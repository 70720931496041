import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { spacing } from "@mui/system";
import {
  Box as MuiBox,
  Drawer as MuiDrawer,
  ListItemButton,
} from "@mui/material";
import { useSelector } from "react-redux";
import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import { authUserType } from "../../utils/defaultStatus";

const Box = styled(MuiBox)(spacing);
const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const renderBrand = ({ url, companyName = "IndexCloud" }) => (
  <Brand component={NavLink} to={url}>
    <Box ml={1}>{companyName}</Box>
  </Brand>
);

function Sidebar({ items, showFooter = true, ...rest }) {
  const user = useSelector((state) => state.user.user);
  const { EMPLOYEE, SHIPPER } = authUserType;
  return (
    <Drawer variant="permanent" {...rest}>
      {user.user_type === EMPLOYEE &&
        renderBrand({
          url: `/${user?.service_provider?.service_provider_company_name}/dashboard`,
        })}
      {user.user_type === SHIPPER &&
        renderBrand({
          url: `/${user?.service_provider?.service_provider_company_name}/shipper/${user?.shipper_company_name}/dashboard`,
        })}
      <SidebarNav items={items} />
      {!!showFooter && <Footer />}
    </Drawer>
  );
}

export default Sidebar;
