/**
 * @flow
 */

/* eslint-disable */

"use strict";

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SignUpQueryVariables = {||};
export type SignUpQueryResponse = {|
  +getCities: ?$ReadOnlyArray<?{|
    +_id: ?string,
    +city_name: ?string,
    +city_code: ?string,
  |}>
|};
export type SignUpQuery = {|
  variables: SignUpQueryVariables,
  response: SignUpQueryResponse,
|};
*/

/*
query SignUpQuery {
  getCities {
    _id
    city_name
    city_code
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: "AllCityReturn",
      kind: "LinkedField",
      name: "getCities",
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "_id",
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "city_name",
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "city_code",
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "SignUpQuery",
      selections: (v0 /*: any*/),
      type: "RootQueryType",
      abstractKey: null,
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "SignUpQuery",
      selections: (v0 /*: any*/),
    },
    params: {
      cacheID: "8b2c9c089dba7152c2a2b4c7065205f5",
      id: null,
      metadata: {},
      name: "SignUpQuery",
      operationKind: "query",
      text: "query SignUpQuery {\n  getCities {\n    _id\n    city_name\n    city_code\n  }\n}\n",
    },
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'b540a9facfbc13cb9396ef1dce280837';

module.exports = node;
