import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  payableTableList: [],
};

export const shipperPayablesSlice = createSlice({
  name: "shipperPayables",
  initialState,
  reducers: {
    setShipperPayables: (state, action) => {
      state.payableTableList = action.payload;
    },
  },
});

export const { setShipperPayables } = shipperPayablesSlice.actions;

export default shipperPayablesSlice.reducer;
