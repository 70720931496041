/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ShipperUpdateInput = {|
  shipper_id?: ?string,
  shipper_type?: ?string,
  shipper_first_name?: ?string,
  shipper_last_name?: ?string,
  shipper_other_name?: ?string,
  shipper_full_name?: ?string,
  shipper_company_name?: ?string,
  shipper_display_name?: ?string,
  country_code?: ?string,
  shipper_phone1?: ?string,
  shipper_phone2?: ?string,
  billing_due_days?: ?number,
  payment_due_days?: ?number,
  shipper_email?: ?string,
  shipper_contacts?: ?$ReadOnlyArray<?ContactInput>,
  shipper_address_line1?: ?string,
  shipper_address_line2?: ?string,
  shipper_city?: ?CityInput,
  shipper_district?: ?DistrictInput,
  shipper_province?: ?ProvinceInput,
  shipper_address_location?: ?LocationInput,
  account_number?: ?string,
  pickup_location?: ?PickupLocationInput,
  bank?: ?BankInput,
  bank_account_name?: ?string,
  payment_cycle?: ?paymentCycleInput,
  billing_cycle?: ?billingCycleInput,
  payment_method?: ?string,
  shipper_price_card?: ?PriceCardInputType,
  service_provider?: ?string,
  shipper_status?: ?boolean,
  business_registration_doc?: ?string,
  other_doc?: ?string,
  created_date?: ?string,
  user_email_verify_status?: ?boolean,
  user_mobile_verify_status?: ?boolean,
  profile_setting?: ?ShipperProfileSettingsInput,
|};
export type ContactInput = {|
  _id: string,
  user_name: string,
  user_type: string,
  user_level: string,
  user_status: string,
|};
export type CityInput = {|
  _id: string,
  city_name: string,
|};
export type DistrictInput = {|
  _id: string,
  district_name: string,
  district_status?: ?boolean,
|};
export type ProvinceInput = {|
  _id: string,
  province_name: string,
  province_status?: ?boolean,
|};
export type LocationInput = {|
  type: string,
  coordinates: $ReadOnlyArray<?string>,
|};
export type PickupLocationInput = {|
  pickup_address_line1?: ?string,
  pickup_address_line2?: ?string,
  pickup_location_city?: ?CityInput,
  pickup_location_province?: ?ProvinceInput,
  pickup_location_district?: ?DistrictInput,
  pickup_location?: ?LocationInput,
  pickup_zone?: ?ZoneInput,
  pickup_location_hub?: ?PickupLocationHubInput,
|};
export type ZoneInput = {|
  _id?: ?string,
  zone_name?: ?string,
  zone_code?: ?string,
|};
export type PickupLocationHubInput = {|
  _id?: ?string,
  hub_name?: ?string,
  hub_code?: ?string,
|};
export type BankInput = {|
  _id: string,
  bank_name: string,
  bank_code: string,
  branch_code: string,
  branch_name: string,
|};
export type paymentCycleInput = {|
  _id?: ?string,
  payment_cycle_name?: ?string,
  payment_cycle_day?: ?string,
  payment_cycle_date?: ?number,
  payment_cycle_time?: ?string,
  payment_cycle_type?: ?string,
|};
export type billingCycleInput = {|
  _id?: ?string,
  billing_cycle_name?: ?string,
  billing_day?: ?string,
  billing_cycle_date?: ?number,
  billing_cycle_time?: ?string,
  billing_cycle_type?: ?string,
|};
export type PriceCardInputType = {|
  _id?: ?string,
  price_card_name?: ?string,
  price_card_version?: ?string,
  price_card_created_date?: ?string,
  price_card_created_by?: ?userPriceCardInputType,
  price_card_type?: ?string,
  price_card_service?: ?string,
|};
export type userPriceCardInputType = {|
  _id?: ?string,
  user_name?: ?string,
|};
export type ShipperProfileSettingsInput = {|
  logo?: ?string,
  banner?: ?string,
|};
export type BankInformationMutationVariables = {|
  id: string,
  match_pickup_location: boolean,
  shipper: ShipperUpdateInput,
|};
export type BankInformationMutationResponse = {|
  +updateShipper: ?{|
    +_id: ?string,
    +shipper_email: ?string,
    +shipper_full_name: ?string,
    +shipper_id: ?string,
    +shipper_company_name: ?string,
    +shipper_type: ?string,
    +pickup_location: ?{|
      +pickup_address_line1: ?string,
      +pickup_address_line2: ?string,
      +pickup_location_city: ?{|
        +_id: ?string,
        +city_name: ?string,
      |},
      +pickup_location_district: ?{|
        +_id: ?string,
        +district_name: ?string,
      |},
      +pickup_location: ?{|
        +type: ?string,
        +coordinates: ?$ReadOnlyArray<?string>,
      |},
      +pickup_location_hub: ?{|
        +_id: ?string,
        +hub_name: ?string,
        +hub_code: ?string,
      |},
      +pickup_location_province: ?{|
        +_id: ?string,
        +province_name: ?string,
      |},
      +pickup_zone: ?{|
        +_id: ?string,
        +zone_name: ?string,
        +zone_code: ?string,
      |},
    |},
    +shipper_city: ?{|
      +_id: ?string,
      +city_name: ?string,
    |},
    +service_provider: ?{|
      +_id: ?string,
      +service_provider_full_name: ?string,
      +service_provider_company_name: ?string,
    |},
  |}
|};
export type BankInformationMutation = {|
  variables: BankInformationMutationVariables,
  response: BankInformationMutationResponse,
|};
*/


/*
mutation BankInformationMutation(
  $id: ID!
  $match_pickup_location: Boolean!
  $shipper: ShipperUpdateInput!
) {
  updateShipper(id: $id, match_pickup_location: $match_pickup_location, shipper: $shipper) {
    _id
    shipper_email
    shipper_full_name
    shipper_id
    shipper_company_name
    shipper_type
    pickup_location {
      pickup_address_line1
      pickup_address_line2
      pickup_location_city {
        _id
        city_name
      }
      pickup_location_district {
        _id
        district_name
      }
      pickup_location {
        type
        coordinates
      }
      pickup_location_hub {
        _id
        hub_name
        hub_code
      }
      pickup_location_province {
        _id
        province_name
      }
      pickup_zone {
        _id
        zone_name
        zone_code
      }
    }
    shipper_city {
      _id
      city_name
    }
    service_provider {
      _id
      service_provider_full_name
      service_provider_company_name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "match_pickup_location"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shipper"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "city_name",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "match_pickup_location",
        "variableName": "match_pickup_location"
      },
      {
        "kind": "Variable",
        "name": "shipper",
        "variableName": "shipper"
      }
    ],
    "concreteType": "Shipper",
    "kind": "LinkedField",
    "name": "updateShipper",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shipper_email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shipper_full_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shipper_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shipper_company_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shipper_type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PickupLocationsReturn",
        "kind": "LinkedField",
        "name": "pickup_location",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pickup_address_line1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pickup_address_line2",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CityReturn",
            "kind": "LinkedField",
            "name": "pickup_location_city",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DistrictReturn",
            "kind": "LinkedField",
            "name": "pickup_location_district",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "district_name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AddressLocationReturn",
            "kind": "LinkedField",
            "name": "pickup_location",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coordinates",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PickupHubReturn",
            "kind": "LinkedField",
            "name": "pickup_location_hub",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hub_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hub_code",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProvinceReturn",
            "kind": "LinkedField",
            "name": "pickup_location_province",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "province_name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ZoneReturn",
            "kind": "LinkedField",
            "name": "pickup_zone",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "zone_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "zone_code",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CityReturn",
        "kind": "LinkedField",
        "name": "shipper_city",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceProviderRef",
        "kind": "LinkedField",
        "name": "service_provider",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "service_provider_full_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "service_provider_company_name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BankInformationMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BankInformationMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "6425d7e408673e594046f002ad504d08",
    "id": null,
    "metadata": {},
    "name": "BankInformationMutation",
    "operationKind": "mutation",
    "text": "mutation BankInformationMutation(\n  $id: ID!\n  $match_pickup_location: Boolean!\n  $shipper: ShipperUpdateInput!\n) {\n  updateShipper(id: $id, match_pickup_location: $match_pickup_location, shipper: $shipper) {\n    _id\n    shipper_email\n    shipper_full_name\n    shipper_id\n    shipper_company_name\n    shipper_type\n    pickup_location {\n      pickup_address_line1\n      pickup_address_line2\n      pickup_location_city {\n        _id\n        city_name\n      }\n      pickup_location_district {\n        _id\n        district_name\n      }\n      pickup_location {\n        type\n        coordinates\n      }\n      pickup_location_hub {\n        _id\n        hub_name\n        hub_code\n      }\n      pickup_location_province {\n        _id\n        province_name\n      }\n      pickup_zone {\n        _id\n        zone_name\n        zone_code\n      }\n    }\n    shipper_city {\n      _id\n      city_name\n    }\n    service_provider {\n      _id\n      service_provider_full_name\n      service_provider_company_name\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2d4ad7109324039b37d103493064d917';

module.exports = node;
