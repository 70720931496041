/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef } from "react";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { PhotoCamera } from "@mui/icons-material";
import EditImageDialog from "../../../components/ImageUpload/EditLandingPageImageDialog";
import {
  authUserType,
  landingPageImageType,
} from "../../../utils/defaultStatus";

const Input = styled("input")({
  display: "none",
});

export default function BannerUploadButton({
  fetchUserDetails,
  setIsErrorUploading,
}) {
  const [ViewDialog, toggleViewImageDialog] = useState(false);
  const inputFile = useRef(null);
  const [imageDataUrl, setImageDataUrl] = useState(null);
  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const handleFileUpload = async (e) => {
    const { files } = e.target;
    if (files && files.length) {
      // comment for future usage
      //   const filename = files[0].name;
      //   const parts = filename.split(".");
      //   const fileType = parts[parts.length - 1];
      const imageUrl = await readFile(files[0]);
      setImageDataUrl(imageUrl);
      e.target.value = null;
      toggleViewImageDialog(true);
    }
  };

  return (
    <>
      <Tooltip title="Change banner">
        <label htmlFor="icon-button-file-banner">
          <Input
            accept="image/png, image/jpeg"
            id="icon-button-file-banner"
            type="file"
            ref={inputFile}
            onChange={handleFileUpload}
          />
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            sx={{ backgroundColor: "white" }}
          >
            <PhotoCamera sx={{ fontSize: 30 }} />
          </IconButton>
        </label>
      </Tooltip>
      <EditImageDialog
        toggleViewImageDialog={toggleViewImageDialog}
        ViewDialog={ViewDialog}
        image={imageDataUrl}
        fetchUserDetails={fetchUserDetails}
        setIsErrorUploading={setIsErrorUploading}
        userType={authUserType.SERVICE_PROVIDER}
        imageType={landingPageImageType.BANNER}
      />
    </>
  );
}
