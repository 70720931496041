import React, { useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import "./SignUp.css";
import { useSelector } from "react-redux";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  LinearProgress as MuiLinearProgress,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import ProvinceSelector from "./components/ProvinceSelector";
import DistrictSelector from "./components/DistrictSelectorByProvince";
import CitySelector from "./components/CitySelectorByDistrict";
import { authResponse } from "../../utils/response_messages/auth";
import { authConstants } from "../../utils/authConstants";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);

function ServiceProviderBillingInformation({ setComponent }) {
  const [logingErrors, setLoggingErrors] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [commit, isInFlight] = useMutation(graphql`
    mutation ServiceProviderBillingInformationMutation(
      $id: ID!
      $service_provider: ServiceProviderInputType!
    ) {
      updateServiceProvider(id: $id, service_provider: $service_provider) {
        _id
        service_provider {
          service_provider_full_name
        }
      }
    }
  `);

  return (
    <>
      <Typography component="h1" variant="h2" align="center" gutterBottom>
        Billing Information
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Please enter your billing information below.
      </Typography>
      <Formik
        initialValues={{
          addressLine1: "",
          addressLine2: "",
          province: "",
          district: "",
          city: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          addressLine1: Yup.string()
            .max(255)
            .required("Address line 1 is required"),
          addressLine2: Yup.string().max(255),
          province: Yup.string().max(255).required("Province is required"),
          district: Yup.string().max(255).required("District is equired"),
          city: Yup.string().max(255).required("City is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const provinceArr = values.province.split("_");
            const districtArr = values.district.split("_");
            const cityArr = values.city.split("_");
            commit({
              variables: {
                id: user.service_provider._id,
                service_provider: {
                  service_provider_address_line1: values.addressLine1,
                  service_provider_address_line2: values.addressLine2,
                  service_provider_city: {
                    _id: cityArr[0],
                    city_name: cityArr[1],
                  },
                  service_provider_province: {
                    _id: provinceArr[0],
                    province_name: provinceArr[1],
                  },
                  service_provider_district: {
                    _id: districtArr[0],
                    district_name: districtArr[1],
                  },
                },
              },
              onCompleted(data, error) {
                setLoggingErrors(
                  error && error.length > 0
                    ? [{ error: authResponse.FAILURE }]
                    : []
                );
                if (data.updateServiceProvider) {
                  setComponent(authConstants.PAYMENT_METHODS);
                }
              },
            });
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          resetForm,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            {logingErrors.length > 0 && (
              <Alert mb={4} variant="filled" severity="error">
                {authResponse.FAILURE}
              </Alert>
            )}
            <TextField
              type="text"
              name="addressLine1"
              label="Address Line 1"
              value={values.addressLine1}
              error={Boolean(touched.addressLine1 && errors.addressLine1)}
              fullWidth
              helperText={touched.addressLine1 && errors.addressLine1}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="addressLine2"
              label="Address Line 2[optional]"
              value={values.addressLine2}
              error={Boolean(touched.addressLine2 && errors.addressLine2)}
              fullWidth
              helperText={touched.addressLine2 && errors.addressLine2}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />

            <ProvinceSelector
              values={values}
              touched={touched}
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />

            <DistrictSelector
              values={values}
              touched={touched}
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CitySelector
              values={values}
              touched={touched}
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              mr={2}
              fullWidth
              onClick={resetForm}
              style={{ marginBottom: "10px" }}
            >
              Clear Form
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isInFlight}
            >
              Next
            </Button>
            <div className="progress-content">
              <LinearProgress
                variant="determinate"
                value="62.5"
                className="progress"
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default ServiceProviderBillingInformation;
