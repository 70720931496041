import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notificationsList: [],
  unReadNotificationList: [],
};

export const serviceProviderNotificationSlice = createSlice({
  name: "serviceProviderNotifications",
  initialState,
  reducers: {
    setNotificationsList: (state, action) => {
      state.notificationsList = action.payload;
    },
    setUnReadNotificationList: (state, action) => {
      state.unReadNotificationList = action.payload;
    },
    updateNotificationsList: (state, action) => {
      const notificationList = action.payload;

      const objAllIndex = state.notificationsList.findIndex(
        (obj) => obj._id === notificationList._id
      );
      state.notificationsList[objAllIndex] = notificationList;

      const objUnReadIndex = state.unReadNotificationList.findIndex(
        (obj) => obj._id === notificationList._id
      );
      state.unReadNotificationList.splice(objUnReadIndex, 1);
    },
    updateAllNotificationList: (state, action) => {
      const notificationsListIDs = action.payload;
      if (notificationsListIDs) {
        notificationsListIDs.forEach((Id) => {
          const objAllIndex = state.notificationsList.findIndex(
            (obj) => obj._id === Id
          );
          state.notificationsList[objAllIndex].status = "read";

          const objUnReadIndex = state.unReadNotificationList.findIndex(
            (obj) => obj._id === Id
          );
          state.unReadNotificationList.splice(objUnReadIndex, 1);
        });
      }
    },
  },
});

export const {
  setNotificationsList,
  updateNotificationsList,
  setUnReadNotificationList,
  updateAllNotificationList,
} = serviceProviderNotificationSlice.actions;

export default serviceProviderNotificationSlice.reducer;
