import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  confirmedDispatch: {},
};

export const serviceProviderDispatchSlice = createSlice({
  name: "serviceProviderDispatch",
  initialState,
  reducers: {
    setConfirmedDispatch: (state, action) => {
      state.confirmedDispatch = action.payload;
    },
  },
});

export const { setConfirmedDispatch } = serviceProviderDispatchSlice.actions;

export default serviceProviderDispatchSlice.reducer;
