import React from "react";
import { Grid, Typography } from "@mui/material";
import HandleWithcare from "../../utils/images/HandleWithCare.png";
import { shipmentHandlingType } from "../../utils/defaultStatus";

const ShipmentLabelHeader = ({ shipment, qty }) => {
  return (
    <div style={{ margin: "5px 20px 0 20px" }}>
      <Grid container spacing={6}>
        <Grid item xs={4}>
          <div style={{ marginTop: "20px", marginLeft: "10px" }}>
            <Typography
              sx={{ fontSize: "18px", fontWeight: "Bold" }}
              variant="h1"
              gutterBottom
              display="inline"
            >
              FROM
            </Typography>
            <div>
              <Typography
                variant="h3"
                gutterBottom
                display="inline"
                sx={{ fontSize: "15px", fontWeight: "normal" }}
              >
                {shipment.shipper.shipper_company_name}
              </Typography>
            </div>
            <div>
              <Typography
                variant="h3"
                gutterBottom
                display="inline"
                sx={{ fontSize: "15px", fontWeight: "normal" }}
              >
                {shipment.shipper.shipper_address_line1}
              </Typography>
            </div>
            <div>
              <Typography
                variant="h3"
                gutterBottom
                display="inline"
                sx={{ fontSize: "15px", fontWeight: "normal" }}
              >
                {shipment.shipper.pickup_locations.city.city_name}
              </Typography>
            </div>
            <div>
              <Typography
                variant="h3"
                gutterBottom
                display="inline"
                sx={{ fontSize: "15px", fontWeight: "normal" }}
              >
                {shipment.shipper.shipper_phone1}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h1>
              <span style={{ fontWeight: "normal", fontSize: "20px" }}>
                Weight:&nbsp;{shipment.weight}KG
              </span>
            </h1>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "10px",
            }}
          >
            <h1>
              <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                {qty}&nbsp;of&nbsp;{shipment.qty}
              </span>
            </h1>
          </div>
        </Grid>
      </Grid>
      <div
        style={{
          marginTop: "10px",
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <div style={{ marginLeft: "10px" }}>
              <Typography
                variant="h1"
                gutterBottom
                display="inline"
                sx={{ fontSize: "20px", fontWeight: "Bold" }}
              >
                SHIP TO:
              </Typography>
              <div
                style={{
                  marginLeft: "30px",
                }}
              >
                <Typography
                  variant="h2"
                  gutterBottom
                  display="inline"
                  sx={{
                    fontSize: "18px",
                    fontWeight: "normal",
                    lineHeight: 1.5,
                  }}
                >
                  {shipment.recipient_name}
                </Typography>
              </div>
              <div
                style={{
                  marginLeft: "30px",
                }}
              >
                {shipment.recipient_phone2 ? (
                  <Typography
                    variant="h2"
                    gutterBottom
                    display="inline"
                    sx={{
                      fontSize: "18px",
                      fontWeight: "normal",
                      lineHeight: 1.2,
                    }}
                  >
                    {`${shipment.recipient_phone1}/${shipment.recipient_phone2}`}
                  </Typography>
                ) : (
                  <Typography
                    variant="h2"
                    gutterBottom
                    display="inline"
                    sx={{
                      fontSize: "18px",
                      fontWeight: "normal",
                      lineHeight: 1.2,
                    }}
                  >
                    {shipment.recipient_phone1}
                  </Typography>
                )}
              </div>
              <div
                style={{
                  marginLeft: "30px",
                }}
              >
                <Typography
                  variant="h2"
                  gutterBottom
                  display="inline"
                  sx={{
                    fontSize: "18px",
                    fontWeight: "normal",
                    lineHeight: 1.2,
                  }}
                >
                  {shipment.recipient_address_line1}
                </Typography>
              </div>
              <div
                style={{
                  marginLeft: "30px",
                }}
              >
                <Typography
                  variant="h2"
                  gutterBottom
                  display="inline"
                  sx={{
                    fontSize: "18px",
                    fontWeight: "normal",
                    lineHeight: 1.2,
                  }}
                >
                  {shipment.city ? shipment.city.city_name : ""}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            {shipment.handling_type === shipmentHandlingType.FRAGILE && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    lineHeight: 1.5,
                    marginTop: 0,
                  }}
                >
                  Fragile
                </span>

                <img
                  src={HandleWithcare}
                  width="110px"
                  height="100px"
                  alt="handle"
                />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ShipmentLabelHeader;
