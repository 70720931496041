import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hubs: [],
};

export const serviceProviderHubsSlice = createSlice({
  name: "serviceProviderHubs",
  initialState,
  reducers: {
    setHubs: (state, action) => {
      state.hubs = action.payload;
    },
    updateNewHub: (state, action) => {
      const updatedHub = action.payload;
      const objIndex = state.hubs.findIndex(
        (obj) => obj._id === updatedHub._id
      );
      state.hubs[objIndex] = updatedHub;
    },
  },
});

export const { setHubs, updateNewHub } = serviceProviderHubsSlice.actions;

export default serviceProviderHubsSlice.reducer;
