/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CitySelectorByDistrictQueryVariables = {|
  district_id?: ?string
|};
export type CitySelectorByDistrictQueryResponse = {|
  +getCities: ?$ReadOnlyArray<?{|
    +_id: ?string,
    +city_name: ?string,
    +city_code: ?string,
  |}>
|};
export type CitySelectorByDistrictQuery = {|
  variables: CitySelectorByDistrictQueryVariables,
  response: CitySelectorByDistrictQueryResponse,
|};
*/


/*
query CitySelectorByDistrictQuery(
  $district_id: ID
) {
  getCities(district_id: $district_id) {
    _id
    city_name
    city_code
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "district_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "district_id",
        "variableName": "district_id"
      }
    ],
    "concreteType": "AllCityReturn",
    "kind": "LinkedField",
    "name": "getCities",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "city_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "city_code",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CitySelectorByDistrictQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CitySelectorByDistrictQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "acfea847c12754097de36be96ebd0b6b",
    "id": null,
    "metadata": {},
    "name": "CitySelectorByDistrictQuery",
    "operationKind": "query",
    "text": "query CitySelectorByDistrictQuery(\n  $district_id: ID\n) {\n  getCities(district_id: $district_id) {\n    _id\n    city_name\n    city_code\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f065ba3f6310310377070266c5ee4b04';

module.exports = node;
