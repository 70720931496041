// async function fetchGraphQL(text, variables) {
//   let url = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/graphql`;
//   if (process.env.REACT_APP_API_URL) {
//     url = `${process.env.REACT_APP_API_URL}/graphql`;
//   }

//   const response = await fetch(url, {
//     method: "POST",
//     headers: {
//       // Authorization: `bearer ${REACT_APP_GITHUB_AUTH_TOKEN}`,
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({
//       query: text,
//       variables,
//     }),
//   });

//   // Get the response as JSON
//   return await response.json();
// }

// export default fetchGraphQL;

async function fetchGraphQL(text, variables) {
  let url = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/graphql`;
  if (process.env.REACT_APP_API_URL) {
    url = `${process.env.REACT_APP_API_URL}/graphql`;
  }

  return fetch(url, {
    method: "POST",
    headers: {
      // Authorization: `bearer ${REACT_APP_GITHUB_AUTH_TOKEN}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: text,
      variables,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      if (json && json.errors) {
        throw new Error(json.errors[0].message);
      }
      return json;
    });
}

export default fetchGraphQL;
