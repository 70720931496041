import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "axios";
import graphql from "babel-plugin-relay/macro";
import { useDispatch } from "react-redux";
import { useMutation } from "react-relay";
import useAuth from "../../hooks/useAuth";
import { authConstants } from "../../utils/authConstants";
import { authUserType, userActivationStatus } from "../../utils/defaultStatus";
import { setUser } from "../../redux/slices/user";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn({ setAuthComponent }) {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const dispatch = useDispatch();
  const [loginUser, setLoginUser] = useState({});
  const [isEmailValidAccount, setIsEmailValidAccount] = useState(false);
  const [isMobileValidAccount, setIsMobileValidAccount] = useState(false);
  const { EMAIL_OTP, MOBILE_NUMBER } = authConstants;
  const [commit, isInFlight] = useMutation(graphql`
    mutation SignInMutation($user_name: String, $otp_type: String) {
      resendOtp(user_name: $user_name, otp_type: $otp_type) {
        otp_value
        otp_type
        user {
          user_name
        }
      }
    }
  `);

  const resendEmailOtp = (setErrors) => {
    switch (loginUser.user_type) {
      case authUserType.SHIPPER:
        commit({
          variables: {
            user_name: loginUser.user.shipper_email,
            otp_type: "email",
          },
          onCompleted(data, error) {
            if (data.resendOtp) {
              navigate(
                `/${loginUser.user.service_provider?.service_provider_company_name}/shipper/auth/sign_up`,
                { state: { component: EMAIL_OTP }, replace: true }
              );
              setAuthComponent(authConstants.SIGN_UP);
            }
            if (error) {
              setErrors({
                submit: "Something went wrong",
                loginFailed: error.message,
              });
            }
          },
        });
        break;
      case authUserType.EMPLOYEE:
        commit({
          variables: {
            user_name: loginUser.user.emp_company_email,
            otp_type: "email",
          },
          onCompleted(data, error) {
            if (data.resendOtp) {
              navigate(`/auth/sign_up`, {
                state: { component: EMAIL_OTP },
                replace: true,
              });
              setAuthComponent(authConstants.SIGN_UP);
            }
            if (error) {
              setErrors({
                submit: "Something went wrong",
                loginFailed: error.message,
              });
            }
          },
        });
        break;
      default:
        break;
    }
  };

  const resendMobileOtpMessege = () => {
    switch (loginUser.user_type) {
      case authUserType.SHIPPER:
        navigate(
          `/${loginUser.user.service_provider?.service_provider_company_name}/shipper/auth/sign_up`,
          { state: { component: MOBILE_NUMBER }, replace: true }
        );
        setAuthComponent(authConstants.SIGN_UP);
        break;
      case authUserType.EMPLOYEE:
        navigate(`/auth/sign_up`, {
          state: { component: MOBILE_NUMBER },
          replace: true,
        });
        setAuthComponent(authConstants.SIGN_UP);
        break;

      default:
        break;
    }
  };

  return (
    <Formik
      initialValues={{
        // email: 'demo@bootlab.io',
        // password: 'unsafepassword',
        email: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          let url = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/login`;
          if (process.env.REACT_APP_API_URL) {
            url = `${process.env.REACT_APP_API_URL}/login`;
          }
          const response = await axios.post(url, {
            user_name: values.email,
            password: values.password,
          });
          const userTypeResponse = response.data.user_type;
          switch (userTypeResponse) {
            case authUserType.SHIPPER:
              if (
                response.data.user_activation_status ===
                userActivationStatus.DEACTIVATE
              ) {
                setErrors({
                  submit: "Something went wrong",
                  loginFailed:
                    "This account has been suspended. Please contact admin for more information.",
                });
              } else if (!response.data.user_email_verify_status) {
                dispatch(setUser(response.data));
                setLoginUser(response.data);

                setErrors({
                  submit: "Something went wrong",
                  loginFailed:
                    "This account is not verified. Please resend verification email and verify your account.",
                });
                setIsEmailValidAccount(true);
              } else if (!response.data.user_mobile_verify_status) {
                dispatch(setUser(response.data));
                setLoginUser(response.data);

                setErrors({
                  submit: "Something went wrong",
                  loginFailed:
                    "This account is not verified. Please resend verification mobile number and verify your account.",
                });
                setIsMobileValidAccount(true);
              } else {
                await signIn(response);
                dispatch(setUser(response.data));
                navigate(
                  `/${response.data.user.service_provider.service_provider_company_name}/shipper/${response.data.user.shipper_company_name}/dashboard`
                );
                window.location.reload();
              }

              break;
            case authUserType.SERVICE_PROVIDER:
              navigate(
                `/${response.data.user.service_provider.service_provider_company_name}/dashboard`
              );
              window.location.reload();
              break;
            case authUserType.EMPLOYEE:
              if (
                response.data.user_activation_status ===
                userActivationStatus.DEACTIVATE
              ) {
                setErrors({
                  submit: "Something went wrong",
                  loginFailed:
                    "This account has been suspended. Please contact IndexCloud team for more information at billing@indexcloud.io",
                });
              } else if (!response.data.user_email_verify_status) {
                dispatch(setUser(response.data));
                setLoginUser(response.data);
                setErrors({
                  submit: "Something went wrong",
                  loginFailed:
                    "This account is not verified. Please resend verification email and verify your account.",
                });
                setIsEmailValidAccount(true);
              } else if (!response.data.user_mobile_verify_status) {
                dispatch(setUser(response.data));
                setLoginUser(response.data);
                setErrors({
                  submit: "Something went wrong",
                  loginFailed:
                    "This account is not verified. Please resend verification mobile number and verify your account.",
                });
                setIsMobileValidAccount(true);
              } else {
                await signIn(response);
                dispatch(setUser(response.data));
                navigate(
                  `/${response.data.user.service_provider.service_provider_company_name}/dashboard`
                );
                window.location.reload();
              }

              break;
            case authUserType.SYSTEM_ADMIN:
              await signIn(response);
              dispatch(setUser(response.data));
              navigate(`/admin/home`);
              window.location.reload();
              break;
            default:
          }
        } catch (error) {
          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({
            submit: message,
            loginFailed: "Invalid Username or Password!",
          });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert
              mt={2}
              mb={3}
              severity="warning"
              onClose={() => setErrors({})}
            >
              {errors.loginFailed}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h8">Don't have an account?</Typography>
            <Button
              color="primary"
              onClick={() => setAuthComponent(authConstants.SIGN_UP)}
            >
              Sign up
            </Button>
          </div>
          {!isMobileValidAccount && !isEmailValidAccount && (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Sign in
            </Button>
          )}

          {isEmailValidAccount && (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={isInFlight}
              onClick={() => resendEmailOtp(setErrors)}
            >
              Re-send verification email
            </Button>
          )}

          {!isEmailValidAccount && isMobileValidAccount && (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={isInFlight}
              onClick={() => resendMobileOtpMessege()}
            >
              Re-send verification mobile number
            </Button>
          )}
          <Button
            component={Link}
            to="/auth/reset-password"
            fullWidth
            color="primary"
          >
            Forgot password
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
