import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  shippers: [],
};

export const ServiceProviderPaymentSummaryDashboardTableSlice = createSlice({
  name: "serviceProviderPaymentSummaryDashboardTable",
  initialState,
  reducers: {
    setServiceProviderSummaryDashboardTable: (state, action) => {
      state.shippers = action.payload;
    },
  },
});

export const { setServiceProviderSummaryDashboardTable } =
  ServiceProviderPaymentSummaryDashboardTableSlice.actions;
export default ServiceProviderPaymentSummaryDashboardTableSlice.reducer;
