/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserPlaceOrderFormQueryVariables = {|
  slug?: ?string
|};
export type UserPlaceOrderFormQueryResponse = {|
  +getOrderURLBySlug: ?{|
    +product_code: ?string,
    +product_name: ?string,
    +color: ?string,
    +size: ?string,
    +product_description: ?string,
    +product_image: ?$ReadOnlyArray<?string>,
    +weight: ?number,
    +weight_type: ?string,
    +product_price: ?string,
    +delivery_fee: ?number,
    +url_status: ?boolean,
    +shipper: ?{|
      +_id: ?string,
      +shipper_id: ?string,
      +shipper_full_name: ?string,
      +shipper_company_name: ?string,
      +shipper_phone1: ?string,
      +shipper_address_line1: ?string,
      +shipper_email: ?string,
      +pickup_locations: ?{|
        +city: ?{|
          +_id: ?string,
          +city_name: ?string,
        |},
        +hub: ?{|
          +_id: ?string,
          +hub_name: ?string,
          +hub_code: ?string,
        |},
      |},
    |},
    +service_provider: ?{|
      +_id: ?string,
      +service_provider_full_name: ?string,
      +service_provider_company_name: ?string,
    |},
  |}
|};
export type UserPlaceOrderFormQuery = {|
  variables: UserPlaceOrderFormQueryVariables,
  response: UserPlaceOrderFormQueryResponse,
|};
*/


/*
query UserPlaceOrderFormQuery(
  $slug: String
) {
  getOrderURLBySlug(slug: $slug) {
    product_code
    product_name
    color
    size
    product_description
    product_image
    weight
    weight_type
    product_price
    delivery_fee
    url_status
    shipper {
      _id
      shipper_id
      shipper_full_name
      shipper_company_name
      shipper_phone1
      shipper_address_line1
      shipper_email
      pickup_locations {
        city {
          _id
          city_name
        }
        hub {
          _id
          hub_name
          hub_code
        }
      }
    }
    service_provider {
      _id
      service_provider_full_name
      service_provider_company_name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "slug"
      }
    ],
    "concreteType": "OrderUrlReturnType",
    "kind": "LinkedField",
    "name": "getOrderURLBySlug",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "product_code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "product_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "size",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "product_description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "product_image",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "weight",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "weight_type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "product_price",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "delivery_fee",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url_status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OrderShipperRef",
        "kind": "LinkedField",
        "name": "shipper",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shipper_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shipper_full_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shipper_company_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shipper_phone1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shipper_address_line1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shipper_email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderPickupLocationsReturn",
            "kind": "LinkedField",
            "name": "pickup_locations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderCityReturn",
                "kind": "LinkedField",
                "name": "city",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "city_name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderShipperHub",
                "kind": "LinkedField",
                "name": "hub",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hub_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hub_code",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OrderServiceProviderReturn",
        "kind": "LinkedField",
        "name": "service_provider",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "service_provider_full_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "service_provider_company_name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserPlaceOrderFormQuery",
    "selections": (v2/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserPlaceOrderFormQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "249a808de3dbdf47aa2d95108508aa4d",
    "id": null,
    "metadata": {},
    "name": "UserPlaceOrderFormQuery",
    "operationKind": "query",
    "text": "query UserPlaceOrderFormQuery(\n  $slug: String\n) {\n  getOrderURLBySlug(slug: $slug) {\n    product_code\n    product_name\n    color\n    size\n    product_description\n    product_image\n    weight\n    weight_type\n    product_price\n    delivery_fee\n    url_status\n    shipper {\n      _id\n      shipper_id\n      shipper_full_name\n      shipper_company_name\n      shipper_phone1\n      shipper_address_line1\n      shipper_email\n      pickup_locations {\n        city {\n          _id\n          city_name\n        }\n        hub {\n          _id\n          hub_name\n          hub_code\n        }\n      }\n    }\n    service_provider {\n      _id\n      service_provider_full_name\n      service_provider_company_name\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '059db7467ac212a8d53451c294af94c7';

module.exports = node;
