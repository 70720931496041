import React from "react";
import styled from "styled-components/macro";
import {
  Card as MuiCard,
  CardContent,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from "@mui/material";
import { spacing } from "@mui/system";
import moment from "moment";

const Card = styled(MuiCard)(spacing);

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

function DetailsOne({ shipmentDetails }) {
  return (
    <Card mb={6} style={{ marginTop: "10px" }}>
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                SHIP DATE
              </TableCell>
              <TableCell align="right">
                {moment
                  .unix(shipmentDetails.shipment_created_time / 1000)
                  .subtract(5.5, "hours")
                  .format("DD MMM YYYY hh:mm a")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                BILL TO
              </TableCell>
              <TableCell align="right">
                {shipmentDetails.shipper &&
                  shipmentDetails.shipper.shipper_company_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                WEIGHT
              </TableCell>
              <TableCell align="right">{shipmentDetails.weight}kg</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                QTY
              </TableCell>
              <TableCell align="right">{shipmentDetails.qty}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default DetailsOne;
