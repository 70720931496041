import React, { useState, useCallback } from "react";
import styled from "styled-components/macro";
import Cropper from "react-easy-crop";
import { useSelector } from "react-redux";
import {
  Dialog,
  Box,
  Slider,
  Typography,
  DialogContent,
  DialogTitle,
  Stack,
  Button as MuiButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import getCroppedImg from "./CropImage";
import { authUserType, landingPageImageType } from "../../utils/defaultStatus";

const Button = styled(MuiButton)(spacing);

const EditLandingPageImageDialog = ({
  ViewDialog,
  toggleViewImageDialog,
  image,
  fetchUserDetails,
  setIsErrorUploading,
  userType,
  imageType,
}) => {
  const {
    REACT_APP_AWS_S3_BUCKET_ACCESS_KEY_ID,
    REACT_APP_AWS_S3_BUCKET_SECRET_ACCESS_KEY,
    REACT_APP_AWS_S3_BUCKET_REGION,
    REACT_APP_AWS_S3_BUCKET,
    REACT_APP_SP_LANDING_PAGE_BUCKET_FOLDER,
    REACT_APP_SHIPPER_LANDING_PAGE_BUCKET_FOLDER,
  } = process.env;
  const { SHIPPER, SERVICE_PROVIDER } = authUserType;
  const { LOGO, BANNER } = landingPageImageType;
  const client = new S3Client({
    credentials: {
      accessKeyId: REACT_APP_AWS_S3_BUCKET_ACCESS_KEY_ID,
      secretAccessKey: REACT_APP_AWS_S3_BUCKET_SECRET_ACCESS_KEY,
    },
    region: REACT_APP_AWS_S3_BUCKET_REGION,
  });
  const user = useSelector((state) => state.user.user);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [isUploading, setIsUploading] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [commitUpdateServiceProvider] = useMutation(graphql`
    mutation EditLandingPageImageDialogServiceProviderMutation(
      $id: ID
      $service_provider: ServiceProviderInputType!
    ) {
      updateServiceProvider(id: $id, service_provider: $service_provider) {
        _id
      }
    }
  `);

  const [commitUpdateShipper] = useMutation(graphql`
    mutation EditLandingPageImageDialogShipperMutation(
      $id: ID
      $shipper: ShipperUpdateInput!
    ) {
      updateShipperBySS(id: $id, shipper: $shipper) {
        _id
      }
    }
  `);

  // eslint-disable-next-line no-shadow
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const updateShipper = () => {
    let variables;
    if (imageType === LOGO) {
      variables = {
        id: user._id,
        shipper: {
          profile_setting: {
            logo: `logo-${user._id}`,
          },
        },
      };
    } else if (imageType === BANNER) {
      variables = {
        id: user._id,
        shipper: {
          profile_setting: {
            banner: `banner-${user._id}`,
          },
        },
      };
    }
    commitUpdateShipper({
      variables,
      onCompleted(data, error) {
        if (data.updateShipperBySS) {
          fetchUserDetails();
        }
        if (error) {
          setIsErrorUploading(true);
        }
      },
    });
  };

  const updateServiceProvider = () => {
    let variables;
    if (imageType === LOGO) {
      variables = {
        id: user.service_provider._id,
        service_provider: {
          profile_setting: {
            logo: `logo-${user.service_provider._id}`,
          },
        },
      };
    } else if (imageType === BANNER) {
      variables = {
        id: user.service_provider._id,
        service_provider: {
          profile_setting: {
            banner: `banner-${user.service_provider._id}`,
          },
        },
      };
    }
    commitUpdateServiceProvider({
      variables,
      onCompleted(data, error) {
        if (data.updateServiceProvider) {
          fetchUserDetails();
        }
        if (error) {
          setIsErrorUploading(true);
        }
      },
    });
  };

  const doneCroppedImage = useCallback(async () => {
    setIsUploading(true);
    try {
      const cropImage = await getCroppedImg(image, croppedAreaPixels, rotation);
      let uploadParamKey = "";
      if (userType === SHIPPER) {
        if (imageType === LOGO) {
          uploadParamKey = `${REACT_APP_SHIPPER_LANDING_PAGE_BUCKET_FOLDER}/logo-${user._id}.png`;
        } else if (imageType === BANNER) {
          uploadParamKey = `${REACT_APP_SHIPPER_LANDING_PAGE_BUCKET_FOLDER}/banner-${user._id}.png`;
        }
      } else if (userType === SERVICE_PROVIDER) {
        if (imageType === LOGO) {
          uploadParamKey = `${REACT_APP_SP_LANDING_PAGE_BUCKET_FOLDER}/logo-${user.service_provider._id}.png`;
        } else if (imageType === BANNER) {
          uploadParamKey = `${REACT_APP_SP_LANDING_PAGE_BUCKET_FOLDER}/banner-${user.service_provider._id}.png`;
        }
      }
      const uploadParams = {
        Bucket: REACT_APP_AWS_S3_BUCKET,
        Key: uploadParamKey,
        Body: cropImage[1],
        ContentType: "image/png",
      };

      const cmd = new PutObjectCommand(uploadParams);

      const response = await client.send(cmd);
      if (response.$metadata.httpStatusCode === 200) {
        if (userType === SHIPPER) {
          updateShipper();
        } else if (userType === SERVICE_PROVIDER) {
          updateServiceProvider();
        }
      }
      setIsUploading(false);
      setIsErrorUploading(false);
      toggleViewImageDialog(false);
    } catch (e) {
      Error(e);
      setIsUploading(false);
      toggleViewImageDialog(false);
      setIsErrorUploading(true);
    }
  }, [croppedAreaPixels, rotation]);

  const onClose = useCallback(() => {
    toggleViewImageDialog(false);
  }, []);

  return (
    <div>
      <Dialog
        open={ViewDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Focus to crop</DialogTitle>

        <DialogContent>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: 500,
              background: "#333",
            }}
          >
            <Cropper
              image={image}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={imageType === LOGO ? 1 / 1 : 4 / 1}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <Box width={800}>
            <div style={{ display: "flex", flex: "1", alignItems: "center" }}>
              <Typography variant="overline" style={{ alignItems: "center" }}>
                Zoom
              </Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                style={{ padding: "22px 0px", marginLeft: 53 }}
                // eslint-disable-next-line no-shadow
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
            <div style={{ display: "flex", flex: "1", alignItems: "center" }}>
              <Typography variant="overline" style={{ alignItems: "center" }}>
                Rotation
              </Typography>
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                style={{ padding: "22px 0px", marginLeft: 32 }}
                // eslint-disable-next-line no-shadow
                onChange={(e, rotation) => setRotation(rotation)}
              />
            </div>
          </Box>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: "10px",
              marginTop: "20px",
            }}
          >
            <Stack direction="row" spacing={2}>
              <Button
                color="success"
                onClick={doneCroppedImage}
                variant="outlined"
                style={{ flexShrink: 0, marginLeft: 16 }}
                disabled={isUploading}
              >
                {isUploading ? "Uploading..." : "Done"}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                mt={3}
                onClick={onClose}
                disabled={isUploading}
              >
                Close
              </Button>
            </Stack>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default EditLandingPageImageDialog;
