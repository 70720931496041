import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import {
  FormControl as MuiFormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { spacing } from "@mui/system";
import graphql from "babel-plugin-relay/macro";
import { fetchQuery, useRelayEnvironment } from "react-relay";
import { useSelector } from "react-redux";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

function CitySelector({
  values,
  touched,
  errors,
  SPId,
  handleBlur,
  handleChange,
}) {
  return (
    <FormControl style={{ marginTop: "10px" }}>
      <InputLabel id="demo-simple-select-helper-label">City</InputLabel>

      <SelectorDisplay
        values={values}
        touched={touched}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        SPId={SPId}
      />

      <FormHelperText style={{ color: "red" }}>
        {touched.city && errors.city ? "City is required." : ""}
      </FormHelperText>
    </FormControl>
  );
}

function SelectorDisplay({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  SPId,
}) {
  const [cityList, setCityList] = useState([]);
  const environment = useRelayEnvironment();
  const user = useSelector((state) => state.user.user);
  const fetchCities = async () => {
    const serviceProviderId = SPId || user?.service_provider?._id;
    const disArr = values.district.split("_");
    fetchQuery(
      environment,
      graphql`
        query CitySelectorByDistrictAndServiceProviderQuery(
          $district_id: ID
          $service_provider_id: ID
        ) {
          getAllCities(
            district_id: $district_id
            service_provider_id: $service_provider_id
          ) {
            _id
            city_name
            city_code
          }
        }
      `,
      { district_id: disArr[0], service_provider_id: serviceProviderId }
    ).subscribe({
      start: () => {},
      complete: () => {},
      error: () => {
        setCityList([]);
      },
      next: (data) => {
        if (data.getAllCities) {
          setCityList(data.getAllCities);
        } else {
          setCityList([]);
        }
      },
    });
  };
  const initialRender = useRef(true);

  useEffect(() => {
    let timeout;
    if (initialRender.current) {
      if (values.district) {
        fetchCities();
      }
      initialRender.current = false;
    } else {
      fetchCities();
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [values.district]);

  return (
    <Select
      labelId="demo-simple-select-helper-label"
      id="demo-simple-select-helper"
      name="city"
      value={values.city}
      error={Boolean(touched.city && errors.city)}
      helperText={touched.city && errors.city}
      onBlur={handleBlur}
      onChange={handleChange}
    >
      {cityList.map((city, key) => (
        <MenuItem
          key={key}
          value={`${city._id}_${city.city_name}_${city.city_code}`}
        >
          {city.city_name}
        </MenuItem>
      ))}
    </Select>
  );
}

export default CitySelector;
