import React, { useEffect } from "react";
import styled from "styled-components/macro";
import {
  FormControl as MuiFormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { spacing } from "@mui/system";
import graphql from "babel-plugin-relay/macro";

const { usePreloadedQuery, useQueryLoader } = require("react-relay");

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const getAllDistricts = graphql`
  query DistrictSelectorQuery($provice_id: ID) {
    getDistricts(provice_id: $provice_id) {
      _id
      district_name
    }
  }
`;

function DistrictSelector({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
}) {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader(getAllDistricts);

  useEffect(() => {
    loadQuery({ provice_id: "" });
  }, []);
  return (
    <FormControl style={{ marginTop: "10px" }}>
      <InputLabel id="demo-simple-select-helper-label">District</InputLabel>

      {queryReference != null ? (
        <SelectorDisplay
          values={values}
          touched={touched}
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          queryReference={queryReference}
        />
      ) : null}

      <FormHelperText style={{ color: "red" }}>
        {touched.district && errors.district ? "District is required." : ""}
      </FormHelperText>
    </FormControl>
  );
}

function SelectorDisplay({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  queryReference,
}) {
  const data = usePreloadedQuery(getAllDistricts, queryReference);

  return (
    <Select
      labelId="demo-simple-select-helper-label"
      id="demo-simple-select-helper"
      name="district"
      value={values.district}
      error={Boolean(touched.district && errors.district)}
      helperText={touched.district && errors.district}
      onBlur={handleBlur}
      onChange={handleChange}
    >
      {data.getDistricts.map((district, key) => (
        <MenuItem key={key} value={`${district._id}_${district.district_name}`}>
          {district.district_name}
        </MenuItem>
      ))}
    </Select>
  );
}

export default DistrictSelector;
