import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import ServiceProviderDashboard from "./layouts/ServiceProviderDashboard";
import ServiceProviderSettingsLayout from "./layouts/ServiceProviderSettings";
import ShipperSettingsLayout from "./layouts/ShipperSettings";
import ServiceProviderProfileLayout from "./layouts/ServiceProviderProfile";
import SystemAdminLayout from "./layouts/SystemAdmin";
import DocLayout from "./layouts/Doc";
import PresentationLayout from "./layouts/Presentation";

// Guards
import AuthGuard from "./components/guards/AuthGuard";
import ShipperAuthGuard from "./components/guards/ShipperAuthGuard";
import ServiceProviderAuthGuard from "./components/guards/ServiceProviderAuthGuard";
import SystemAdminAuthGuard from "./components/guards/SystemAdminAuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import Auth from "./pages/auth/Auth";
import ServiceProviderAuth from "./pages/auth/ServiceProviderAuth";
import BankInformation from "./pages/auth/BankInformation";
import PickUpInformation from "./pages/auth/PickUpInformation";
import BillingInformation from "./pages/auth/BillingInformation";
import MobileNumber from "./pages/auth/MobileNumber";
import EmailOtp from "./pages/auth/EmailOTP";
import ResetPasswordEmail from "./pages/auth/ResetPasswordEmail";
import ResetPassword from "./pages/auth/ResetPassword";
import MobileOtp from "./pages/auth/MobileOtp";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Components
import Accordion from "./pages/components/Accordion";
import Alerts from "./pages/components/Alerts";
import Avatars from "./pages/components/Avatars";
import Badges from "./pages/components/Badges";
import Buttons from "./pages/components/Buttons";
import Cards from "./pages/components/Cards";
import Chips from "./pages/components/Chips";
import Dialogs from "./pages/components/Dialogs";
import Lists from "./pages/components/Lists";
import Menus from "./pages/components/Menus";
import Pagination from "./pages/components/Pagination";
import Progress from "./pages/components/Progress";
import Snackbars from "./pages/components/Snackbars";
import Tooltips from "./pages/components/Tooltips";

// Form components
import SelectionCtrls from "./pages/forms/SelectionControls";
import Selects from "./pages/forms/Selects";
import TextFields from "./pages/forms/TextFields";

// Icon components
// eslint-disable-next-line import/no-named-as-default-member
import MaterialIcons from "./pages/icons/MaterialIcons";

// Page components
import Blank from "./pages/pages/Blank";
import InvoiceDetails from "./pages/pages/InvoiceDetails";
import InvoiceList from "./pages/pages/InvoiceList";
import Orders from "./pages/pages/Orders";
import Pricing from "./pages/pages/Pricing";
import Settings from "./pages/pages/Settings";
import Projects from "./pages/pages/Projects";
import Chat from "./pages/pages/Chat";

// Table components
import SimpleTable from "./pages/tables/SimpleTable";
import AdvancedTable from "./pages/tables/AdvancedTable";

// Documentation
import Welcome from "./pages/docs/Welcome";
import GettingStarted from "./pages/docs/GettingStarted";
import Routing from "./pages/docs/Routing";
import Auth0 from "./pages/docs/auth/Auth0";
import Cognito from "./pages/docs/auth/Cognito";
import Firebase from "./pages/docs/auth/Firebase";
import JWT from "./pages/docs/auth/JWT";
import Guards from "./pages/docs/Guards";
import EnvironmentVariables from "./pages/docs/EnvironmentVariables";
import Deployment from "./pages/docs/Deployment";
import Theming from "./pages/docs/Theming";
import APICalls from "./pages/docs/APICalls";
import Redux from "./pages/docs/Redux";
import Internationalization from "./pages/docs/Internationalization";
import ESLintAndPrettier from "./pages/docs/ESLintAndPrettier";
import MigratingToNextJS from "./pages/docs/MigratingToNextJS";
import Support from "./pages/docs/Support";
import Changelog from "./pages/docs/Changelog";

// Rasutomairu Landing
import RasutomairuLanding from "./pages/presentation/rasutomairu_landing/RasutomairuLandingPage";

// IndexCloud Landing
import IndexCloudLanding from "./pages/presentation/indexcloud_landing";

// shipper landing
import ShipperLanding from "./pages/presentation/shipperLanding/ShipperLandingIndex";

// User Place Order
import UserPlaceOrder from "./pages/orders/UserPlaceOrder";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";

import { authConstants } from "./utils/authConstants";

// Dashboard components
const Default = async(() =>
  import("./pages/dashboards/Default/IndexShipperHome")
);
const Analytics = async(() => import("./pages/dashboards/Analytics"));
const SaaS = async(() => import("./pages/dashboards/SaaS"));

// Form components
const Pickers = async(() => import("./pages/forms/Pickers"));
const Editors = async(() => import("./pages/forms/Editors"));
const Formik = async(() => import("./pages/forms/Formik"));

// Icon components
const FeatherIcons = async(() => import("./pages/icons/FeatherIcons"));
const Profile = async(() => import("./pages/pages/Profile"));
const Tasks = async(() => import("./pages/pages/Tasks"));
const Calendar = async(() => import("./pages/pages/Calendar"));

// Table components
const DataGrid = async(() => import("./pages/tables/DataGrid"));

// Chart components
const Chartjs = async(() => import("./pages/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("./pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("./pages/maps/VectorMaps"));

// Shipper Components
const ShipperSummary = async(() =>
  import("./pages/shipments/summary/ShipmentSummaryIndex")
);
const AddShipment = async(() =>
  import("./pages/shipments/add_shipments/Index")
);
const AllShipments = async(() =>
  import("./pages/shipments/all_shipments/AllShipments")
);
const NewShipments = async(() =>
  import("./pages/shipments/new_shipments/NewShipments")
);
const TrackBulk = async(() => import("./pages/shipments/track_bulk/TrackBulk"));
const ShipmentReports = async(() =>
  import("./pages/shipments/reports/ShipmentReports")
);
const ReturnSummary = async(() =>
  import("./pages/returns/summary/ReturnSummaryIndex")
);
const PendingReturns = async(() =>
  import("./pages/returns/pending/PendingReturns")
);
const AllReturns = async(() => import("./pages/returns/all/AllReturns"));
const ReturnReports = async(() =>
  import("./pages/returns/reports/ReturnShipmentReports")
);
const PaymentSummary = async(() => import("./pages/payments/summary/Summary"));
const PendingPayments = async(() =>
  import("./pages/payments/pending/PendingPayments")
);
const CompltedPayments = async(() =>
  import("./pages/payments/completed/CompletedPayments")
);
const PaymentReports = async(() =>
  import("./pages/payments/reports/PaymentReports")
);
const ViewPayment = async(() =>
  import("./pages/payments/view_payment/ViewPayment")
);
const BillingSummary = async(() => import("./pages/billing/summary/Summary"));
const PendingBills = async(() =>
  import("./pages/billing/pending/PendingBills")
);
const PaidBills = async(() => import("./pages/billing/paid/PaidBills"));
const ShipperServiceFeeSummary = async(() =>
  import("./pages/billing/ServiceFee/ServiceFeeIndex")
);
const BillingReports = async(() =>
  import("./pages/billing/reports/BillingReports")
);
const ShipmentProgressView = async(() =>
  import("./pages/shipments/progress_view/ProgressView")
);
const PickupRequests = async(() =>
  import("./pages/pickups/pickupRequests/ShipperPickupsIndex")
);
const PendingOrders = async(() =>
  import("./pages/orders/PendingOrders/PendingOrders")
);
const GenerateUrl = async(() =>
  import("./pages/orders/GenerateOrderUrl/GenerateOrderUrl")
);
const ItemList = async(() => import("./pages/orders/UrlList/UrlList"));

// shipper settings components
const ShipperLandingPageSettings = async(() =>
  import("./pages/shipperSettings/landingPageSettings/LandingPageSettings")
);

// service provider components
const Home = async(() => import("./pages/serviceProvider/home/Index"));
const SetupOperations = async(() =>
  import("./pages/serviceProvider/onboading/index")
);
const PickupsSummary = async(() =>
  import("./pages/serviceProvider/pickups/summary/index")
);
const AllPickupRequests = async(() =>
  import("./pages/serviceProvider/pickups/pickupRequests/AllPickupRequests")
);
const NewPickupRequests = async(() =>
  import("./pages/serviceProvider/pickups/pickupRequests/NewPickupRequests")
);
const AgingRequests = async(() =>
  import("./pages/serviceProvider/pickups/agingRequests/AgingRequests")
);
const PickupScan = async(() =>
  import("./pages/serviceProvider/pickups/scanAtHub/ScanAtHub")
);
const PickupClearance = async(() =>
  import("./pages/serviceProvider/pickups/pickupClearance/PickupClearance")
);
const PickupErrorLog = async(() =>
  import("./pages/serviceProvider/pickups/scanAtHub/ErrorLog")
);
const DispatchSummary = async(() =>
  import("./pages/serviceProvider/dispatch/summary/index")
);
const DispatchShipments = async(() =>
  import("./pages/serviceProvider/dispatch/dispatch/index")
);
const DispatchHistory = async(() =>
  import("./pages/serviceProvider/dispatch/history/DispatchHistory")
);
const ShipmentSummarySP = async(() =>
  import(
    "./pages/serviceProvider/shipments/summary/ServiceProviderShipmentSummary"
  )
);
const ShipmentOutForDelivery = async(() =>
  import("./pages/serviceProvider/shipments/out_for_delivery/index")
);
const PaymentSummarySP = async(() =>
  import("./pages/serviceProvider/payments/summary/index")
);
const ShipperPayablesSP = async(() =>
  import("./pages/serviceProvider/payments/payables/PayablesIndex")
);
const PayablePaymentNotesSP = async(() =>
  import("./pages/serviceProvider/payments/paymentNotes/PayablePaymentNotes")
);
const PaymentSummariesSP = async(() =>
  import("./pages/serviceProvider/payments/paymentSummary/PaymentSummaryIndex")
);
const AllShipmentsSP = async(() =>
  import("./pages/serviceProvider/shipments/allShipments/AllShipments")
);
const AllPaymentNotesSP = async(() =>
  import("./pages/serviceProvider/payments/allPaymentNotes/PaymentNotesIndex")
);
const AddShipmentsSP = async(() =>
  import("./pages/serviceProvider/shipments/add_shipments/index")
);
const PendingReturnSummary = async(() =>
  import("./pages/serviceProvider/returns/summary/index")
);
const DispatchReturn = async(() =>
  import("./pages/serviceProvider/returns/dispatchReturns/index")
);
const ReturnDelivery = async(() =>
  import("./pages/serviceProvider/returns/returnsDelivery/index")
);
const ReturnsShipmentReport = async(() =>
  import("./pages/serviceProvider/returns/reports/ReturnShipmentReport")
);
const ReturnsRunSheets = async(() =>
  import("./pages/serviceProvider/returns/returnRunSheets/ReturnRunSheetIndex")
);
const ReturnsDispatchHistory = async(() =>
  import(
    "./pages/serviceProvider/returns/dispatchReturnsHistory/ReturnDispatchHistory"
  )
);
const ShipmentReportSP = async(() =>
  import("./pages/serviceProvider/shipments/reports/ShipmentReports")
);
const DriverClearance = async(() =>
  import("./pages/serviceProvider/shipments/driverClearances/index")
);
const RunSheets = async(() =>
  import("./pages/serviceProvider/shipments/runsheets/RunSheetsIndex")
);
const InvoicesSummary = async(() =>
  import("./pages/serviceProvider/invoices/summary/index")
);
const ServiceFeeIndex = async(() =>
  import("./pages/serviceProvider/ServiceFee/ServiceFeeIndex")
);
const ReceivableInvoices = async(() =>
  import("./pages/serviceProvider/invoices/receivables/ReceivableInvoicesIndex")
);
const DueInvoices = async(() =>
  import("./pages/serviceProvider/invoices/dueInvoices/DueInvoicesIndex")
);
const AllInvoices = async(() =>
  import("./pages/serviceProvider/invoices/allInvoices/AllInvoicesIndex")
);
const CollectionSummary = async(() =>
  import("./pages/serviceProvider/collections/summary/index")
);
const HubCollections = async(() =>
  import(
    "./pages/serviceProvider/collections/hub_collections/AllHubCollections"
  )
);
const PendingCollectionsSP = async(() =>
  import(
    "./pages/serviceProvider/collections/hub_collections/PendingCollections"
  )
);
const AllCollectionsSP = async(() =>
  import("./pages/serviceProvider/collections/hub_collections/AllCollections")
);
const AllNotifications = async(() =>
  import("./pages/notifications/AllNotifications")
);

// service provider settings components
const OperationSetting = async(() =>
  import("./pages/serviceProviderSettings/operations/index")
);
const HubsSetting = async(() =>
  import("./pages/serviceProviderSettings/Hubs/index")
);
const IndexCreditSp = async(() =>
  import("./pages/serviceProviderSettings/indexCredit/index")
);
const EmployeesSetting = async(() =>
  import("./pages/serviceProviderSettings/employees")
);
const CustomerSetting = async(() =>
  import("./pages/serviceProviderSettings/customers/index")
);
const PriceCards = async(() =>
  import("./pages/serviceProviderSettings/priceCards/index")
);
const ServiceProviderLandingPageSettings = async(() =>
  import("./pages/serviceProviderSettings/landingPageSettings")
);

// service provider profile components
const ServiceProviderProfileInformation = async(() =>
  import("./pages/serviceProviderProfile/profile")
);
const ServiceProviderAccountSettings = async(() =>
  import("./pages/serviceProviderProfile/accountSettings")
);

// service provider admin components
const SystemAdminHome = async(() =>
  import("./pages/serviceProviderAdmin/home/index")
);
const SystemAdminServiceProviders = async(() =>
  import(
    "./pages/serviceProviderAdmin/serviceProviders/AdminServiceProvidersList"
  )
);

const routes = [
  {
    path: "/",
    element: <PresentationLayout />,
    children: [
      {
        path: "",
        element: <IndexCloudLanding />,
      },
    ],
  },
  {
    path: "/:serviceProvider",
    element: <PresentationLayout />,
    children: [
      {
        path: "",
        element: <RasutomairuLanding />,
      },
    ],
  },
  {
    path: "/:serviceProvider/shipper/:shipperName",
    element: <PresentationLayout />,
    children: [
      {
        path: "",
        element: <ShipperLanding />,
      },
    ],
  },

  {
    path: "/order/:slug",
    element: <PresentationLayout />,
    children: [
      {
        path: "",
        element: <UserPlaceOrder />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "default",
        element: <Default />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
      {
        path: "saas",
        element: <SaaS />,
      },
    ],
  },
  {
    path: "pages",
    element: <DashboardLayout />,
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "chat",
        element: <Chat />,
      },
      {
        path: "blank",
        element: <Blank />,
      },
    ],
  },
  {
    path: "projects",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Projects />,
      },
    ],
  },
  {
    path: "invoices",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <InvoiceList />,
      },
      {
        path: "detail",
        element: <InvoiceDetails />,
      },
    ],
  },
  {
    path: "orders",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Orders />,
      },
    ],
  },
  {
    path: "tasks",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Tasks />,
      },
    ],
  },
  {
    path: "calendar",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Calendar />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "auth",
        element: <Auth component={authConstants.SIGN_IN} />,
      },
      {
        path: "bank-information",
        element: <BankInformation />,
      },
      {
        path: "pickup-information",
        element: <PickUpInformation />,
      },
      {
        path: "billing-information",
        element: <BillingInformation />,
      },
      {
        path: "mobile-otp",
        element: <MobileOtp />,
      },
      {
        path: "mobile-number",
        element: <MobileNumber />,
      },
      {
        path: "reset-password",
        element: <ResetPasswordEmail />,
      },
      {
        path: "reset-password/:userId",
        element: <ResetPassword />,
      },
      {
        path: "email-otp",
        element: <EmailOtp />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  // shipper auth
  {
    path: "/:serviceProvider/shipper/auth",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <Auth component={authConstants.SIGN_IN} />,
      },
      {
        path: "sign_up",
        element: <Auth component={authConstants.SIGN_UP} />,
      },
      {
        path: "bank-information",
        element: <BankInformation />,
      },
      {
        path: "pickup-information",
        element: <PickUpInformation />,
      },
      {
        path: "billing-information",
        element: <BillingInformation />,
      },
      {
        path: "mobile-otp",
        element: <MobileOtp />,
      },
      {
        path: "mobile-number",
        element: <MobileNumber />,
      },
      {
        path: "reset-password",
        element: <ResetPasswordEmail />,
      },
      {
        path: "reset-password/:userId",
        element: <ResetPassword />,
      },
      {
        path: "email-otp",
        element: <EmailOtp />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  // shipper dashboard
  {
    path: "/:serviceProvider/shipper/:shipperName/dashboard",
    element: (
      <ShipperAuthGuard>
        <DashboardLayout />
      </ShipperAuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Default />,
      },
    ],
  },
  {
    path: "/:serviceProvider/shipper/:shipperName/shipments",
    element: (
      <ShipperAuthGuard>
        <DashboardLayout />
      </ShipperAuthGuard>
    ),
    children: [
      {
        path: "summary",
        element: <ShipperSummary />,
      },
      {
        path: "add_shipments",
        element: <AddShipment />,
      },
      {
        path: "all_shipments",
        element: <AllShipments />,
      },
      {
        path: "new_shipments",
        element: <NewShipments />,
      },
      {
        path: "track_bulk",
        element: <TrackBulk />,
      },
      {
        path: "reports",
        element: <ShipmentReports />,
      },
      {
        path: "progress_view/:shipmentId",
        element: <ShipmentProgressView />,
      },
    ],
  },
  {
    path: "/:serviceProvider/shipper/:shipperName/returns",
    element: (
      <ShipperAuthGuard>
        <DashboardLayout />
      </ShipperAuthGuard>
    ),
    children: [
      {
        path: "summary",
        element: <ReturnSummary />,
      },
      {
        path: "pending_returns",
        element: <PendingReturns />,
      },
      {
        path: "all_returns",
        element: <AllReturns />,
      },
      {
        path: "reports",
        element: <ReturnReports />,
      },
    ],
  },
  {
    path: "/:serviceProvider/shipper/:shipperName/pickups",
    element: (
      <ShipperAuthGuard>
        <DashboardLayout />
      </ShipperAuthGuard>
    ),
    children: [
      {
        path: "pickup_requests",
        element: <PickupRequests />,
      },
    ],
  },
  {
    path: "/:serviceProvider/shipper/:shipperName/orders",
    element: (
      <ShipperAuthGuard>
        <DashboardLayout />
      </ShipperAuthGuard>
    ),
    children: [
      {
        path: "PendingOrders",
        element: <PendingOrders />,
      },
      {
        path: "generate_url",
        element: <GenerateUrl />,
      },
      {
        path: "UrlList",
        element: <ItemList />,
      },
    ],
  },
  {
    path: "/:serviceProvider/shipper/:shipperName/payments",
    element: (
      <ShipperAuthGuard>
        <DashboardLayout />
      </ShipperAuthGuard>
    ),
    children: [
      {
        path: "summary",
        element: <PaymentSummary />,
      },
      {
        path: "pending_payments",
        element: <PendingPayments />,
      },
      {
        path: "completed_payments",
        element: <CompltedPayments />,
      },
      {
        path: "reports",
        element: <PaymentReports />,
      },
      {
        path: "pending_payments/view_payment/:id",
        element: <ViewPayment />,
      },
      {
        path: "completed_payments/view_payment/:id",
        element: <ViewPayment />,
      },
    ],
  },
  {
    path: "/:serviceProvider/shipper/:shipperName/invoices",
    element: (
      <ShipperAuthGuard>
        <DashboardLayout />
      </ShipperAuthGuard>
    ),
    children: [
      {
        path: "summary",
        element: <BillingSummary />,
      },
      {
        path: "pending_invoices",
        element: <PendingBills />,
      },
      {
        path: "paid_invoices",
        element: <PaidBills />,
      },
      {
        path: "service_fee_summary",
        element: <ShipperServiceFeeSummary />,
      },
      {
        path: "reports",
        element: <BillingReports />,
      },
    ],
  },
  // service provider auth
  {
    path: "/:serviceProvider/auth",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <Auth component={authConstants.SIGN_IN} />,
      },
      {
        path: "sign_up",
        element: <Auth component={authConstants.SIGN_UP} />,
      },
      {
        path: "bank-information",
        element: <BankInformation />,
      },
      {
        path: "pickup-information",
        element: <PickUpInformation />,
      },
      {
        path: "billing-information",
        element: <BillingInformation />,
      },
      {
        path: "mobile-otp",
        element: <MobileOtp />,
      },
      {
        path: "mobile-number",
        element: <MobileNumber />,
      },
      {
        path: "reset-password",
        element: <ResetPasswordEmail />,
      },
      {
        path: "reset-password/:userId",
        element: <ResetPassword />,
      },
      {
        path: "email-otp",
        element: <EmailOtp />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  // service provider dashboard
  {
    path: "/:serviceProvider/dashboard",
    element: (
      <ServiceProviderAuthGuard>
        <ServiceProviderDashboard />
      </ServiceProviderAuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Home />,
      },
    ],
  },
  {
    path: "/notifications",
    element: (
      <ServiceProviderAuthGuard>
        <ServiceProviderDashboard />
      </ServiceProviderAuthGuard>
    ),
    children: [
      {
        path: "",
        element: <AllNotifications />,
      },
    ],
  },
  {
    path: "/:serviceProvider/setup_operations",
    element: (
      <ServiceProviderAuthGuard>
        <ServiceProviderDashboard />
      </ServiceProviderAuthGuard>
    ),
    children: [
      {
        path: "",
        element: <SetupOperations />,
      },
    ],
  },
  {
    path: "/:serviceProvider/pickups",
    element: (
      <ServiceProviderAuthGuard>
        <ServiceProviderDashboard />
      </ServiceProviderAuthGuard>
    ),
    children: [
      {
        path: "summary",
        element: <PickupsSummary />,
      },
      {
        path: "new_pickup_requests",
        element: <NewPickupRequests />,
      },
      {
        path: "all_pickup_requests",
        element: <AllPickupRequests />,
      },
      {
        path: "aging_requests",
        element: <AgingRequests />,
      },
      {
        path: "scan_at_hub",
        element: <PickupScan />,
      },
      {
        path: "pickup_clearance",
        element: <PickupClearance />,
      },
      {
        path: "error_log",
        element: <PickupErrorLog />,
      },
    ],
  },
  {
    path: "/:serviceProvider/dispatch",
    element: (
      <ServiceProviderAuthGuard>
        <ServiceProviderDashboard />
      </ServiceProviderAuthGuard>
    ),
    children: [
      {
        path: "summary",
        element: <DispatchSummary />,
      },
      {
        path: "dispatch",
        element: <DispatchShipments />,
      },
      {
        path: "history",
        element: <DispatchHistory />,
      },
    ],
  },
  {
    path: "/:serviceProvider/shipments",
    element: (
      <ServiceProviderAuthGuard>
        <ServiceProviderDashboard />
      </ServiceProviderAuthGuard>
    ),
    children: [
      {
        path: "summary",
        element: <ShipmentSummarySP />,
      },
      {
        path: "all_shipments",
        element: <AllShipmentsSP />,
      },
      {
        path: "add_shipments",
        element: <AddShipmentsSP />,
      },
      {
        path: "out_for_delivery",
        element: <ShipmentOutForDelivery />,
      },
      {
        path: "reports",
        element: <ShipmentReportSP />,
      },
      {
        path: "driver_clearance",
        element: <DriverClearance />,
      },
      {
        path: "run_sheets",
        element: <RunSheets />,
      },
      {
        path: "progress_view/:shipmentId",
        element: <ShipmentProgressView />,
      },
    ],
  },
  {
    path: "/:serviceProvider/returns",
    element: (
      <ServiceProviderAuthGuard>
        <ServiceProviderDashboard />
      </ServiceProviderAuthGuard>
    ),
    children: [
      {
        path: "summary",
        element: <PendingReturnSummary />,
      },
      {
        path: "dispatch_return",
        element: <DispatchReturn />,
      },
      {
        path: "return_delivery",
        element: <ReturnDelivery />,
      },
      {
        path: "reports",
        element: <ReturnsShipmentReport />,
      },
      {
        path: "return_run_sheets",
        element: <ReturnsRunSheets />,
      },
      {
        path: "return_dispatch_history",
        element: <ReturnsDispatchHistory />,
      },
    ],
  },
  {
    path: "/:serviceProvider/payments",
    element: (
      <ServiceProviderAuthGuard>
        <ServiceProviderDashboard />
      </ServiceProviderAuthGuard>
    ),
    children: [
      {
        path: "summary",
        element: <PaymentSummarySP />,
      },
      {
        path: "shipper_payables",
        element: <ShipperPayablesSP />,
      },
      {
        path: "payment_notes",
        element: <PayablePaymentNotesSP />,
      },
      {
        path: "payment_summaries",
        element: <PaymentSummariesSP />,
      },
      {
        path: "all_payment_notes",
        element: <AllPaymentNotesSP />,
      },
    ],
  },
  {
    path: "/:serviceProvider/invoices",
    element: (
      <ServiceProviderAuthGuard>
        <ServiceProviderDashboard />
      </ServiceProviderAuthGuard>
    ),
    children: [
      {
        path: "summary",
        element: <InvoicesSummary />,
      },
      {
        path: "receivables",
        element: <ReceivableInvoices />,
      },
      {
        path: "due_invoices",
        element: <DueInvoices />,
      },
      {
        path: "all_invoices",
        element: <AllInvoices />,
      },
      {
        path: "service_fee_summary",
        element: <ServiceFeeIndex />,
      },
    ],
  },
  {
    path: "/:serviceProvider/collections",
    element: (
      <ServiceProviderAuthGuard>
        <ServiceProviderDashboard />
      </ServiceProviderAuthGuard>
    ),
    children: [
      {
        path: "summary",
        element: <CollectionSummary />,
      },
      {
        path: "hub_collections",
        element: <HubCollections />,
      },
      {
        path: "pending_collections",
        element: <PendingCollectionsSP />,
      },
      {
        path: "all_collections",
        element: <AllCollectionsSP />,
      },
    ],
  },

  // landing page auth
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <ServiceProviderAuth component={authConstants.SIGN_IN} />,
      },
      {
        path: "sign_up",
        element: <ServiceProviderAuth component={authConstants.SIGN_UP} />,
      },
    ],
  },

  // service provider settings
  {
    path: "/:serviceProvider/settings",
    element: (
      <ServiceProviderAuthGuard>
        <ServiceProviderSettingsLayout />
      </ServiceProviderAuthGuard>
    ),
    children: [
      {
        path: "operations",
        element: <OperationSetting />,
      },
      {
        path: "shippers",
        element: <CustomerSetting />,
      },
      {
        path: "hubs",
        element: <HubsSetting />,
      },
      {
        path: "employees",
        element: <EmployeesSetting />,
      },
      {
        path: "index_credit",
        element: <IndexCreditSp />,
      },
      {
        path: "price_cards",
        element: <PriceCards />,
      },
      {
        path: "landing_page_settings",
        element: <ServiceProviderLandingPageSettings />,
      },
    ],
  },

  // service provider profile

  {
    path: "/:serviceProvider",
    element: (
      <ServiceProviderAuthGuard>
        <ServiceProviderProfileLayout />
      </ServiceProviderAuthGuard>
    ),
    children: [
      {
        path: "profile",
        element: <ServiceProviderProfileInformation />,
      },
      {
        path: "account_settings",
        element: <ServiceProviderAccountSettings />,
      },
    ],
  },

  // shipper settings
  {
    path: "/:serviceProvider/shipper/:shipperName/settings",
    element: (
      <ShipperAuthGuard>
        <ShipperSettingsLayout />
      </ShipperAuthGuard>
    ),
    children: [
      {
        path: "landing_page_settings",
        element: <ShipperLandingPageSettings />,
      },
    ],
  },

  // shipper profile

  {
    path: "/:serviceProvider/shipper/:shipperName",
    element: (
      <ShipperAuthGuard>
        <ServiceProviderProfileLayout />
      </ShipperAuthGuard>
    ),
    children: [
      {
        path: "profile",
        element: <ServiceProviderProfileInformation />,
      },
      {
        path: "account_settings",
        element: <ServiceProviderAccountSettings />,
      },
    ],
  },

  // system Admin
  {
    path: "admin",
    element: (
      <SystemAdminAuthGuard>
        <SystemAdminLayout />
      </SystemAdminAuthGuard>
    ),
    children: [
      {
        path: "home",
        element: <SystemAdminHome />,
      },
      {
        path: "service_providers",
        element: <SystemAdminServiceProviders />,
      },
    ],
  },

  {
    path: "components",
    element: <DashboardLayout />,
    children: [
      {
        path: "accordion",
        element: <Accordion />,
      },
      {
        path: "alerts",
        element: <Alerts />,
      },
      {
        path: "avatars",
        element: <Avatars />,
      },
      {
        path: "badges",
        element: <Badges />,
      },
      {
        path: "buttons",
        element: <Buttons />,
      },
      {
        path: "cards",
        element: <Cards />,
      },
      {
        path: "chips",
        element: <Chips />,
      },
      {
        path: "dialogs",
        element: <Dialogs />,
      },
      {
        path: "lists",
        element: <Lists />,
      },
      {
        path: "menus",
        element: <Menus />,
      },
      {
        path: "pagination",
        element: <Pagination />,
      },
      {
        path: "progress",
        element: <Progress />,
      },
      {
        path: "snackbars",
        element: <Snackbars />,
      },
      {
        path: "tooltips",
        element: <Tooltips />,
      },
    ],
  },
  {
    path: "forms",
    element: <DashboardLayout />,
    children: [
      {
        path: "pickers",
        element: <Pickers />,
      },
      {
        path: "selection-controls",
        element: <SelectionCtrls />,
      },
      {
        path: "selects",
        element: <Selects />,
      },
      {
        path: "text-fields",
        element: <TextFields />,
      },
      {
        path: "editors",
        element: <Editors />,
      },
      {
        path: "formik",
        element: <Formik />,
      },
    ],
  },
  {
    path: "tables",
    element: <DashboardLayout />,
    children: [
      {
        path: "simple-table",
        element: <SimpleTable />,
      },
      {
        path: "advanced-table",
        element: <AdvancedTable />,
      },
      {
        path: "data-grid",
        element: <DataGrid />,
      },
    ],
  },
  {
    path: "icons",
    element: <DashboardLayout />,
    children: [
      {
        path: "material-icons",
        element: <MaterialIcons />,
      },
      {
        path: "feather-icons",
        element: <FeatherIcons />,
      },
    ],
  },
  {
    path: "charts",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Chartjs />,
      },
    ],
  },
  {
    path: "maps",
    element: <DashboardLayout />,
    children: [
      {
        path: "google-maps",
        element: <GoogleMaps />,
      },
      {
        path: "vector-maps",
        element: <VectorMaps />,
      },
    ],
  },
  {
    path: "documentation",
    element: <DocLayout />,
    children: [
      {
        path: "welcome",
        element: <Welcome />,
      },
      {
        path: "getting-started",
        element: <GettingStarted />,
      },
      {
        path: "routing",
        element: <Routing />,
      },
      {
        path: "auth/auth0",
        element: <Auth0 />,
      },
      {
        path: "auth/cognito",
        element: <Cognito />,
      },
      {
        path: "auth/firebase",
        element: <Firebase />,
      },
      {
        path: "auth/jwt",
        element: <JWT />,
      },
      {
        path: "guards",
        element: <Guards />,
      },
      {
        path: "environment-variables",
        element: <EnvironmentVariables />,
      },
      {
        path: "deployment",
        element: <Deployment />,
      },
      {
        path: "theming",
        element: <Theming />,
      },
      {
        path: "api-calls",
        element: <APICalls />,
      },
      {
        path: "redux",
        element: <Redux />,
      },
      {
        path: "internationalization",
        element: <Internationalization />,
      },
      {
        path: "eslint-and-prettier",
        element: <ESLintAndPrettier />,
      },
      {
        path: "migrating-to-next-js",
        element: <MigratingToNextJS />,
      },
      {
        path: "support",
        element: <Support />,
      },
    ],
  },
  {
    path: "changelog",
    element: <DocLayout />,
    children: [
      {
        path: "",
        element: <Changelog />,
      },
    ],
  },
  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
