import React from "react";
import styled from "styled-components/macro";
import {
  Card as MuiCard,
  CardContent,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  CardHeader,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

function DriverDetails({ shipmentDetails }) {
  const currentAssignedEmployee =
    shipmentDetails.ownership && shipmentDetails.ownership.slice(-1)[0];
  return (
    <Card mb={6} style={{ marginTop: "10px" }}>
      <CardHeader title="Driver Details" />
      <CardContent>
        <Table>
          <TableBody>
            {currentAssignedEmployee ? (
              <>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Driver :
                  </TableCell>
                  <TableCell align="right">
                    {currentAssignedEmployee &&
                      currentAssignedEmployee.emp_full_name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Contact Number :
                  </TableCell>
                  <TableCell align="right">
                    {currentAssignedEmployee &&
                      currentAssignedEmployee.emp_mobile1}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Driver HUB :
                  </TableCell>
                  <TableCell align="right">
                    {currentAssignedEmployee &&
                      currentAssignedEmployee.emp_hub &&
                      currentAssignedEmployee.emp_hub.hub_name}
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <TableRow>
                <TableCell component="th" scope="row">
                  Not available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default DriverDetails;
