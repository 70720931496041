import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allInvoices: {},
  dueInvoices: {},
  SummaryCards: {},
  topTenDebtors: [],
  monthlyInvoices: {},
  pendingCount: [],
  totalCount: [],
  receivableInvoices: [],
  monthLabels: [],
  totReceivable: 0,
  totDueToday: 0,
  totOverDueThreeToday: 0,
  totOverDueMoreThreeToday: 0,
  shipperReceivable: {},
  billingNotes: [],
};

export const serviceProviderInvoicesSlice = createSlice({
  name: "serviceProviderInvoicesSlice",
  initialState,
  reducers: {
    setAllInvoices: (state, action) => {
      state.allInvoices = action.payload;
    },
    setDueInvoices: (state, action) => {
      state.dueInvoices = action.payload;
    },
    setInvoiceSummaryCards: (state, action) => {
      state.SummaryCards = action.payload;
    },
    setTopTenDebtors: (state, action) => {
      state.topTenDebtors = action.payload;
    },
    setMonthlyInvoices: (state, action) => {
      const invoices = action.payload;

      const allmonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

      const result = [];

      allmonths.forEach((month) => {
        const item = invoices.find((item) => item._id === month);

        if (item) {
          result.push(item);
        } else {
          result.push({ _id: month, pending_count: 0, total_count: 0 });
        }
      });

      const totCount = [];
      const penCount = [];
      const monthlyToken = [];

      result.forEach((month) => {
        const count = month.total_count;
        const pending = month.pending_count;
        const monthLabel = month._id;

        switch (monthLabel) {
          case 1:
            monthlyToken.push("Jan");
            break;
          case 2:
            monthlyToken.push("Feb");
            break;
          case 3:
            monthlyToken.push("Mar");
            break;
          case 4:
            monthlyToken.push("Apr");
            break;
          case 5:
            monthlyToken.push("May");
            break;
          case 6:
            monthlyToken.push("Jun");
            break;
          case 7:
            monthlyToken.push("Jul");
            break;
          case 8:
            monthlyToken.push("Aug");
            break;
          case 9:
            monthlyToken.push("Sep");
            break;
          case 10:
            monthlyToken.push("Oct");
            break;
          case 11:
            monthlyToken.push("Nov");
            break;
          case 12:
            monthlyToken.push("Dec");
            break;
          default:
        }

        totCount.push(count);
        penCount.push(pending);
      });

      state.totalCount = totCount;
      state.pendingCount = penCount;
      state.monthLabels = monthlyToken;
    },
    setShipperReceivables: (state, action) => {
      state.receivableInvoices = action.payload;
    },
    setShipperReceivableByID: (state, action) => {
      state.shipperReceivable = action.payload;
    },
    setBillingNotes: (state, action) => {
      state.billingNotes = action.payload;
    },
  },
});

export const {
  setAllInvoices,
  setDueInvoices,
  setTopTenDebtors,
  setShipperReceivables,
  setInvoiceSummaryCards,
  setShipperReceivableByID,
  setBillingNotes,
  setMonthlyInvoices,
} = serviceProviderInvoicesSlice.actions;

export default serviceProviderInvoicesSlice.reducer;
