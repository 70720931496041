/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CitySelectorByDistrictAndServiceProviderQueryVariables = {|
  district_id?: ?string,
  service_provider_id?: ?string,
|};
export type CitySelectorByDistrictAndServiceProviderQueryResponse = {|
  +getAllCities: ?$ReadOnlyArray<?{|
    +_id: ?string,
    +city_name: ?string,
    +city_code: ?string,
  |}>
|};
export type CitySelectorByDistrictAndServiceProviderQuery = {|
  variables: CitySelectorByDistrictAndServiceProviderQueryVariables,
  response: CitySelectorByDistrictAndServiceProviderQueryResponse,
|};
*/


/*
query CitySelectorByDistrictAndServiceProviderQuery(
  $district_id: ID
  $service_provider_id: ID
) {
  getAllCities(district_id: $district_id, service_provider_id: $service_provider_id) {
    _id
    city_name
    city_code
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "district_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "service_provider_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "district_id",
        "variableName": "district_id"
      },
      {
        "kind": "Variable",
        "name": "service_provider_id",
        "variableName": "service_provider_id"
      }
    ],
    "concreteType": "AllCityReturn",
    "kind": "LinkedField",
    "name": "getAllCities",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "city_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "city_code",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CitySelectorByDistrictAndServiceProviderQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CitySelectorByDistrictAndServiceProviderQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d43c4cf4548f0441ef35810670169a2d",
    "id": null,
    "metadata": {},
    "name": "CitySelectorByDistrictAndServiceProviderQuery",
    "operationKind": "query",
    "text": "query CitySelectorByDistrictAndServiceProviderQuery(\n  $district_id: ID\n  $service_provider_id: ID\n) {\n  getAllCities(district_id: $district_id, service_provider_id: $service_provider_id) {\n    _id\n    city_name\n    city_code\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ec56d07af09a7c611cfb5311a478b256';

module.exports = node;
