/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DistrictsContainCitiesByProvinceSelectorQueryVariables = {|
  service_provider_id?: ?string,
  province_id?: ?string,
|};
export type DistrictsContainCitiesByProvinceSelectorQueryResponse = {|
  +getDistrictsBySS: ?$ReadOnlyArray<?{|
    +_id: ?string,
    +district_name: ?string,
  |}>
|};
export type DistrictsContainCitiesByProvinceSelectorQuery = {|
  variables: DistrictsContainCitiesByProvinceSelectorQueryVariables,
  response: DistrictsContainCitiesByProvinceSelectorQueryResponse,
|};
*/


/*
query DistrictsContainCitiesByProvinceSelectorQuery(
  $service_provider_id: ID
  $province_id: ID
) {
  getDistrictsBySS(service_provider_id: $service_provider_id, province_id: $province_id) {
    _id
    district_name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "province_id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "service_provider_id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "province_id",
        "variableName": "province_id"
      },
      {
        "kind": "Variable",
        "name": "service_provider_id",
        "variableName": "service_provider_id"
      }
    ],
    "concreteType": "AllDistrictReturn",
    "kind": "LinkedField",
    "name": "getDistrictsBySS",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "district_name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DistrictsContainCitiesByProvinceSelectorQuery",
    "selections": (v2/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DistrictsContainCitiesByProvinceSelectorQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5c3e36b97a3cac78af40de00f572179c",
    "id": null,
    "metadata": {},
    "name": "DistrictsContainCitiesByProvinceSelectorQuery",
    "operationKind": "query",
    "text": "query DistrictsContainCitiesByProvinceSelectorQuery(\n  $service_provider_id: ID\n  $province_id: ID\n) {\n  getDistrictsBySS(service_provider_id: $service_provider_id, province_id: $province_id) {\n    _id\n    district_name\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eb65513e72cf2f0861cf19bd04d79237';

module.exports = node;
