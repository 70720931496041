import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  FormControl as MuiFormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { spacing } from "@mui/system";

import graphql from "babel-plugin-relay/macro";

const { fetchQuery, useRelayEnvironment } = require("react-relay");

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

function HubsSelector({
  hub,
  setHub,
  hubSelectError,
  setHubSelectError,
  placeholder = "Select Hub",
  errorMessage = "Select Hub",
}) {
  const environment = useRelayEnvironment();
  const [hubList, setHubList] = useState([]);

  const fetchHubs = async () => {
    const user = await localStorage.getItem("user");
    const userJson = JSON.parse(user);
    fetchQuery(
      environment,
      graphql`
        query HubsSelectorQuery($service_provider_id: ID) {
          getAllhubs(service_provider_id: $service_provider_id) {
            _id
            hub_name
            hub_code
          }
        }
      `,
      { service_provider_id: userJson.service_provider._id }
    ).subscribe({
      start: () => {},
      complete: () => {},
      error: () => {},
      next: (data) => {
        setHubList(data.getAllhubs);
      },
    });
  };

  const handleChange = (e) => {
    setHub(e.target.value);
    setHubSelectError(false);
  };
  useEffect(() => {
    fetchHubs();
  }, []);
  return (
    <FormControl style={{ marginTop: "10px" }}>
      <InputLabel id="demo-simple-select-helper-label">
        {placeholder}
      </InputLabel>

      <SelectorDisplay
        values={hub}
        handleChange={handleChange}
        hubList={hubList}
        hubSelectError={hubSelectError}
      />

      <FormHelperText style={{ color: "red" }}>
        {hubSelectError ? errorMessage : ""}
      </FormHelperText>
    </FormControl>
  );
}

function SelectorDisplay({ values, handleChange, hubList, hubSelectError }) {
  return (
    <Select
      labelId="demo-simple-select-helper-label"
      id="demo-simple-select-helper"
      name="destinationHub"
      value={values}
      error={hubSelectError}
      onChange={handleChange}
    >
      {hubList &&
        hubList.map((hub, key) => (
          <MenuItem
            key={key}
            value={`${hub._id}_${hub.hub_name}_${hub.hub_code}`}
          >
            {hub.hub_name}
          </MenuItem>
        ))}
    </Select>
  );
}

export default HubsSelector;
