import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  scannedShipmentsForDelivery: [],
  confirmedOutForDelivery: {},
  driverRunningSheet: {},
  driverLiabilityReport: {},
  shipmentReport: [],
  runningSheets: {},
  oneRunningSheet: {},
  allShipments: {},
  returnRunningSheets: {},
  oneReturnRunningSheet: {},
};

export const serviceProviderShipmentsSlice = createSlice({
  name: "serviceProviderShipments",
  initialState,
  reducers: {
    setConfirmedOutForDelivery: (state, action) => {
      state.confirmedOutForDelivery = action.payload;
    },
    setDriverRunningSheet: (state, action) => {
      state.driverRunningSheet = action.payload;
    },
    setScannedShipmentsForDelivery: (state, action) => {
      state.scannedShipmentsForDelivery = [
        ...state.scannedShipmentsForDelivery,
        action.payload,
      ];
    },
    removeScannedShipmentsForDelivery: (state, action) => {
      state.scannedShipmentsForDelivery =
        state.scannedShipmentsForDelivery.filter(
          (shipment) => shipment._id !== action.payload
        );
    },
    removeAllScannedShipmentsForDelivery: (state) => {
      state.scannedShipmentsForDelivery = [];
    },
    setDriverLiabilityReport: (state, action) => {
      state.driverLiabilityReport = action.payload;
    },
    setShipmentReport: (state, action) => {
      state.shipmentReport = action.payload;
    },
    clearShipmentReport: (state) => {
      state.shipmentReport = [];
    },
    setRunningSheets: (state, action) => {
      state.runningSheets = action.payload;
    },
    setOneRunningSheet: (state, action) => {
      state.oneRunningSheet = action.payload;
    },
    setAllShipments: (state, action) => {
      state.allShipments = action.payload;
    },
    updateShipmentStatusInAllShipments: (state, action) => {
      const updatedShipment = action.payload;
      const objIndex = state.allShipments.shipments.findIndex(
        (obj) => obj._id === updatedShipment._id
      );
      state.allShipments.shipments[objIndex].shipment_status =
        updatedShipment.shipment_status;
    },
    setReturnRunningSheets: (state, action) => {
      state.returnRunningSheets = action.payload;
    },
    setOneReturnRunningSheet: (state, action) => {
      state.oneReturnRunningSheet = action.payload;
    },
  },
});

export const {
  setConfirmedOutForDelivery,
  setDriverRunningSheet,
  setScannedShipmentsForDelivery,
  removeScannedShipmentsForDelivery,
  removeAllScannedShipmentsForDelivery,
  setDriverLiabilityReport,
  setShipmentReport,
  clearShipmentReport,
  setRunningSheets,
  setOneRunningSheet,
  setAllShipments,
  updateShipmentStatusInAllShipments,
  setReturnRunningSheets,
  setOneReturnRunningSheet,
} = serviceProviderShipmentsSlice.actions;

export default serviceProviderShipmentsSlice.reducer;
