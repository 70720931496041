import React, { useState } from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import "./SignUp.css";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  LinearProgress as MuiLinearProgress,
  Select,
  MenuItem,
  FormControl as MuiFormControl,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import graphql from "babel-plugin-relay/macro";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-relay";
import { authResponse } from "../../utils/response_messages/auth";
import { authUserType } from "../../utils/defaultStatus";
import { authConstants } from "../../utils/authConstants";
import { updateUserMobileNumber } from "../../redux/slices/user";
import {
  getCurrentDateTime,
  validateMobile,
} from "../../utils/commonFunctions";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 30%;
`;

function MobileNumber({ setComponent, userTypeProp }) {
  const navigate = useNavigate();
  const [loggedUser, setLoggedUser] = useState(null);
  const [logingErrors, setLoggingErrors] = useState([]);
  const [validMobileNumber, setMobileNumber] = useState();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [commit, isInFlight] = useMutation(graphql`
    mutation MobileNumberMutation(
      $id: ID!
      $user: UserUpdateInput!
      $updated_date: String
    ) {
      addUserMobileNumber(id: $id, user: $user, updated_date: $updated_date) {
        _id
        service_provider {
          service_provider_phone1
        }
        shipper {
          shipper_phone1
        }
      }
    }
  `);

  return (
    <>
      <Typography component="h1" variant="h2" align="center" gutterBottom>
        Let's add your mobile phone number
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Please enter your mobile phone number below. We will use this mobile
        phone number as primary point of contact and in two factor
        authentication to secure your account.
      </Typography>
      <Formik
        initialValues={{
          mobileNumber: "",
          countryCode: "+94",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          mobileNumber: Yup.number()
            .typeError("Please enter a valid mobile phone number")
            .test(
              "maxDigits",
              "Please enter a valid mobile phone number",
              (number) => String(number).length === 9
            )
            .positive()
            .required("Mobile number is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          const { SHIPPER, SERVICE_PROVIDER, EMPLOYEE } = authUserType;
          try {
            switch (userTypeProp) {
              case SHIPPER:
                commit({
                  variables: {
                    id: user._id,
                    user: {
                      user_type: "shipper",
                      shipper: {
                        country_code: values.countryCode,
                        shipper_phone1: validMobileNumber,
                      },
                    },
                    updated_date: getCurrentDateTime(),
                  },
                  onCompleted(data, error) {
                    setLoggedUser(data.addUser);
                    setLoggingErrors(
                      error && error.length > 0
                        ? [{ error: authResponse.FAILURE }]
                        : []
                    );
                    if (data.addUserMobileNumber) {
                      dispatch(
                        updateUserMobileNumber({
                          userType: "shipper",
                          mobileNumber1:
                            data.addUserMobileNumber.shipper.shipper_phone1,
                        })
                      );
                      setComponent(authConstants.MOBILE_OTP);
                    }
                  },
                });
                break;
              case SERVICE_PROVIDER:
                commit({
                  variables: {
                    id: user.service_provider._id,
                    user: {
                      user_type: SERVICE_PROVIDER,
                      service_provider: {
                        country_code: values.countryCode,
                        service_provider_phone1: validMobileNumber,
                      },
                    },
                  },
                  onCompleted(data, error) {
                    setLoggedUser(data.addUser);
                    setLoggingErrors(
                      error && error.length > 0
                        ? [{ error: authResponse.FAILURE }]
                        : []
                    );
                    if (data.addUserMobileNumber) {
                      const currentUser = localStorage.getItem("user");
                      const userJson = JSON.parse(currentUser);
                      navigate(
                        `/${userJson.service_provider.service_provider_company_name}/dashboard`
                      );
                      window.location.reload();
                    }
                  },
                });
                break;
              case EMPLOYEE:
                commit({
                  variables: {
                    id: user.service_provider._id,
                    user: {
                      user_type: SERVICE_PROVIDER,
                      service_provider: {
                        country_code: values.countryCode,
                        service_provider_phone1: validMobileNumber,
                      },
                    },
                    updated_date: getCurrentDateTime(),
                  },
                  onCompleted(data, error) {
                    setLoggedUser(data.addUser);
                    setLoggingErrors(
                      error && error.length > 0
                        ? [{ error: authResponse.FAILURE }]
                        : []
                    );
                    if (data.addUserMobileNumber) {
                      dispatch(
                        updateUserMobileNumber({
                          userType: "service_provider",
                          mobileNumber1:
                            data.addUserMobileNumber.service_provider
                              .service_provider_phone1,
                        })
                      );
                      setComponent(authConstants.MOBILE_OTP);
                    }
                  },
                });
                break;
              default:
                break;
            }
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            {logingErrors.length > 0 && (
              <Alert mb={4} variant="filled" severity="error">
                {authResponse.FAILURE}
              </Alert>
            )}
            {loggedUser && (
              <Alert mb={4} variant="filled" severity="success">
                {authResponse.SUCCESS}
              </Alert>
            )}
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormControl>
                <Select
                  value={values.countryCode}
                  displayEmpty
                  name="countryCode"
                  inputProps={{ "aria-label": "Without label" }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <MenuItem value="+94" selected="selected">
                    LK (+94)
                  </MenuItem>
                </Select>
              </FormControl>
              <div style={{ width: "70%", paddingLeft: "10px" }}>
                <TextField
                  type="text"
                  name="mobileNumber"
                  label="Mobile Phone Number"
                  fullWidth
                  value={values.mobileNumber}
                  error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                  helperText={touched.mobileNumber && errors.mobileNumber}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setMobileNumber(validateMobile(e.target.value));
                  }}
                  my={3}
                  style={{ display: "inline-block" }}
                />
              </div>
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isInFlight}
            >
              Next
            </Button>
            <div className="progress-content">
              <LinearProgress
                variant="determinate"
                value="37.5"
                className="progress"
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default MobileNumber;
