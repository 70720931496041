import * as React from "react";
import styled from "styled-components/macro";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";
import { removeUser } from "../../redux/slices/user";
import { authUserType } from "../../utils/defaultStatus";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const dispatch = useDispatch();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    const user = await localStorage.getItem("user");
    const userJson = JSON.parse(user);
    const { service_provider, shipper_company_name: shipperCompanyName } =
      userJson;
    const { service_provider_company_name: serviceProviderCompanyName } =
      service_provider;
    await signOut();
    dispatch(removeUser());

    switch (userJson.user_type) {
      case authUserType.SHIPPER:
        navigate(
          `/${serviceProviderCompanyName}/shipper/${shipperCompanyName}`
        );
        break;

      case authUserType.SERVICE_PROVIDER:
        navigate(`/${serviceProviderCompanyName}`);
        break;
      case authUserType.EMPLOYEE:
        navigate(`/${serviceProviderCompanyName}`);
        break;
      case authUserType.SYSTEM_ADMIN:
        navigate(`/`);
        break;
      default:
    }
  };

  return (
    <>
      <Tooltip title="Logout">
        <IconButton
          aria-owns={anchorMenu ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Power />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {/* <MenuItem onClick={closeMenu}>Profile</MenuItem> */}
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </>
  );
}

export default NavbarUserDropdown;
