/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserUpdateInput = {|
  user_name?: ?string,
  password?: ?string,
  user_type: string,
  user_level?: ?string,
  shipper?: ?ShipperUpdateInput,
  seller?: ?SellerInput,
  employee?: ?EmployeeUpdateInput,
  service_provider?: ?ServiceProviderInputType,
  user_status?: ?boolean,
  user_create_by?: ?UserUpdateInputField,
  otp_type?: ?string,
|};
export type ShipperUpdateInput = {|
  shipper_id?: ?string,
  shipper_type?: ?string,
  shipper_first_name?: ?string,
  shipper_last_name?: ?string,
  shipper_other_name?: ?string,
  shipper_full_name?: ?string,
  shipper_company_name?: ?string,
  shipper_display_name?: ?string,
  country_code?: ?string,
  shipper_phone1?: ?string,
  shipper_phone2?: ?string,
  billing_due_days?: ?number,
  payment_due_days?: ?number,
  shipper_email?: ?string,
  shipper_contacts?: ?$ReadOnlyArray<?ContactInput>,
  shipper_address_line1?: ?string,
  shipper_address_line2?: ?string,
  shipper_city?: ?CityInput,
  shipper_district?: ?DistrictInput,
  shipper_province?: ?ProvinceInput,
  shipper_address_location?: ?LocationInput,
  account_number?: ?string,
  pickup_location?: ?PickupLocationInput,
  bank?: ?BankInput,
  bank_account_name?: ?string,
  payment_cycle?: ?paymentCycleInput,
  billing_cycle?: ?billingCycleInput,
  payment_method?: ?string,
  shipper_price_card?: ?PriceCardInputType,
  service_provider?: ?string,
  shipper_status?: ?boolean,
  business_registration_doc?: ?string,
  other_doc?: ?string,
  created_date?: ?string,
  user_email_verify_status?: ?boolean,
  user_mobile_verify_status?: ?boolean,
  profile_setting?: ?ShipperProfileSettingsInput,
|};
export type ContactInput = {|
  _id: string,
  user_name: string,
  user_type: string,
  user_level: string,
  user_status: string,
|};
export type CityInput = {|
  _id: string,
  city_name: string,
|};
export type DistrictInput = {|
  _id: string,
  district_name: string,
  district_status?: ?boolean,
|};
export type ProvinceInput = {|
  _id: string,
  province_name: string,
  province_status?: ?boolean,
|};
export type LocationInput = {|
  type: string,
  coordinates: $ReadOnlyArray<?string>,
|};
export type PickupLocationInput = {|
  pickup_address_line1?: ?string,
  pickup_address_line2?: ?string,
  pickup_location_city?: ?CityInput,
  pickup_location_province?: ?ProvinceInput,
  pickup_location_district?: ?DistrictInput,
  pickup_location?: ?LocationInput,
  pickup_zone?: ?ZoneInput,
  pickup_location_hub?: ?PickupLocationHubInput,
|};
export type ZoneInput = {|
  _id?: ?string,
  zone_name?: ?string,
  zone_code?: ?string,
|};
export type PickupLocationHubInput = {|
  _id?: ?string,
  hub_name?: ?string,
  hub_code?: ?string,
|};
export type BankInput = {|
  _id: string,
  bank_name: string,
  bank_code: string,
  branch_code: string,
  branch_name: string,
|};
export type paymentCycleInput = {|
  _id?: ?string,
  payment_cycle_name?: ?string,
  payment_cycle_day?: ?string,
  payment_cycle_date?: ?number,
  payment_cycle_time?: ?string,
  payment_cycle_type?: ?string,
|};
export type billingCycleInput = {|
  _id?: ?string,
  billing_cycle_name?: ?string,
  billing_day?: ?string,
  billing_cycle_date?: ?number,
  billing_cycle_time?: ?string,
  billing_cycle_type?: ?string,
|};
export type PriceCardInputType = {|
  _id?: ?string,
  price_card_name?: ?string,
  price_card_version?: ?string,
  price_card_created_date?: ?string,
  price_card_created_by?: ?userPriceCardInputType,
  price_card_type?: ?string,
  price_card_service?: ?string,
|};
export type userPriceCardInputType = {|
  _id?: ?string,
  user_name?: ?string,
|};
export type ShipperProfileSettingsInput = {|
  logo?: ?string,
  banner?: ?string,
|};
export type SellerInput = {|
  _id?: ?string,
  seller_id?: ?string,
  full_name?: ?string,
  other_name?: ?string,
  company_name?: ?string,
  display_name?: ?string,
  country_code?: ?string,
  phone1?: ?string,
  phone2?: ?string,
  email?: ?string,
|};
export type EmployeeUpdateInput = {|
  emp_code?: ?string,
  emp_full_name?: ?string,
  emp_personal_email?: ?string,
  country_code?: ?string,
  emp_mobile1?: ?string,
|};
export type ServiceProviderInputType = {|
  _id?: ?string,
  service_provider_id?: ?string,
  service_provider_full_name?: ?string,
  service_provider_company_name?: ?string,
  service_provider_email?: ?string,
  country_code?: ?string,
  service_provider_phone1?: ?string,
  service_provider_phone2?: ?string,
  service_provider_address_line1?: ?string,
  service_provider_address_line2?: ?string,
  service_provider_city?: ?ServiceProviderCityInput,
  service_provider_province?: ?ServiceProviderProvinceInput,
  service_provider_zip?: ?string,
  service_provider_district?: ?ServiceProviderDistrictInput,
  service_provider_address_location?: ?ServiceProviderAddrLocationInput,
  service_provider_status?: ?string,
  no_of_users?: ?number,
  hub?: ?$ReadOnlyArray<?ServiceProviderHubsInput>,
  payment_card_details?: ?$ReadOnlyArray<?ServiceProviderPaymentInput>,
  profile_setting?: ?ServiceProviderProfileSettingsInput,
|};
export type ServiceProviderCityInput = {|
  _id?: ?string,
  city_name?: ?string,
|};
export type ServiceProviderProvinceInput = {|
  _id?: ?string,
  province_name?: ?string,
  province_status?: ?boolean,
|};
export type ServiceProviderDistrictInput = {|
  _id?: ?string,
  district_name?: ?string,
  status?: ?boolean,
|};
export type ServiceProviderAddrLocationInput = {|
  type?: ?string,
  coordinates?: ?$ReadOnlyArray<?string>,
|};
export type ServiceProviderHubsInput = {|
  hub_name?: ?string,
  hub_code?: ?string,
  hub_status?: ?boolean,
  hub_address?: ?ServiceProviderHubCityInput,
|};
export type ServiceProviderHubCityInput = {|
  hub_address_city?: ?ServiceProviderHubAddrCityInput,
  hub_address_zip_code?: ?string,
  hub_address_location?: ?ServiceProviderHubAddrLocationInput,
|};
export type ServiceProviderHubAddrCityInput = {|
  _id?: ?string,
  city_name?: ?string,
|};
export type ServiceProviderHubAddrLocationInput = {|
  type?: ?string,
  coordinates?: ?$ReadOnlyArray<?string>,
|};
export type ServiceProviderPaymentInput = {|
  card_type?: ?string,
  card_holder_name?: ?string,
  card_number?: ?string,
  card_expiration_month?: ?number,
  card_expiration_year?: ?number,
  csv_code?: ?number,
|};
export type ServiceProviderProfileSettingsInput = {|
  logo?: ?string,
  banner?: ?string,
|};
export type UserUpdateInputField = {|
  _id?: ?string,
  user_name?: ?string,
  user_type?: ?string,
|};
export type MobileNumberMutationVariables = {|
  id: string,
  user: UserUpdateInput,
  updated_date?: ?string,
|};
export type MobileNumberMutationResponse = {|
  +addUserMobileNumber: ?{|
    +_id: ?string,
    +service_provider: ?{|
      +service_provider_phone1: ?string
    |},
    +shipper: ?{|
      +shipper_phone1: ?string
    |},
  |}
|};
export type MobileNumberMutation = {|
  variables: MobileNumberMutationVariables,
  response: MobileNumberMutationResponse,
|};
*/


/*
mutation MobileNumberMutation(
  $id: ID!
  $user: UserUpdateInput!
  $updated_date: String
) {
  addUserMobileNumber(id: $id, user: $user, updated_date: $updated_date) {
    _id
    service_provider {
      service_provider_phone1
    }
    shipper {
      shipper_phone1
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "updated_date"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "user"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "updated_date",
        "variableName": "updated_date"
      },
      {
        "kind": "Variable",
        "name": "user",
        "variableName": "user"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "addUserMobileNumber",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceProviderReturn",
        "kind": "LinkedField",
        "name": "service_provider",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "service_provider_phone1",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ShipperReturn",
        "kind": "LinkedField",
        "name": "shipper",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shipper_phone1",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MobileNumberMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "MobileNumberMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "eca33c727743688fbd2ea1d7d7b43d92",
    "id": null,
    "metadata": {},
    "name": "MobileNumberMutation",
    "operationKind": "mutation",
    "text": "mutation MobileNumberMutation(\n  $id: ID!\n  $user: UserUpdateInput!\n  $updated_date: String\n) {\n  addUserMobileNumber(id: $id, user: $user, updated_date: $updated_date) {\n    _id\n    service_provider {\n      service_provider_phone1\n    }\n    shipper {\n      shipper_phone1\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c5cd23a89498c7094a40f57d4c340e24';

module.exports = node;
