import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  scannedShipmentsForReturnDelivery: [],
  confirmedReturnDelivery: {},
  confirmedReturnDispatch: {},
  driverRunningSheet: {},
  driverLiabilityReport: {},
  shipmentReport: [],
};

export const serviceProviderReturnsSlice = createSlice({
  name: "serviceProviderReturns",
  initialState,
  reducers: {
    setConfirmedReturnDelivery: (state, action) => {
      state.confirmedReturnDelivery = action.payload;
    },
    setDriverRunningSheet: (state, action) => {
      state.driverRunningSheet = action.payload;
    },
    setScannedShipmentsForReturnDelivery: (state, action) => {
      state.scannedShipmentsForReturnDelivery = [
        ...state.scannedShipmentsForReturnDelivery,
        action.payload,
      ];
    },
    removeScannedShipmentsForReturnDelivery: (state, action) => {
      state.scannedShipmentsForReturnDelivery =
        state.scannedShipmentsForReturnDelivery.filter(
          (shipment) => shipment._id !== action.payload
        );
    },
    removeAllScannedShipmentsForReturnDelivery: (state) => {
      state.scannedShipmentsForReturnDelivery = [];
    },
    setDriverLiabilityReport: (state, action) => {
      state.driverLiabilityReport = action.payload;
    },
    setShipmentReport: (state, action) => {
      state.shipmentReport = action.payload;
    },
    clearShipmentReport: (state) => {
      state.shipmentReport = [];
    },
    setConfirmedReturnDispatch: (state, action) => {
      state.confirmedReturnDispatch = action.payload;
    },
  },
});

export const {
  setConfirmedReturnDelivery,
  setDriverRunningSheet,
  setScannedShipmentsForReturnDelivery,
  removeScannedShipmentsForReturnDelivery,
  removeAllScannedShipmentsForReturnDelivery,
  setDriverLiabilityReport,
  setShipmentReport,
  clearShipmentReport,
  setConfirmedReturnDispatch,
} = serviceProviderReturnsSlice.actions;

export default serviceProviderReturnsSlice.reducer;
