import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hubCollectionsList: [],
};

export const ServiceProviderHubCollections = createSlice({
  name: "serviceProviderHubCollections",
  initialState,
  reducers: {
    setHubCollections: (state, action) => {
      if (action.payload) {
        state.hubCollectionsList = action.payload;
      }
    },
  },
});

export const { setHubCollections } = ServiceProviderHubCollections.actions;

export default ServiceProviderHubCollections.reducer;
