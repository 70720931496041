import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  zones: [],
};

export const serviceProviderZonesSlice = createSlice({
  name: "serviceProviderZones",
  initialState,
  reducers: {
    setZones: (state, action) => {
      state.zones = action.payload;
    },
    updateNewZone: (state, action) => {
      const updatedZone = action.payload;
      const objIndex = state.zones.findIndex(
        (obj) => obj._id === updatedZone._id
      );
      state.zones[objIndex] = updatedZone;
    },
  },
});

export const { setZones, updateNewZone } = serviceProviderZonesSlice.actions;

export default serviceProviderZonesSlice.reducer;
