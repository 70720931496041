import React from "react";
import Barcode from "react-barcode";

import styled from "styled-components";

const Wrapper = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
  transform: rotate(270deg);
`;

function HubBarCode({ shipment }) {
  return (
    <Wrapper>
      <Barcode
        value={
          shipment.shipper.pickup_locations &&
          shipment.shipper.pickup_locations.hub &&
          shipment.shipper.pickup_locations.hub.hub_code
        }
        displayValue={false}
        height={60}
        width={3}
        format="CODE128"
        font="Avenir Next"
        fontOptions="200"
        textMargin={2}
        margin={0}
      />

      <h1>
        <span style={{ fontWeight: "bold", fontSize: "24px" }}>Hub:&nbsp;</span>
        <span style={{ fontWeight: "normal", fontSize: "24px" }}>
          {shipment.shipper?.pickup_locations?.hub?.hub_code}
        </span>
      </h1>
    </Wrapper>
  );
}

export default HubBarCode;
