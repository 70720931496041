import React from "react";
import styled from "styled-components/macro";
import { Grid } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AddImgWrapp1 = styled.div`
  img {
    width: 300px;
    height: 300px;
  }
  @media (max-width: 860px) {
    justify-content: center;
  }
`;

const EachSlide = styled.div`
  height: 300px;
  width: 300px;

  @media (max-width: 760px) {
    width: 300px;
    justify-content: center;

    .slick-dots {
      display: block;
      width: 100%;
      position: relative;
      bottom: -5px;
      list-style: none;
      text-align: center;
    }
  }
`;

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

function ImageSlider({ productImages }) {
  return (
    <Grid container spacing={2}>
      <Grid
        container
        item
        xs={12}
        md={12}
        lg={12}
        xl={12}
        justifyContent="center"
      >
        <EachSlide>
          <Slider {...settings}>
            {productImages &&
              productImages.map((imageUrl) => (
                <AddImgWrapp1>
                  <img src={imageUrl} alt="productPic1" />
                </AddImgWrapp1>
              ))}
          </Slider>
        </EachSlide>
      </Grid>
    </Grid>
  );
}

export default ImageSlider;
