import React from "react";
import Barcode from "react-barcode";

import styled from "styled-components";

const Wrapper = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-family: sans-serif;
`;

function TRKBarCode({ shipment }) {
  return (
    <Wrapper>
      <Barcode
        value={shipment.traking_id}
        displayValue={false}
        height={80}
        width={3}
        format="CODE128"
        font="Avenir Next"
        fontOptions="400"
        textMargin={2}
        margin={0}
      />
    </Wrapper>
  );
}

export default TRKBarCode;
