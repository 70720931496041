import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { fetchQuery, useRelayEnvironment } from "react-relay";
import _ from "lodash";
import Loader from "../../../components/Loader";
import Page404 from "../../auth/Page404";
import ShipperLanding from "./ShipperLanding";

function ShipperLandingIndex({ onEdit, setIsErrorUploading }) {
  const { shipperName } = useParams();
  const [currentUser, setCurrentUser] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const environment = useRelayEnvironment();
  const location = useLocation();

  const fetchUserDetails = async () => {
    fetchQuery(
      environment,
      graphql`
        query ShipperLandingIndexQuery($company_name: String) {
          getShipperByCompanyName(company_name: $company_name) {
            _id
            shipper_company_name
            profile_setting {
              logo
              banner
            }
          }
        }
      `,
      {
        company_name: shipperName,
      }
    ).subscribe({
      start: () => {
        setIsLoading(true);
      },
      complete: () => {
        setIsLoading(false);
      },
      error: () => {},
      next: (data) => {
        if (data.getShipperByCompanyName) {
          setCurrentUser(data.getShipperByCompanyName);
        }
      },
    });
  };

  useEffect(() => {
    fetchUserDetails();
  }, [location]);

  if (isLoading) return <Loader />;

  if (_.isEmpty(currentUser)) return <Page404 />;

  return (
    <ShipperLanding
      onEdit={onEdit}
      setIsErrorUploading={setIsErrorUploading}
      currentUser={currentUser}
      fetchUserDetails={fetchUserDetails}
    />
  );
}

export default ShipperLandingIndex;
