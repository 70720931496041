import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  FormControl as MuiFormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { spacing } from "@mui/system";

import graphql from "babel-plugin-relay/macro";

const { fetchQuery, useRelayEnvironment } = require("react-relay");

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

function DistrictSelectorByProvince({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
}) {
  return (
    <FormControl style={{ marginTop: "10px" }}>
      <InputLabel id="demo-simple-select-helper-label">District</InputLabel>

      <SelectorDisplay
        values={values}
        touched={touched}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />

      <FormHelperText style={{ color: "red" }}>
        {touched.district && errors.district ? "District is required." : ""}
      </FormHelperText>
    </FormControl>
  );
}

function SelectorDisplay({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
}) {
  const [districtList, setDistrictList] = useState([]);
  const environment = useRelayEnvironment();
  const fetchDistricts = async () => {
    fetchQuery(
      environment,
      graphql`
        query DistrictSelectorByProvinceQuery($provice_id: ID) {
          getDistricts(provice_id: $provice_id) {
            _id
            district_name
          }
        }
      `,
      { provice_id: values.province.split("_")[0] }
    ).subscribe({
      start: () => {},
      complete: () => {},
      error: () => {
        setDistrictList([]);
      },
      next: (data) => {
        if (data.getDistricts) {
          setDistrictList(data.getDistricts);
        } else {
          setDistrictList([]);
        }
      },
    });
  };

  useEffect(() => {
    if (values.province) {
      fetchDistricts();
    }
  }, [values.province]);

  return (
    <Select
      labelId="demo-simple-select-helper-label"
      id="demo-simple-select-helper"
      name="district"
      value={values.district}
      error={Boolean(touched.district && errors.district)}
      helperText={touched.district && errors.district}
      onBlur={handleBlur}
      onChange={handleChange}
    >
      {districtList.map((district, key) => (
        <MenuItem key={key} value={`${district._id}_${district.district_name}`}>
          {district.district_name}
        </MenuItem>
      ))}
    </Select>
  );
}

export default DistrictSelectorByProvince;
