import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  paymentCycles: [],
};

export const serviceProviderPaymentCyclesSlice = createSlice({
  name: "serviceProviderPaymentCycles",
  initialState,
  reducers: {
    setPaymentCycles: (state, action) => {
      state.paymentCycles = action.payload;
    },
    updateNewPaymentCycles: (state, action) => {
      const updatedPaymentCycles = action.payload;
      const objIndex = state.paymentCycles.findIndex(
        (obj) => obj._id === updatedPaymentCycles._id
      );
      state.paymentCycles[objIndex] = updatedPaymentCycles;
    },
  },
});

export const { setPaymentCycles, updateNewPaymentCycles } =
  serviceProviderPaymentCyclesSlice.actions;

export default serviceProviderPaymentCyclesSlice.reducer;
