import React from "react";
import { Typography } from "@mui/material";
import DLimage from "../../utils/images/DL.png";

const ShipmentLabelFooter = ({ shipment, serviceProviderLogo }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {serviceProviderLogo && (
        <div
          style={{
            marginBottom: "20px",
            marginLeft: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "140px",
              height: "140px",
              border: "5px solid #dfe6ed",
            }}
          >
            <img
              src={serviceProviderLogo}
              width="100%"
              height="100%"
              alt="logo"
            />
          </div>
        </div>
      )}
      <div
        style={{
          backgroundColor: "#dfe6ed",
          height: "140px",
          display: "flex",
          flexDirection: "row",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          marginLeft: serviceProviderLogo ? "10px" : "20px",
          marginBottom: "10px",
          marginRight: "10px",
        }}
      >
        <Typography
          variant="h1"
          sx={{ fontWeight: "normal", fontSize: "30px" }}
        >
          {shipment.service_provider.service_provider_company_name}
        </Typography>
      </div>
      <div
        style={{
          width: "140px",
          height: "140px",
          marginBottom: "20px",
          marginRight: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={DLimage} width="100%" height="100%" alt="service type" />
      </div>
    </div>
  );
};

export default ShipmentLabelFooter;
