import React from "react";
import styled from "styled-components/macro";
import {
  Card as MuiCard,
  CardContent,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from "@mui/material";
import { spacing } from "@mui/system";
import { shipmentHandlingType } from "../../../utils/defaultStatus";

const Card = styled(MuiCard)(spacing);

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

function DetailsThree({ shipmentDetails }) {
  return (
    <Card mb={6} style={{ marginTop: "10px" }}>
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                HANDLING
              </TableCell>
              <TableCell align="right">
                {shipmentDetails.handling_type ===
                  shipmentHandlingType.STANDARD && "Standard"}
                {shipmentDetails.handling_type ===
                  shipmentHandlingType.FRAGILE && "Fragile"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default DetailsThree;
