import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { borderRadius, rgba } from "polished";

import {
  Box,
  Button,
  Container,
  Grid,
  Tooltip,
  Typography as MuiTypography,
  Card as MuiCard,
  CardContent as MuiCardContent,
  List,
  ListItemIcon,
  ListSubheader,
  Avatar as MuiAvatar,
  ListItem,
  ListItemText as MuiListItemText,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  Visibility as VisibilityIcon,
  ArrowForward as ArrowForwardIcon,
  Image as ImageIcon,
  Work as WorkIcon,
  BeachAccess as BeachAccessIcon,
  CheckCircle,
} from "@mui/icons-material";
import {
  PlayCircle,
  DollarSign,
  Database,
  Zap,
  Navigation,
} from "react-feather";
import FeaturePic1 from "../../../utils/images/Landing_page_feature_1.png";
import FeaturePic2 from "../../../utils/images/landing_page_feature2.png";
import FeaturePic3 from "../../../utils/images/Landing_page_feature_3.png";
import FeaturePic4 from "../../../utils/images/landing_page_feature4.png";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Avatar = styled(MuiAvatar)`
  margin: ${(props) => props.theme.spacing(2)};
  background: ${(props) => rgba(props.theme.palette.primary.main, 1)};
`;

const ListItemText = styled(MuiListItemText)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Wrapper = styled.div`
  padding-top: 3.5rem;
  position: relative;
  text-align: center;
  overflow: hidden;
`;

const Content = styled.div`
  padding: ${(props) => props.theme.spacing(6)} 0;
  line-height: 150%;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  box-shadow: 0 6px 18px 0 rgba(18, 38, 63, 0.075);
  border-radius: 5px;
  transform: perspective(2000px) rotateX(25deg);
  z-index: 0;
  position: relative;
  image-rendering: optimizequality;
  image-rendering: -webkit-optimize-contrast;
  margin-bottom: -100px;
  margin-top: -35px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: -50px;
  }
`;

const ImageWrapper = styled.div`
  &:before {
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.02));
    bottom: 0;
    left: 0;
    position: absolute;
    content: " ";
    z-index: 1;
    display: block;
    width: 100%;
    height: 75px;
    pointer-events: none;
  }
`;

const Title = styled(Typography)`
  opacity: 0.9;
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 2rem;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2.5rem;
  }

  span {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)} 0;
`;

const BrandIcon = styled.img`
  vertical-align: middle;
  margin-right: ${(props) => props.theme.spacing(3)};
  height: auto;
`;

const Visibility = styled(VisibilityIcon)`
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const ArrowForward = styled(ArrowForwardIcon)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const Version = styled(MuiTypography)`
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  background: ${(props) => rgba(props.theme.palette.primary.main, 0.1)};
  box-shadow: 0 1px 1px
    ${(props) => rgba(props.theme.palette.primary.main, 0.25)};
  padding: 3px 8px;
  border-radius: 4px;
  margin-bottom: ${(props) => props.theme.spacing(3)};
  display: inline-block;
`;

function OptimizedList() {
  return (
    <List>
      <ListItem>
        <CheckCircle color="secondary" fontSize="large" />

        <ListItemText primary="Powerful process from pickup request to delivery" />
      </ListItem>
      <ListItem>
        <CheckCircle color="secondary" fontSize="large" />

        <ListItemText primary="Track and manage returns" />
      </ListItem>
      <ListItem>
        <CheckCircle color="secondary" fontSize="large" />

        <ListItemText primary="Collect and pay Cash On Delivery(COD) in a click" />
      </ListItem>
    </List>
  );
}

function PayCODList() {
  return (
    <List>
      <ListItem>
        <CheckCircle color="secondary" fontSize="large" />

        <ListItemText primary="Automated and configurable COD reconcilations" />
      </ListItem>
      <ListItem>
        <CheckCircle color="secondary" fontSize="large" />

        <ListItemText primary="Make COD payments to shippers in seconds" />
      </ListItem>
      <ListItem>
        <CheckCircle color="secondary" fontSize="large" />

        <ListItemText primary="Complete visibility of overall payment status" />
      </ListItem>
    </List>
  );
}

function NeverLoseList() {
  return (
    <List>
      <ListItem>
        <CheckCircle color="secondary" fontSize="large" />

        <ListItemText primary="Automated and configurable collection reconcilations" />
      </ListItem>
      <ListItem>
        <CheckCircle color="secondary" fontSize="large" />

        <ListItemText primary="Track and trace who owes you, how much and when" />
      </ListItem>
      <ListItem>
        <CheckCircle color="secondary" fontSize="large" />

        <ListItemText primary="Top to bottom drill down capability for insights" />
      </ListItem>
    </List>
  );
}

function BusinessGrowingList() {
  return (
    <List>
      <ListItem>
        <CheckCircle color="secondary" fontSize="large" />

        <ListItemText primary="30-day free trial" />
      </ListItem>
      <ListItem>
        <CheckCircle color="secondary" fontSize="large" />

        <ListItemText primary="Peronalized onboarding" />
      </ListItem>
      <ListItem>
        <CheckCircle color="secondary" fontSize="large" />

        <ListItemText primary="Access all features" />
      </ListItem>
    </List>
  );
}

function Features() {
  return (
    <Wrapper>
      <Container>
        <Grid container alignItems="center" justifyContent="center" spacing={4}>
          <Grid item xs={12} sm={9} md={8} lg={8}>
            <Content>
              <Version variant="body1">Features</Version>
              <Title variant="h1" gutterBottom>
                Deliver faster and more with real time tracking
              </Title>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item xs={12} lg={10}>
                  <Subtitle color="textSecondary">
                    Manage your operations with simple and easy to use
                    interfaces for all your teams across unlimited number of
                    delivery hubs and delivery agents
                  </Subtitle>
                </Grid>
              </Grid>
            </Content>
          </Grid>
        </Grid>
      </Container>
      <Grid container spacing={6}>
        <Grid item xs={6} mt={15}>
          <Content>
            <Grid ml={60}>
              <Avatar>
                <Navigation fontSize="large" />
              </Avatar>
            </Grid>
            <Title variant="h5" gutterBottom>
              Optimized Processes
            </Title>
            <Grid container justifyContent="left" spacing={4}>
              <Grid item xs={12} lg={10} ml={30}>
                <Subtitle color="textSecondary">
                  We have run delivery operations for more than 5 years
                  ourselves.
                </Subtitle>
              </Grid>

              <Grid item xs={12} lg={10} ml={60}>
                <OptimizedList />
              </Grid>
              <Grid item xs={12} lg={4} mt={5} ml={65}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button
                    ml={2}
                    color="primary"
                    variant="outlined"
                    color="primary"
                    startIcon={<PlayCircle />}
                  >
                    Request Demo
                  </Button>

                  <Button ml={4} color="primary" variant="contained">
                    Sign up FREE
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Content>
        </Grid>
        <Grid item xs={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: 400,
            }}
          >
            <img
              src={FeaturePic1}
              height="600px"
              style={{
                borderRadius: 8,
              }}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={6} mt={10}>
        <Grid item xs={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingRight: 300,
            }}
          >
            <img
              src={FeaturePic2}
              height="600px"
              style={{
                borderRadius: 8,
              }}
            />
          </div>
        </Grid>
        <Grid item xs={6} mt={15}>
          <Content>
            <Grid ml={20}>
              <Avatar>
                <DollarSign fontSize="large" />
              </Avatar>
            </Grid>

            <Title variant="h5" gutterBottom>
              Pay COD to your shippers in seconds
            </Title>
            <Grid container justifyContent="center" spacing={4}>
              <Grid item xs={12} lg={10} ml={8}>
                <Subtitle align="left" color="textSecondary">
                  Our proven workflows do the hard work for you. You will be
                  able to pay COD collections to your shippers in seconds, not
                  days!
                </Subtitle>
              </Grid>

              <Grid item xs={12} lg={10}>
                <PayCODList />
              </Grid>
              <Grid item xs={12} lg={4} mt={5} mr={105}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button
                    ml={2}
                    color="primary"
                    variant="outlined"
                    color="primary"
                    startIcon={<PlayCircle />}
                  >
                    Request Demo
                  </Button>

                  <Button ml={4} color="primary" variant="contained">
                    Sign up FREE
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Content>
        </Grid>
      </Grid>

      <Grid container spacing={6} mt={10}>
        <Grid item xs={6} mt={10}>
          <Content>
            <Grid ml={37}>
              <Avatar>
                <Database fontSize="large" />
              </Avatar>
            </Grid>
            <Title ml={40} variant="h5" align="left" gutterBottom>
              Never lose a cent, ever!
            </Title>
            <Grid container justifyContent="center" spacing={4}>
              <Grid item xs={12} lg={10} ml={40}>
                <Subtitle color="textSecondary" align="left">
                  Collecting COD from delivery hubs and drivers seems like a
                  nightmare. We have built a process to ensure you are in
                  control and never lose a cent ever!.
                </Subtitle>
              </Grid>

              <Grid item xs={12} lg={10} ml={30}>
                <NeverLoseList />
              </Grid>
              <Grid item xs={12} lg={4} mt={5} mr={75}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "left",
                  }}
                >
                  <Button
                    ml={2}
                    color="primary"
                    variant="outlined"
                    color="primary"
                    startIcon={<PlayCircle />}
                  >
                    Request Demo
                  </Button>

                  <Button ml={4} color="primary" variant="contained">
                    Sign up FREE
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Content>
        </Grid>
        <Grid item xs={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: 400,
            }}
          >
            <img
              src={FeaturePic3}
              height="600px"
              style={{
                borderRadius: 8,
              }}
            />
          </div>
        </Grid>
      </Grid>

      <Container>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={4}
          mt={40}
        >
          <Grid item xs={12} sm={9} md={8} lg={8}>
            <Content>
              <Grid container justifyContent="center" mb={10}>
                <Avatar>
                  <Zap fontSize="large" />
                </Avatar>
              </Grid>

              <Title variant="h1" gutterBottom>
                Unleash the full power of battle tested features
              </Title>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item xs={12} lg={10}>
                  <Subtitle color="textSecondary">
                    Everything you need to start, grow and scale your delivery
                    operation.
                  </Subtitle>
                </Grid>

                <Grid item xs={12} lg={10} mt={10}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Grid item>
                      <Typography color="secondary" style={{ fontSize: 70 }}>
                        15m+
                      </Typography>
                      <Typography style={{ fontSize: 20 }}>
                        Deliveries
                      </Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item>
                      <Typography color="secondary" style={{ fontSize: 70 }}>
                        4k+
                      </Typography>
                      <Typography style={{ fontSize: 20 }}>
                        Service providers
                      </Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item>
                      <Typography color="secondary" style={{ fontSize: 70 }}>
                        30k+
                      </Typography>
                      <Typography style={{ fontSize: 20 }}>
                        Global shippers
                      </Typography>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Content>
          </Grid>
        </Grid>
      </Container>

      <Grid item xs={12} mt={5}>
        <Box
          ml={20}
          mr={20}
          mb={5}
          style={{
            backgroundColor: "#e3f2fd",
            borderRadius: 8,
            height: "180px",
          }}
        >
          <Grid container spacing={6} mt={10}>
            <Grid item xs={6} mt={5}>
              <Typography style={{ fontSize: 40 }}>
                Start your 30-day free trial
              </Typography>
              <Typography color="secondary" style={{ fontSize: 20 }}>
                Join over 4,000+ startups already growing with the index.
              </Typography>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={4} lg={2} mt={10}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  color="primary"
                  size="large"
                >
                  Learn more
                </Button>

                <Button color="primary" variant="contained" size="large">
                  Get started
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid container spacing={6} mt={10}>
        <Grid item xs={6} mt={10}>
          <Content>
            <Grid container justifyContent="center" spacing={4}>
              <Grid item xs={12} lg={10} ml={30}>
                <Title variant="h5" align="left" gutterBottom>
                  Join 4,000+ Businesses growing with the index.
                </Title>
              </Grid>

              <Grid item xs={12} lg={10} ml={25}>
                <BusinessGrowingList />
              </Grid>
              <Grid item xs={12} lg={3} mt={5} mr={100}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button
                    ml={2}
                    color="primary"
                    variant="outlined"
                    color="primary"
                  >
                    Learn more
                  </Button>

                  <Button ml={4} color="primary" variant="contained">
                    Get started
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Content>
        </Grid>
        <Grid item xs={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: 300,
            }}
          >
            <img
              src={FeaturePic4}
              height="600px"
              style={{
                borderRadius: 8,
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Features;
