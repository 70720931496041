import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import { Paper, Typography, Button } from "@mui/material";

import graphql from "babel-plugin-relay/macro";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
import BankInformationComponent from "../../components/auth/BankInformation";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function BankInformation() {
  return (
    <>
      {/* <Brand /> */}
      <Wrapper>
        <Helmet title="Sign Up" />

        <Typography component="h1" variant="h2" align="center" gutterBottom>
          Bank Account Information
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Please enter your bank information below. We will use this information
          to make any payments due to you.
        </Typography>
        <BankInformationComponent />
      </Wrapper>
    </>
  );
}

export default BankInformation;
