import * as React from "react";
import useAuth from "../../hooks/useAuth";
import { authUserType } from "../../utils/defaultStatus";
// For routes that can only be accessed by authenticated users
function ServiceProviderAuthGuard({ children }) {
  const { isAuthenticated, userType } = useAuth();

  if (isAuthenticated && userType === authUserType.EMPLOYEE) {
    return <>{children}</>;
  }

  return "";
}

export default ServiceProviderAuthGuard;
