/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NavbarNotificationsDropdownQueryVariables = {|
  owner?: ?string,
  search_key?: ?string,
  page?: ?number,
|};
export type NavbarNotificationsDropdownQueryResponse = {|
  +getAllNotifications: ?{|
    +pages: ?string,
    +notifications: ?$ReadOnlyArray<?{|
      +_id: ?string,
      +type: ?string,
      +title: ?string,
      +status: ?string,
      +content: ?string,
      +created_date: ?string,
      +updated_date: ?string,
    |}>,
  |}
|};
export type NavbarNotificationsDropdownQuery = {|
  variables: NavbarNotificationsDropdownQueryVariables,
  response: NavbarNotificationsDropdownQueryResponse,
|};
*/


/*
query NavbarNotificationsDropdownQuery(
  $owner: ID
  $search_key: String
  $page: Int
) {
  getAllNotifications(owner: $owner, search_key: $search_key, page: $page) {
    pages
    notifications {
      _id
      type
      title
      status
      content
      created_date
      updated_date
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "owner"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search_key"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "owner",
        "variableName": "owner"
      },
      {
        "kind": "Variable",
        "name": "page",
        "variableName": "page"
      },
      {
        "kind": "Variable",
        "name": "search_key",
        "variableName": "search_key"
      }
    ],
    "concreteType": "AllNotificationsTypeReturn",
    "kind": "LinkedField",
    "name": "getAllNotifications",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pages",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Notification",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created_date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updated_date",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NavbarNotificationsDropdownQuery",
    "selections": (v3/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "NavbarNotificationsDropdownQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "55696a6b5e77a9b0398ce09af2a74348",
    "id": null,
    "metadata": {},
    "name": "NavbarNotificationsDropdownQuery",
    "operationKind": "query",
    "text": "query NavbarNotificationsDropdownQuery(\n  $owner: ID\n  $search_key: String\n  $page: Int\n) {\n  getAllNotifications(owner: $owner, search_key: $search_key, page: $page) {\n    pages\n    notifications {\n      _id\n      type\n      title\n      status\n      content\n      created_date\n      updated_date\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd2ef34c734ed62feef035307a2ff637c';

module.exports = node;
