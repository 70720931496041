import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pickupSlots: [],
};

export const serviceProviderPickupSlotsSlice = createSlice({
  name: "serviceProviderPickupSlots",
  initialState,
  reducers: {
    setPickupSlots: (state, action) => {
      state.pickupSlots = action.payload;
    },
    updateNewPickupSlots: (state, action) => {
      const updatedPickupSlot = action.payload;
      const objIndex = state.pickupSlots.findIndex(
        (obj) => obj._id === updatedPickupSlot._id
      );
      state.pickupSlots[objIndex] = updatedPickupSlot;
    },
  },
});

export const { setPickupSlots, updateNewPickupSlots } =
  serviceProviderPickupSlotsSlice.actions;

export default serviceProviderPickupSlotsSlice.reducer;
