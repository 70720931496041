import {
  BookOpen,
  Calendar,
  CheckSquare,
  CreditCard,
  List,
  Map,
  PieChart,
  Sliders,
  RotateCcw,
  DollarSign,
  Briefcase,
  ShoppingCart,
} from "react-feather";

const getUserAndSPDisplayName = () => {
  const user = localStorage.getItem("user");
  const userJson = JSON.parse(user);
  if (userJson) {
    return {
      userDisplayName: userJson?.shipper_company_name,
      userSPDisplayName:
        userJson?.service_provider?.service_provider_company_name,
    };
  }
  return "";
};

const pagesSection = [
  {
    href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
      getUserAndSPDisplayName().userDisplayName
    }/dashboard`,
    icon: Sliders,
    title: "Home",
  },
  {
    href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
      getUserAndSPDisplayName().userDisplayName
    }/shipments`,
    icon: List,
    title: "Shipments",
    children: [
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/shipments/summary`,
        title: "Summary",
      },
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/shipments/add_shipments`,
        title: "Add Shipments",
      },
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/shipments/all_shipments`,
        title: "All Shipments",
      },
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/shipments/new_shipments`,
        title: "New Shipments",
      },
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/shipments/track_bulk`,
        title: "Track Bulk",
      },
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/shipments/reports`,
        title: "Reports",
      },
    ],
  },
  {
    href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
      getUserAndSPDisplayName().userDisplayName
    }/pickups`,
    icon: Briefcase,
    title: "Pickups",
    children: [
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/pickups/pickup_requests`,
        title: "Pickup Requests",
      },
    ],
  },
  {
    href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
      getUserAndSPDisplayName().userDisplayName
    }/returns`,
    icon: RotateCcw,
    title: "Returns",
    children: [
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/returns/summary`,
        title: "Summary",
      },
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/returns/pending_returns`,
        title: "Pending Returns",
      },
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/returns/all_returns`,
        title: "All Returns",
      },
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/returns/reports`,
        title: "Reports",
      },
    ],
  },

  {
    href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
      getUserAndSPDisplayName().userDisplayName
    }/orders`,
    icon: ShoppingCart,
    title: "Orders",
    children: [
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/orders/PendingOrders`,
        title: "Pending Orders",
      },
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/orders/generate_url`,
        title: "Generate Order URL",
      },
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/orders/UrlList`,
        title: "Order URL List",
      },
    ],
  },
  {
    href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
      getUserAndSPDisplayName().userDisplayName
    }/payments`,
    icon: CreditCard,
    title: "Payments",
    children: [
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/payments/summary`,
        title: "Summary",
      },
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/payments/pending_payments`,
        title: "Pending Payments",
      },
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/payments/completed_payments`,
        title: "Completed Payments",
      },
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/payments/reports`,
        title: "Reports",
      },
    ],
  },
  {
    href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
      getUserAndSPDisplayName().userDisplayName
    }/invoices`,
    icon: DollarSign,
    title: "Invoices",
    children: [
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/invoices/summary`,
        title: "Summary",
      },
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/invoices/pending_invoices`,
        title: "Pending Invoices",
      },
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/invoices/paid_invoices`,
        title: "Paid Invoices",
      },
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/invoices/service_fee_summary`,
        title: "Service Fee Summary",
      },
      {
        href: `/${getUserAndSPDisplayName().userSPDisplayName}/shipper/${
          getUserAndSPDisplayName().userDisplayName
        }/invoices/reports`,
        title: "Reports",
      },
    ],
  },
  // {
  //   href: "/dashboard",
  //   icon: Sliders,
  //   title: "Dashboard",
  //   children: [
  //     {
  //       href: "/dashboard/default",
  //       title: "Default",
  //     },
  //     {
  //       href: "/dashboard/analytics",
  //       title: "Analytics",
  //     },
  //     {
  //       href: "/dashboard/saas",
  //       title: "SaaS",
  //     },
  //   ],
  // },
  // {
  //   href: "/pages",
  //   icon: Layout,
  //   title: "Pages",
  //   children: [
  //     {
  //       href: "/pages/profile",
  //       title: "Profile",
  //     },
  //     {
  //       href: "/pages/settings",
  //       title: "Settings",
  //     },
  //     {
  //       href: "/pages/pricing",
  //       title: "Pricing",
  //     },
  //     {
  //       href: "/pages/chat",
  //       title: "Chat",
  //     },
  //     {
  //       href: "/pages/blank",
  //       title: "Blank Page",
  //     },
  //   ],
  // },
  // {
  //   href: "/projects",
  //   icon: Briefcase,
  //   title: "Projects",
  //   badge: "8",
  // },
  // {
  //   href: "/orders",
  //   icon: ShoppingCart,
  //   title: "Orders",
  // },
  // {
  //   href: "/invoices",
  //   icon: CreditCard,
  //   title: "Invoices",
  //   children: [
  //     {
  //       href: "/invoices",
  //       title: "List",
  //     },
  //     {
  //       href: "/invoices/detail",
  //       title: "Detail",
  //     },
  //   ],
  // },
  // {
  //   href: "/tasks",
  //   icon: CheckSquare,
  //   title: "Tasks",
  //   badge: "17",
  // },
  // {
  //   href: "/calendar",
  //   icon: Calendar,
  //   title: "Calendar",
  // },
  // {
  //   href: "/auth",
  //   icon: Users,
  //   title: "Auth",
  //   children: [
  //     {
  //       href: "/auth/sign-in",
  //       title: "Sign In",
  //     },
  //     {
  //       href: "/auth/sign-up",
  //       title: "Sign Up",
  //     },
  //     {
  //       href: "/auth/reset-password",
  //       title: "Reset Password",
  //     },
  //     {
  //       href: "/auth/404",
  //       title: "404 Page",
  //     },
  //     {
  //       href: "/auth/500",
  //       title: "500 Page",
  //     },
  //   ],
  // },
];

// const elementsSection = [
//   {
//     href: "/components",
//     icon: Grid,
//     title: "Components",
//     children: [
//       {
//         href: "/components/alerts",
//         title: "Alerts",
//       },
//       {
//         href: "/components/accordion",
//         title: "Accordion",
//       },
//       {
//         href: "/components/avatars",
//         title: "Avatars",
//       },
//       {
//         href: "/components/badges",
//         title: "Badges",
//       },
//       {
//         href: "/components/buttons",
//         title: "Buttons",
//       },
//       {
//         href: "/components/cards",
//         title: "Cards",
//       },
//       {
//         href: "/components/chips",
//         title: "Chips",
//       },
//       {
//         href: "/components/dialogs",
//         title: "Dialogs",
//       },
//       {
//         href: "/components/lists",
//         title: "Lists",
//       },
//       {
//         href: "/components/menus",
//         title: "Menus",
//       },
//       {
//         href: "/components/pagination",
//         title: "Pagination",
//       },
//       {
//         href: "/components/progress",
//         title: "Progress",
//       },
//       {
//         href: "/components/snackbars",
//         title: "Snackbars",
//       },
//       {
//         href: "/components/tooltips",
//         title: "Tooltips",
//       },
//     ],
//   },
//   {
//     href: "/charts",
//     icon: PieChart,
//     title: "Charts",
//   },
//   {
//     href: "/forms",
//     icon: CheckSquare,
//     title: "Forms",
//     children: [
//       {
//         href: "/forms/pickers",
//         title: "Pickers",
//       },
//       {
//         href: "/forms/selection-controls",
//         title: "Selection Controls",
//       },
//       {
//         href: "/forms/selects",
//         title: "Selects",
//       },
//       {
//         href: "/forms/text-fields",
//         title: "Text Fields",
//       },
//       {
//         href: "/forms/editors",
//         title: "Editors",
//       },
//       {
//         href: "/forms/formik",
//         title: "Formik",
//       },
//     ],
//   },
//   {
//     href: "/tables",
//     icon: List,
//     title: "Tables",
//     children: [
//       {
//         href: "/tables/simple-table",
//         title: "Simple Table",
//       },
//       {
//         href: "/tables/advanced-table",
//         title: "Advanced Table",
//       },
//       {
//         href: "/tables/data-grid",
//         title: "Data Grid",
//       },
//     ],
//   },
//   {
//     href: "/icons",
//     icon: Heart,
//     title: "Icons",
//     children: [
//       {
//         href: "/icons/material-icons",
//         title: "Material Icons",
//       },
//       {
//         href: "/icons/feather-icons",
//         title: "Feather Icons",
//       },
//     ],
//   },
//   {
//     href: "/maps",
//     icon: Map,
//     title: "Maps",
//     children: [
//       {
//         href: "/maps/google-maps",
//         title: "Google Maps",
//       },
//       {
//         href: "/maps/vector-maps",
//         title: "Vector Maps",
//       },
//     ],
//   },
// ];

// const docsSection = [
//   {
//     href: "/documentation/welcome",
//     icon: BookOpen,
//     title: "Documentation",
//   },
//   {
//     href: "/changelog",
//     icon: List,
//     title: "Changelog",
//     badge: "v3.1.0",
//   },
// ];

const navItems = [
  {
    title: "",
    pages: pagesSection,
  },
  // {
  //   title: "Elements",
  //   pages: elementsSection,
  // },
  // {
  //   title: "Material App",
  //   pages: docsSection,
  // },
];

export default navItems;
