import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dueTodayPaymentNotes: {},
  overDue3DaysPaymentNotes: {},
  overDueMoreThan3DaysPaymentNotes: {},
  paidPaymentNotes: {},
  paymentSummaryList: {},
  paymentSummary: {
    payment_notes: [],
  },
  paymentNoteType: {
    DUE_TODAY: false,
    OVER_DUE_THREE_DAYS: false,
    OVER_DUE_MORE_THAN_THREE_DAYS: false,
  },
};

export const serviceProviderPaymentNotesSlice = createSlice({
  name: "serviceProviderPaymentNotes",
  initialState,
  reducers: {
    setDueTodayPaymentNotes: (state, action) => {
      state.dueTodayPaymentNotes = action.payload;
    },
    setOverDue3DaysPaymentNotes: (state, action) => {
      state.overDue3DaysPaymentNotes = action.payload;
    },
    setOverDueMoreThan3DaysPaymentNotes: (state, action) => {
      state.overDueMoreThan3DaysPaymentNotes = action.payload;
    },
    setPaidPaymentNotes: (state, action) => {
      state.paidPaymentNotes = action.payload;
    },
    setPaymentSummaryList: (state, action) => {
      state.paymentSummaryList = action.payload;
    },
    setPaymentSummary: (state, action) => {
      state.paymentSummary = action.payload;
    },
    setPaymentNoteTypeDueToday: (state, action) => {
      state.paymentNoteType.DUE_TODAY = action.payload;
    },
    setPaymentNoteTypeDueThreeDays: (state, action) => {
      state.paymentNoteType.OVER_DUE_THREE_DAYS = action.payload;
    },
    setPaymentNoteTypeDueMoreThanThreeDays: (state, action) => {
      state.paymentNoteType.OVER_DUE_MORE_THAN_THREE_DAYS = action.payload;
    },
  },
});

export const {
  setDueTodayPaymentNotes,
  setOverDue3DaysPaymentNotes,
  setOverDueMoreThan3DaysPaymentNotes,
  setPaidPaymentNotes,
  setPaymentSummaryList,
  setPaymentSummary,
  setPaymentNoteTypeDueToday,
  setPaymentNoteTypeDueThreeDays,
  setPaymentNoteTypeDueMoreThanThreeDays,
} = serviceProviderPaymentNotesSlice.actions;

export default serviceProviderPaymentNotesSlice.reducer;
