import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { green, red } from "@mui/material/colors";

import graphql from "babel-plugin-relay/macro";
import Actions from "./Actions";
import ShipmentSummary from "./ShipmentSummary";
import Stats from "./Stats";
import PaymentSummary from "./PaymentSummary";
import BillingSummary from "./BillingSummary";
import Loader from "../../../components/Loader";

const Divider = styled(MuiDivider)(spacing);

const { fetchQuery, useRelayEnvironment } = require("react-relay");

const Typography = styled(MuiTypography)(spacing);

function Default() {
  const { t } = useTranslation();

  const environment = useRelayEnvironment();
  const [ShipperPerformanceOverview, setShipperPerformanceOverview] = useState(
    []
  );
  const [poisLoading, setPOIsLoading] = useState(false);
  const [ssisLoading, setSSIsLoading] = useState(false);

  const ShipperPerformanceOverviewSummary = async () => {
    const user = await localStorage.getItem("user");
    const userJson = JSON.parse(user);

    fetchQuery(
      environment,
      graphql`
        query IndexShipperHomeQuery($shipper_id: ID) {
          getShipperPerformanceOverview(shipper_id: $shipper_id) {
            pending_payments
            pending_shipments
            pending_bills
          }
        }
      `,
      { shipper_id: userJson._id }
    ).subscribe({
      start: () => {
        setPOIsLoading(true);
      },
      complete: (e) => {
        setPOIsLoading(false);
      },
      error: (errors) => {},
      next: (data) => {
        setShipperPerformanceOverview(data.getShipperPerformanceOverview);
      },
    });
  };
  useEffect(() => {
    ShipperPerformanceOverviewSummary();
  }, []);

  return (
    <>
      <Helmet title="Home" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            IndexCloud
          </Typography>
          <Typography variant="subtitle1">
            {t("Welcome back")}, {t("We've missed you")}.{" "}
            <span role="img" aria-label="Waving Hand Sign">
              👋
            </span>
          </Typography>
        </Grid>

        <Grid item>{/* <Actions /> */}</Grid>
      </Grid>

      <Divider my={6} />

      <Typography variant="h3" gutterBottom>
        Performance Overview
      </Typography>
      {poisLoading || ssisLoading ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl>
              <Stats
                title="Pending Shipments"
                amount={ShipperPerformanceOverview.pending_shipments}
                chip="Today"
                percentagetext="+26%"
                percentagecolor={green[500]}
                icon="shipments"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl>
              <Stats
                title="Pending Payments"
                amount={`${ShipperPerformanceOverview.pending_payments} LKR`}
                chip="Annual"
                percentagetext="-14%"
                percentagecolor={red[500]}
                icon="payments"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl>
              <Stats
                title="Pending Bills"
                amount={`${ShipperPerformanceOverview.pending_bills} LKR`}
                chip="Monthly"
                percentagetext="+18%"
                percentagecolor={green[500]}
                icon="bills"
              />
            </Grid>
          </Grid>

          <Grid container spacing={6}>
            <Grid item xs={12} lg={4}>
              <ShipmentSummary />
            </Grid>
            <Grid item xs={12} lg={4}>
              <PaymentSummary />
            </Grid>
            <Grid item xs={12} lg={4}>
              <BillingSummary />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default Default;
