import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pickUpRequestsList: [],
  pickUpRequest: {},
};

export const shipperPickupsSlice = createSlice({
  name: "shipperPickups",
  initialState,
  reducers: {
    setPickupRequestsList: (state, action) => {
      state.pickUpRequestsList = action.payload;
    },
    setPickupRequest: (state, action) => {
      state.pickUpRequest = action.payload;
    },
  },
});

export const { setPickupRequestsList, setPickupRequest } =
  shipperPickupsSlice.actions;

export default shipperPickupsSlice.reducer;
