/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ShipperLandingIndexQueryVariables = {|
  company_name?: ?string
|};
export type ShipperLandingIndexQueryResponse = {|
  +getShipperByCompanyName: ?{|
    +_id: ?string,
    +shipper_company_name: ?string,
    +profile_setting: ?{|
      +logo: ?string,
      +banner: ?string,
    |},
  |}
|};
export type ShipperLandingIndexQuery = {|
  variables: ShipperLandingIndexQueryVariables,
  response: ShipperLandingIndexQueryResponse,
|};
*/


/*
query ShipperLandingIndexQuery(
  $company_name: String
) {
  getShipperByCompanyName(company_name: $company_name) {
    _id
    shipper_company_name
    profile_setting {
      logo
      banner
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "company_name"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "company_name",
        "variableName": "company_name"
      }
    ],
    "concreteType": "Shipper",
    "kind": "LinkedField",
    "name": "getShipperByCompanyName",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shipper_company_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ShipperProfileSettings",
        "kind": "LinkedField",
        "name": "profile_setting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "banner",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShipperLandingIndexQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShipperLandingIndexQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "df947ff9925f2812041b312e43fa6593",
    "id": null,
    "metadata": {},
    "name": "ShipperLandingIndexQuery",
    "operationKind": "query",
    "text": "query ShipperLandingIndexQuery(\n  $company_name: String\n) {\n  getShipperByCompanyName(company_name: $company_name) {\n    _id\n    shipper_company_name\n    profile_setting {\n      logo\n      banner\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '18d2c9af0968884abb697faf73dcb5a2';

module.exports = node;
