import React from "react";

import AppBar from "./AppBar";
import Introduction from "./Introduction";
import Feartures from "./Features";
import BottomDescription from "./BottomDescription";
import Footer from "./Footer";

function Presentation() {
  return (
    <>
      <AppBar />
      <Introduction />
      <Feartures />
      <BottomDescription />
      <Footer />
    </>
  );
}

export default Presentation;
