import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { resetUserPassword } from "../../redux/slices/user";
import { commonConstants } from "../../utils/commonConstants";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const { SUCCESS, FAILED } = commonConstants;

function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useParams();
  const isLoading = useSelector((state) => state.user.isResetPasswordLoading);
  const resetPasswordStatus = useSelector(
    (state) => state.user.resetPasswordStatus
  );
  const resetPasswordResponse = useSelector(
    (state) => state.user.resetPasswordResponseMessage
  );

  return (
    <Formik
      initialValues={{
        newPassword: "",
        confirmPassword: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        newPassword: Yup.string()
          .min(8, "Must be at least 8 characters")
          .max(20)
          .required("Password is required")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
          ),
        confirmPassword: Yup.string()
          .when("newPassword", {
            is: (val) => !!(val && val.length > 0),
            then: Yup.string().oneOf(
              [Yup.ref("newPassword")],
              "Both password need to be the same"
            ),
          })
          .required("Required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          dispatch(
            resetUserPassword({
              userId,
              newPassword: values.newPassword,
            })
          );
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          {resetPasswordStatus === SUCCESS && (
            <Alert mt={2} mb={1} severity="success">
              {resetPasswordResponse}
            </Alert>
          )}
          {resetPasswordStatus === FAILED && (
            <Alert mt={2} mb={1} severity="error">
              {resetPasswordResponse}
            </Alert>
          )}
          <TextField
            style={{ marginTop: "10px" }}
            type="password"
            name="newPassword"
            label="New Password"
            value={values.newPassword}
            error={Boolean(touched.newPassword && errors.newPassword)}
            fullWidth
            helperText={touched.newPassword && errors.newPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            style={{ marginTop: "10px" }}
            type="password"
            name="confirmPassword"
            label="Confirm Password"
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          {resetPasswordStatus === SUCCESS ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => navigate("/auth/sign-in")}
            >
              Ok
            </Button>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              Reset password
            </Button>
          )}
        </form>
      )}
    </Formik>
  );
}

export default ResetPassword;
