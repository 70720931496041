/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ServiceProviderInputType = {|
  _id?: ?string,
  service_provider_id?: ?string,
  service_provider_full_name?: ?string,
  service_provider_company_name?: ?string,
  service_provider_email?: ?string,
  country_code?: ?string,
  service_provider_phone1?: ?string,
  service_provider_phone2?: ?string,
  service_provider_address_line1?: ?string,
  service_provider_address_line2?: ?string,
  service_provider_city?: ?ServiceProviderCityInput,
  service_provider_province?: ?ServiceProviderProvinceInput,
  service_provider_zip?: ?string,
  service_provider_district?: ?ServiceProviderDistrictInput,
  service_provider_address_location?: ?ServiceProviderAddrLocationInput,
  service_provider_status?: ?string,
  no_of_users?: ?number,
  hub?: ?$ReadOnlyArray<?ServiceProviderHubsInput>,
  payment_card_details?: ?$ReadOnlyArray<?ServiceProviderPaymentInput>,
  profile_setting?: ?ServiceProviderProfileSettingsInput,
|};
export type ServiceProviderCityInput = {|
  _id?: ?string,
  city_name?: ?string,
|};
export type ServiceProviderProvinceInput = {|
  _id?: ?string,
  province_name?: ?string,
  province_status?: ?boolean,
|};
export type ServiceProviderDistrictInput = {|
  _id?: ?string,
  district_name?: ?string,
  status?: ?boolean,
|};
export type ServiceProviderAddrLocationInput = {|
  type?: ?string,
  coordinates?: ?$ReadOnlyArray<?string>,
|};
export type ServiceProviderHubsInput = {|
  hub_name?: ?string,
  hub_code?: ?string,
  hub_status?: ?boolean,
  hub_address?: ?ServiceProviderHubCityInput,
|};
export type ServiceProviderHubCityInput = {|
  hub_address_city?: ?ServiceProviderHubAddrCityInput,
  hub_address_zip_code?: ?string,
  hub_address_location?: ?ServiceProviderHubAddrLocationInput,
|};
export type ServiceProviderHubAddrCityInput = {|
  _id?: ?string,
  city_name?: ?string,
|};
export type ServiceProviderHubAddrLocationInput = {|
  type?: ?string,
  coordinates?: ?$ReadOnlyArray<?string>,
|};
export type ServiceProviderPaymentInput = {|
  card_type?: ?string,
  card_holder_name?: ?string,
  card_number?: ?string,
  card_expiration_month?: ?number,
  card_expiration_year?: ?number,
  csv_code?: ?number,
|};
export type ServiceProviderProfileSettingsInput = {|
  logo?: ?string,
  banner?: ?string,
|};
export type EditLandingPageImageDialogServiceProviderMutationVariables = {|
  id?: ?string,
  service_provider: ServiceProviderInputType,
|};
export type EditLandingPageImageDialogServiceProviderMutationResponse = {|
  +updateServiceProvider: ?{|
    +_id: ?string
  |}
|};
export type EditLandingPageImageDialogServiceProviderMutation = {|
  variables: EditLandingPageImageDialogServiceProviderMutationVariables,
  response: EditLandingPageImageDialogServiceProviderMutationResponse,
|};
*/


/*
mutation EditLandingPageImageDialogServiceProviderMutation(
  $id: ID
  $service_provider: ServiceProviderInputType!
) {
  updateServiceProvider(id: $id, service_provider: $service_provider) {
    _id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "service_provider"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "service_provider",
        "variableName": "service_provider"
      }
    ],
    "concreteType": "EmployeeReturnType",
    "kind": "LinkedField",
    "name": "updateServiceProvider",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditLandingPageImageDialogServiceProviderMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditLandingPageImageDialogServiceProviderMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2a1448d915746cb068d547cfc05f3d5a",
    "id": null,
    "metadata": {},
    "name": "EditLandingPageImageDialogServiceProviderMutation",
    "operationKind": "mutation",
    "text": "mutation EditLandingPageImageDialogServiceProviderMutation(\n  $id: ID\n  $service_provider: ServiceProviderInputType!\n) {\n  updateServiceProvider(id: $id, service_provider: $service_provider) {\n    _id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '43ed414a65d6bc6348af2c205301bbd8';

module.exports = node;
