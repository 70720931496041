import { Home, Layout } from "react-feather";

const getSPDisplayName = () => {
  const user = localStorage.getItem("user");
  const userJson = JSON.parse(user);
  if (userJson) {
    return {
      userDisplayName: userJson.shipper_company_name,
      userSPDisplayName:
        userJson.service_provider.service_provider_company_name,
    };
  }
  return "";
};

const pagesSection = [
  {
    href: `/${getSPDisplayName().userSPDisplayName}/shipper/${
      getSPDisplayName().userDisplayName
    }/dashboard`,
    icon: Home,
    title: "Home",
  },
  {
    href: `/${getSPDisplayName().userSPDisplayName}/shipper/${
      getSPDisplayName().userDisplayName
    }/settings/landing_page_settings`,
    icon: Layout,
    title: "Landing Page",
  },
];

const navItems = [
  {
    title: "Settings",
    pages: pagesSection,
  },
];

export default navItems;
