import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cities: [],
};

export const serviceProviderCitiesSlice = createSlice({
  name: "serviceProviderCities",
  initialState,
  reducers: {
    setCities: (state, action) => {
      state.cities = action.payload;
    },
    updateNewCity: (state, action) => {
      const updatedCity = action.payload;
      const objIndex = state.cities.findIndex(
        (obj) => obj._id === updatedCity._id
      );
      state.cities[objIndex] = updatedCity;
    },
    updateNewCityStatus: (state, action) => {
      const { updatedCity, cityId } = action.payload;
      const objIndex = state.cities.findIndex((obj) => obj._id === cityId);
      state.cities[objIndex] = updatedCity;
    },
    updateNewCityList: (state, action) => {
      const updatedCityList = action.payload;
      updatedCityList.forEach((updatedCity) => {
        const objIndex = state.cities.findIndex(
          (obj) => obj._id === updatedCity._id
        );
        state.cities[objIndex] = updatedCity;
      });
    },
  },
});

export const {
  setCities,
  updateNewCity,
  updateNewCityStatus,
  updateNewCityList,
} = serviceProviderCitiesSlice.actions;

export default serviceProviderCitiesSlice.reducer;
