import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Button as MuiButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useParams } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { useReactToPrint } from "react-to-print";
import DetailsOne from "./DetailsOne";
import DetailsTwo from "./DetailsTwo";
import DetailsThree from "./DetailsThree";
import ShipTo from "./ShipTo";
import UpdateShipmentStatus from "./UpdateShipmentStatus";
import DriverDetails from "./DriverDetails";
import Progress from "./Progress";
import { authUserType } from "../../../utils/defaultStatus";
import ShipmentLabel from "../../printShipmentLabel/ShipmentLabelInProgressView";
import Loader from "../../../components/Loader";

const { fetchQuery, useRelayEnvironment } = require("react-relay");

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Button = styled(MuiButton)(spacing);

function ProgressView({ shipmentDetailsFromSearch, fromSearch }) {
  const { shipmentId } = useParams();
  const [shipmentDetails, setShipmentDetails] = useState({});
  const environment = useRelayEnvironment();
  const [userJson, setUserJson] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const componentRef = useRef();
  const printLabel = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint = () => {
    printLabel();
  };

  const fetchShipmentDetails = async () => {
    let shipperId = "";
    let serviceProviderId = "";
    const user = await localStorage.getItem("user");
    const userJ = JSON.parse(user);
    setUserJson(JSON.parse(user));
    switch (userJ.user_type) {
      case authUserType.SHIPPER:
        shipperId = userJ._id;
        break;
      case authUserType.SERVICE_PROVIDER:
        serviceProviderId = userJ._id;
        break;
      default:
        break;
    }

    fetchQuery(
      environment,
      graphql`
        query ProgressViewQuery(
          $traking_id: String!
          $service_provider_id: ID
          $shipper_id: ID
        ) {
          getShipmentByTRK(
            traking_id: $traking_id
            service_provider_id: $service_provider_id
            shipper_id: $shipper_id
          ) {
            _id
            traking_id
            shipment_created_time
            shipper {
              _id
              shipper_id
              shipper_full_name
              shipper_company_name
              shipper_email
              shipper_address_line1
              shipper_phone1
              pickup_locations {
                hub {
                  _id
                  hub_name
                  hub_code
                }
                city {
                  _id
                  city_name
                }
              }
            }
            weight
            qty
            shipment_status
            shipment_description
            declared_value
            prepaid
            cod_value
            handling_type
            recipient_name
            recipient_phone1
            recipient_phone2
            recipient_address_line1
            recipient_address_line2
            recipient_postal_code
            recipient_address_province {
              _id
              province_name
            }
            district {
              _id
              district_name
            }
            city {
              _id
              city_name
            }
            shipment_change_log {
              change_date
              change_field
              history_field
              event
              location
            }
            ownership {
              _id
              emp_full_name
              emp_mobile1
              emp_hub {
                hub_name
              }
            }
            service_provider {
              _id
              service_provider_full_name
              service_provider_company_name
            }
          }
        }
      `,
      {
        traking_id: shipmentId,
        service_provider_id: serviceProviderId,
        shipper_id: shipperId,
      }
    ).subscribe({
      start: () => {
        setIsLoading(true);
      },
      complete: () => {
        setIsLoading(false);
      },
      error: () => {},
      next: (data) => {
        if (data.getShipmentByTRK) {
          setShipmentDetails(data.getShipmentByTRK);
        }
      },
    });
  };

  useEffect(() => {
    if (fromSearch) {
      const user = localStorage.getItem("user");

      setUserJson(JSON.parse(user));
      setShipmentDetails(shipmentDetailsFromSearch);
    } else if (shipmentId) {
      fetchShipmentDetails();
    }
  }, [shipmentId]);
  return (
    <>
      <Helmet title="Shipment Progress" />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={4}>
              <Typography variant="h3" gutterBottom display="inline">
                Shipment Progress
              </Typography>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Typography variant="h5" gutterBottom display="inline">
                TRK#: {shipmentDetails.traking_id}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={4} />
          </Grid>

          <Divider my={6} />
          <Grid container spacing={6}>
            <Grid item xs={12} lg={4}>
              <DetailsOne shipmentDetails={shipmentDetails} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DetailsTwo shipmentDetails={shipmentDetails} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DetailsThree shipmentDetails={shipmentDetails} />
              {userJson && userJson.user_type === authUserType.SHIPPER && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => handlePrint()}
                  >
                    Shipment Label
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={4}>
              <ShipTo shipmentDetails={shipmentDetails} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DriverDetails shipmentDetails={shipmentDetails} />
            </Grid>
            <Grid item xs={12} lg={4}>
              {(userJson &&
                userJson.user_type === authUserType.SERVICE_PROVIDER) ||
                (userJson && userJson.user_type === authUserType.EMPLOYEE && (
                  <UpdateShipmentStatus
                    shipmentDetails={shipmentDetails}
                    setShipmentDetails={setShipmentDetails}
                    handlePrint={handlePrint}
                  />
                ))}
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={12}>
              <div>
                <Typography variant="h5" gutterBottom display="inline">
                  Shipment Progress
                </Typography>
              </div>
              <Progress shipmentDetails={shipmentDetails} />
            </Grid>
          </Grid>
          <div style={{ display: "none", marginLeft: "10px" }}>
            <ShipmentLabel ref={componentRef} shipment={[shipmentDetails]} />
          </div>
        </>
      )}
    </>
  );
}

export default ProgressView;
