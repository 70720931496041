import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import "./SignUp.css";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  LinearProgress as MuiLinearProgress,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import graphql from "babel-plugin-relay/macro";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-relay";
import BankSelector from "./components/BankSelector";
import useAuth from "../../hooks/useAuth";
import { authResponse } from "../../utils/response_messages/auth";
import { authConstants } from "../../utils/authConstants";
import { resetUser } from "../../redux/slices/user";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);

function BankInformation({ setComponent }) {
  const [logingErrors, setLoggingErrors] = useState([]);
  const navigate = useNavigate();
  const { signUp } = useAuth();
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [commit, isInFlight] = useMutation(graphql`
    mutation BankInformationMutation(
      $id: ID!
      $match_pickup_location: Boolean!
      $shipper: ShipperUpdateInput!
    ) {
      updateShipper(
        id: $id
        match_pickup_location: $match_pickup_location
        shipper: $shipper
      ) {
        _id
        shipper_email
        shipper_full_name
        shipper_id
        shipper_company_name
        shipper_type
        pickup_location {
          pickup_address_line1
          pickup_address_line2
          pickup_location_city {
            _id
            city_name
          }
          pickup_location_district {
            _id
            district_name
          }
          pickup_location {
            type
            coordinates
          }
          pickup_location_hub {
            _id
            hub_name
            hub_code
          }
          pickup_location_province {
            _id
            province_name
          }
          pickup_zone {
            _id
            zone_name
            zone_code
          }
        }
        shipper_city {
          _id
          city_name
        }
        service_provider {
          _id
          service_provider_full_name
          service_provider_company_name
        }
      }
    }
  `);
  return (
    <>
      <Typography component="h1" variant="h2" align="center" gutterBottom>
        Bank Account Information
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Please enter your bank information below. We will use this information
        to make any payments due to you.
      </Typography>
      <Formik
        initialValues={{
          accountName: "",
          bank: "",
          bankCode: "",
          branch: "",
          branchCode: "",
          city: "",
          bankAccountNumber: "",
          reBankAccountNumber: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          accountName: Yup.string()
            .max(255)
            .required("Account name is required"),
          bank: Yup.string().max(255).required("Bank name is required"),
          // bankCode: Yup.string().max(255),
          branch: Yup.string().max(255).required("Branch name is required"),
          // branchCode: Yup.string().max(255),
          bankAccountNumber: Yup.string()
            .max(255)
            .required("Bank account number is required"),
          reBankAccountNumber: Yup.string().when("bankAccountNumber", {
            is: (val) => !!(val && val.length > 0),
            then: Yup.string().oneOf(
              [Yup.ref("bankAccountNumber")],
              "Both account numbers need to be the same"
            ),
          }),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const { bank, branch, bankAccountNumber, accountName } = values;
            const bankArr = bank.split("_");
            const branchArr = branch.split("_");
            commit({
              variables: {
                id: user._id,
                match_pickup_location: false,
                shipper: {
                  bank: {
                    _id: bankArr[0],
                    bank_name: bankArr[1],
                    bank_code: bankArr[2],
                    branch_name: branchArr[0],
                    branch_code: branchArr[1],
                  },
                  account_number: bankAccountNumber,
                  bank_account_name: accountName,
                },
              },
              onCompleted(data, error) {
                setLoggingErrors(
                  error && error.length > 0
                    ? [{ error: authResponse.FAILURE }]
                    : []
                );
                if (data.updateShipper) {
                  const shipper = data.updateShipper;
                  const serviceProviderCompanyName =
                    shipper?.service_provider?.service_provider_company_name;
                  const shipperCompanyName = shipper?.shipper_company_name;
                  signUp(shipper);
                  dispatch(resetUser());
                  navigate(
                    `/${serviceProviderCompanyName}/shipper/${shipperCompanyName}/dashboard`
                  );
                  window.location.reload();
                }
              },
            });
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          resetForm,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            {logingErrors.length > 0 && (
              <Alert mb={4} variant="filled" severity="error">
                {authResponse.FAILURE}
              </Alert>
            )}
            <TextField
              type="text"
              name="accountName"
              label="Account name"
              value={values.accountName}
              error={Boolean(touched.accountName && errors.accountName)}
              fullWidth
              helperText={touched.accountName && errors.accountName}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <BankSelector
              values={values}
              touched={touched}
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <TextField
              type="text"
              name="bankAccountNumber"
              label="Bank Account Number"
              value={values.bankAccountNumber}
              error={Boolean(
                touched.bankAccountNumber && errors.bankAccountNumber
              )}
              fullWidth
              helperText={touched.bankAccountNumber && errors.bankAccountNumber}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="reBankAccountNumber"
              label="Re-enter Account Number"
              value={values.reBankAccountNumber}
              error={Boolean(
                touched.reBankAccountNumber && errors.reBankAccountNumber
              )}
              fullWidth
              helperText={
                touched.reBankAccountNumber && errors.reBankAccountNumber
              }
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <Button
              mr={2}
              fullWidth
              onClick={resetForm}
              style={{ marginBottom: "10px" }}
            >
              Clear Form
            </Button>
            <Button
              mr={2}
              fullWidth
              onClick={() => setComponent(authConstants.PICKUP)}
              style={{ marginBottom: "10px" }}
            >
              Back
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isInFlight}
            >
              Next
            </Button>
            <div className="progress-content">
              <LinearProgress
                variant="determinate"
                value="87.5"
                className="progress"
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default BankInformation;
