import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ShipmentLabelView from "./ShipmentLabelView";

export const ShipmentLabelInProgressView = React.forwardRef((props, ref) => {
  const [serviceProviderLogo, setServiceProviderLogo] = useState(null);
  const user = useSelector((state) => state.user.user);
  const { REACT_APP_AWS_S3_BUCKET, REACT_APP_SP_LANDING_PAGE_BUCKET_FOLDER } =
    process.env;

  const logoExist = (imageUrl) => {
    const http = new XMLHttpRequest();
    http.open("HEAD", imageUrl, false);
    http.send();

    if (http.status === 200) {
      setServiceProviderLogo(imageUrl);
    }
  };

  useEffect(() => {
    logoExist(
      `https://${REACT_APP_AWS_S3_BUCKET}.s3.amazonaws.com/${REACT_APP_SP_LANDING_PAGE_BUCKET_FOLDER}/logo-${
        user?.service_provider?._id
      }.png?${new Date()}`
    );
  }, []);

  return (
    <div style={{ display: "inline" }} ref={ref}>
      {props.shipment.map((shipment) => {
        if (parseInt(shipment.qty) === 1) {
          return (
            <>
              <ShipmentLabelView
                shipment={shipment}
                qty={shipment.qty}
                serviceProviderLogo={serviceProviderLogo}
              />
              <ShipmentLabelView
                shipment={shipment}
                qty={shipment.qty}
                serviceProviderLogo={serviceProviderLogo}
              />
            </>
          );
        }
        const shipmentQty = parseInt(shipment.qty);
        const itemList = [];

        for (let i = 1; i <= shipmentQty; i++) {
          itemList.push(
            <>
              <ShipmentLabelView
                shipment={shipment}
                qty={i}
                serviceProviderLogo={serviceProviderLogo}
              />
              <ShipmentLabelView
                shipment={shipment}
                qty={i}
                serviceProviderLogo={serviceProviderLogo}
              />
            </>
          );
        }
        return <div>{itemList}</div>;
      })}
    </div>
  );
});

export default ShipmentLabelInProgressView;
