import React from "react";
import styled from "styled-components/macro";
import { Grid, Divider as MuiDivider } from "@mui/material";
import { Box, spacing } from "@mui/system";
import TRKBarCode from "./ShipmentLabelTRKBarcode";
import ShipmentLabelSummary from "./ShipmentLabelSummary";
import ShipmentLabelHeader from "./ShipmentLabelHeader";
import ShipmentLabeFooter from "./ShipmentLabelFooter";
import HBarCode from "./HubBarcode";

const Divider = styled(MuiDivider)(spacing);

const marginTop = "15px";
const marginRight = "15px";
const marginBottom = "15px";
const marginLeft = "15px";

function ShipmentLabelView({ shipment, qty, serviceProviderLogo }) {
  const getPageMargins = () => {
    return `@page { size: A4;  margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; } @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
  
      @media print {
        .page-break {
          margin-top: 1rem;
          display: block;
          page-break-before: auto;
        }`;
  };

  return (
    <div style={{ height: "100vh", padding: "2vh" }}>
      <style>{getPageMargins()} </style>
      <Box border={1}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <ShipmentLabelHeader shipment={shipment} qty={qty} />
          </Grid>
        </Grid>

        <Divider my={6} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: "300px",
          }}
        >
          <div style={{ marginLeft: "30px" }}>
            <TRKBarCode shipment={shipment} />
            <h1 style={{ marginLeft: "80px" }}>
              <span style={{ fontWeight: "bold", fontSize: "28px" }}>
                TRK#:&nbsp;
              </span>
              <span style={{ fontWeight: "bold", fontSize: "28px" }}>
                {shipment.traking_id}
              </span>
            </h1>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <HBarCode shipment={shipment} />
          </div>
        </div>

        <Divider my={6} />
        <Grid container spacing={6}>
          <ShipmentLabelSummary shipment={shipment} qty={qty} />
        </Grid>

        <Divider my={6} />
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <ShipmentLabeFooter
              shipment={shipment}
              serviceProviderLogo={serviceProviderLogo}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default ShipmentLabelView;
