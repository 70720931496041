import React from "react";
import styled from "styled-components/macro";

import {
  Button,
  Container,
  Grid,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Facebook, Instagram, Linkedin, Twitter } from "react-feather";
import { Copyright as CopyrightIcon } from "@mui/icons-material";

const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);

const Wrapper = styled.div`
  ${spacing};
  text-align: left;
  position: relative;
  background: #212121;
  color: ${(props) => props.theme.palette.common.white};
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  opacity: 0.75;
`;

function Footer() {
  return (
    <Wrapper pt={5} pb={5}>
      {/* <Container> */}
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={6} md={6} lg={6} xl={6} pl={25}>
          <Typography variant="h6">
            <CopyrightIcon style={{ marginRight: 10, paddingTop: 5 }} />
            2022 IndexCloud. All rights reserved.
          </Typography>

          <Spacer mb={2} />
        </Grid>
        <Grid item xs={6} md={6} lg={6} xl={6} pl={150}>
          <Grid item>
            <Twitter />
            <Linkedin style={{ marginLeft: 25 }} />
            <Facebook style={{ marginLeft: 25 }} />
            <Instagram style={{ marginLeft: 25 }} />
          </Grid>

          <Spacer mb={2} />
        </Grid>
      </Grid>
      {/* </Container> */}
    </Wrapper>
  );
}

export default Footer;
