import { List, Home } from "react-feather";

const getUserDisplayName = () => {
  const user = localStorage.getItem("user");
  const userJson = JSON.parse(user);
  if (userJson) {
    return userJson?.service_provider?.service_provider_company_name;
  }
  return "";
};

const pagesSection = [
  {
    href: `/${getUserDisplayName()}/dashboard`,
    icon: Home,
    title: "Home",
  },
  // {
  //   href: `/${getUserDisplayName()}/profile`,
  //   icon: List,
  //   title: "Profile",
  // },
  {
    href: `/${getUserDisplayName()}/account_settings`,
    icon: List,
    title: "Account Settings",
  },
];

const navItems = [
  {
    title: "Profile",
    pages: pagesSection,
  },
];

export default navItems;
