import * as React from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Button as MuiButton,
  Container,
  Grid,
  Toolbar,
} from "@mui/material";
import { ChevronDown } from "react-feather";

const Button = styled(MuiButton)(spacing);

const Brand = styled.div`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
`;

function AppBarComponent() {
  const navigate = useNavigate();

  return (
    <AppBar position="relative" color="transparent" elevation={0}>
      <Toolbar>
        <Container>
          <Grid container alignItems="center">
            <Grid item>
              <Brand>IndexCloud</Brand>
            </Grid>
            <Grid item ml={20}>
              <Button ml={2} color="inherit" variant="text" size="large">
                Home
              </Button>

              <Button
                ml={2}
                color="inherit"
                variant="text"
                size="large"
                endIcon={<ChevronDown />}
              >
                Products
              </Button>

              <Button
                ml={2}
                color="inherit"
                variant="text"
                size="large"
                endIcon={<ChevronDown />}
              >
                Resources
              </Button>

              <Button ml={2} color="inherit" variant="text" size="large">
                Pricing
              </Button>
            </Grid>
            <Grid item xs />
            {/* <Grid item>
              <Button
                ml={2}
                color="primary"
                variant="contained"
                onClick={() => navigate("/admin/home")}
              >
                Admin Login
              </Button>
            </Grid> */}
            <Grid item>
              <Button
                ml={2}
                color="primary"
                variant="contained"
                onClick={() => navigate("/auth")}
              >
                Log in
              </Button>
            </Grid>
            <Grid item>
              <Button
                ml={2}
                color="primary"
                variant="contained"
                onClick={() => navigate("/auth/sign_up")}
              >
                Sign up FREE
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default AppBarComponent;
