export const authConstants = {
  SIGN_IN: "sign-in",
  SIGN_UP: "sign-up",
  EMAIL_OTP: "email-otp",
  MOBILE_NUMBER: "mobile-number",
  MOBILE_OTP: "mobile-otp",
  BILLING: "billing",
  PICKUP: "pickup",
  BANK: "bank",
  PAYMENT_METHODS: "payment-methods",
};
