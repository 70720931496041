/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ShipperUpdateInput = {|
  shipper_id?: ?string,
  shipper_type?: ?string,
  shipper_first_name?: ?string,
  shipper_last_name?: ?string,
  shipper_other_name?: ?string,
  shipper_full_name?: ?string,
  shipper_company_name?: ?string,
  shipper_display_name?: ?string,
  country_code?: ?string,
  shipper_phone1?: ?string,
  shipper_phone2?: ?string,
  billing_due_days?: ?number,
  payment_due_days?: ?number,
  shipper_email?: ?string,
  shipper_contacts?: ?$ReadOnlyArray<?ContactInput>,
  shipper_address_line1?: ?string,
  shipper_address_line2?: ?string,
  shipper_city?: ?CityInput,
  shipper_district?: ?DistrictInput,
  shipper_province?: ?ProvinceInput,
  shipper_address_location?: ?LocationInput,
  account_number?: ?string,
  pickup_location?: ?PickupLocationInput,
  bank?: ?BankInput,
  bank_account_name?: ?string,
  payment_cycle?: ?paymentCycleInput,
  billing_cycle?: ?billingCycleInput,
  payment_method?: ?string,
  shipper_price_card?: ?PriceCardInputType,
  service_provider?: ?string,
  shipper_status?: ?boolean,
  business_registration_doc?: ?string,
  other_doc?: ?string,
  created_date?: ?string,
  user_email_verify_status?: ?boolean,
  user_mobile_verify_status?: ?boolean,
  profile_setting?: ?ShipperProfileSettingsInput,
|};
export type ContactInput = {|
  _id: string,
  user_name: string,
  user_type: string,
  user_level: string,
  user_status: string,
|};
export type CityInput = {|
  _id: string,
  city_name: string,
|};
export type DistrictInput = {|
  _id: string,
  district_name: string,
  district_status?: ?boolean,
|};
export type ProvinceInput = {|
  _id: string,
  province_name: string,
  province_status?: ?boolean,
|};
export type LocationInput = {|
  type: string,
  coordinates: $ReadOnlyArray<?string>,
|};
export type PickupLocationInput = {|
  pickup_address_line1?: ?string,
  pickup_address_line2?: ?string,
  pickup_location_city?: ?CityInput,
  pickup_location_province?: ?ProvinceInput,
  pickup_location_district?: ?DistrictInput,
  pickup_location?: ?LocationInput,
  pickup_zone?: ?ZoneInput,
  pickup_location_hub?: ?PickupLocationHubInput,
|};
export type ZoneInput = {|
  _id?: ?string,
  zone_name?: ?string,
  zone_code?: ?string,
|};
export type PickupLocationHubInput = {|
  _id?: ?string,
  hub_name?: ?string,
  hub_code?: ?string,
|};
export type BankInput = {|
  _id: string,
  bank_name: string,
  bank_code: string,
  branch_code: string,
  branch_name: string,
|};
export type paymentCycleInput = {|
  _id?: ?string,
  payment_cycle_name?: ?string,
  payment_cycle_day?: ?string,
  payment_cycle_date?: ?number,
  payment_cycle_time?: ?string,
  payment_cycle_type?: ?string,
|};
export type billingCycleInput = {|
  _id?: ?string,
  billing_cycle_name?: ?string,
  billing_day?: ?string,
  billing_cycle_date?: ?number,
  billing_cycle_time?: ?string,
  billing_cycle_type?: ?string,
|};
export type PriceCardInputType = {|
  _id?: ?string,
  price_card_name?: ?string,
  price_card_version?: ?string,
  price_card_created_date?: ?string,
  price_card_created_by?: ?userPriceCardInputType,
  price_card_type?: ?string,
  price_card_service?: ?string,
|};
export type userPriceCardInputType = {|
  _id?: ?string,
  user_name?: ?string,
|};
export type ShipperProfileSettingsInput = {|
  logo?: ?string,
  banner?: ?string,
|};
export type BillingInformationMutationVariables = {|
  id: string,
  match_pickup_location: boolean,
  shipper: ShipperUpdateInput,
  service_provider_id?: ?string,
|};
export type BillingInformationMutationResponse = {|
  +updateShipper: ?{|
    +_id: ?string,
    +shipper_full_name: ?string,
  |}
|};
export type BillingInformationMutation = {|
  variables: BillingInformationMutationVariables,
  response: BillingInformationMutationResponse,
|};
*/


/*
mutation BillingInformationMutation(
  $id: ID!
  $match_pickup_location: Boolean!
  $shipper: ShipperUpdateInput!
  $service_provider_id: ID
) {
  updateShipper(id: $id, match_pickup_location: $match_pickup_location, shipper: $shipper, service_provider_id: $service_provider_id) {
    _id
    shipper_full_name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "match_pickup_location"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "service_provider_id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shipper"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "match_pickup_location",
        "variableName": "match_pickup_location"
      },
      {
        "kind": "Variable",
        "name": "service_provider_id",
        "variableName": "service_provider_id"
      },
      {
        "kind": "Variable",
        "name": "shipper",
        "variableName": "shipper"
      }
    ],
    "concreteType": "Shipper",
    "kind": "LinkedField",
    "name": "updateShipper",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shipper_full_name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BillingInformationMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "BillingInformationMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "6a261475c1e2d16e9f36342016feff16",
    "id": null,
    "metadata": {},
    "name": "BillingInformationMutation",
    "operationKind": "mutation",
    "text": "mutation BillingInformationMutation(\n  $id: ID!\n  $match_pickup_location: Boolean!\n  $shipper: ShipperUpdateInput!\n  $service_provider_id: ID\n) {\n  updateShipper(id: $id, match_pickup_location: $match_pickup_location, shipper: $shipper, service_provider_id: $service_provider_id) {\n    _id\n    shipper_full_name\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '78d3309c55b78dc41b3ae1f9cfcd20eb';

module.exports = node;
