import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { fetchQuery, useRelayEnvironment } from "react-relay";
import { useDispatch } from "react-redux";
import _ from "lodash";
import Introduction from "./RasutomairuLandingIntroduction";
import Loader from "../../../components/Loader";
import Page404 from "../../auth/Page404";
import { setServiceProvider } from "../../../redux/slices/user";

function RasutomairuLandingPage({ onEdit, setIsErrorUploading }) {
  const { serviceProvider } = useParams();
  const [currentUser, setCurrentUser] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const environment = useRelayEnvironment();
  const location = useLocation();
  const dispatch = useDispatch();

  const fetchUserDetails = async () => {
    fetchQuery(
      environment,
      graphql`
        query RasutomairuLandingPagewQuery(
          $service_provider_company_name: String
        ) {
          getServiceProviderByCompanyName(
            service_provider_company_name: $service_provider_company_name
          ) {
            _id
            service_provider_id
            service_provider_full_name
            service_provider_company_name
            profile_setting {
              logo
              banner
            }
          }
        }
      `,
      {
        service_provider_company_name: serviceProvider,
      }
    ).subscribe({
      start: () => {
        setIsLoading(true);
      },
      complete: () => {
        setIsLoading(false);
      },
      error: () => {},
      next: (data) => {
        if (data.getServiceProviderByCompanyName) {
          setCurrentUser(data.getServiceProviderByCompanyName);
          dispatch(setServiceProvider(data.getServiceProviderByCompanyName));
        }
      },
    });
  };

  useEffect(() => {
    fetchUserDetails();
  }, [location]);

  if (isLoading) return <Loader />;

  if (_.isEmpty(currentUser)) return <Page404 />;

  return (
    <Introduction
      currentUser={currentUser}
      onEdit={onEdit}
      fetchUserDetails={fetchUserDetails}
      setIsErrorUploading={setIsErrorUploading}
    />
  );
}

export default RasutomairuLandingPage;
