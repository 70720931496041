import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderUrls: [],
};

export const ServiceProviderOrderUrlsSlice = createSlice({
  name: "serviceProviderOrderUrls",
  initialState,
  reducers: {
    setOrderUrl: (state, action) => {
      state.orderUrls = action.payload;
    },

    updateOrderUrlList: (state, action) => {
      const updateOrderUrls = action.payload;
      const objIndex = state.orderUrls.findIndex(
        (obj) => obj._id === updateOrderUrls._id
      );
      state.orderUrls[objIndex] = updateOrderUrls;
    },
  },
});

export const { setOrderUrl, updateOrderUrlList } =
  ServiceProviderOrderUrlsSlice.actions;
export default ServiceProviderOrderUrlsSlice.reducer;
