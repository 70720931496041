import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pendingReturns: {},
  allReturns: {},
  returnReport: [],
};

export const shipperReturnsSlice = createSlice({
  name: "shipperReturns",
  initialState,
  reducers: {
    setPendingReturns: (state, action) => {
      state.pendingReturns = action.payload;
    },
    setAllReturns: (state, action) => {
      state.allReturns = action.payload;
    },
    setReturnReport: (state, action) => {
      state.returnReport = action.payload;
    },
    clearReturnReport: (state) => {
      state.returnReport = [];
    },
  },
});

export const {
  setPendingReturns,
  setAllReturns,
  setReturnReport,
  clearReturnReport,
} = shipperReturnsSlice.actions;

export default shipperReturnsSlice.reducer;
