import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pickUpRequestsList: [],
  agingPickupsFor24Hours: [],
  agingPickupsForMoreThan24Hours: [],
  scanAtHubPendingShipments: [],
  scanAtHubUpdatedShipments: [],
};

export const serviceProviderPickupsSlice = createSlice({
  name: "serviceProviderPickups",
  initialState,
  reducers: {
    setPickupRequestsList: (state, action) => {
      state.pickUpRequestsList = action.payload;
    },
    updatePickupRequest: (state, action) => {
      const updatedPickup = action.payload;
      const objIndex = state.pickUpRequestsList.findIndex(
        (obj) => obj._id === updatedPickup._id
      );
      state.pickUpRequestsList[objIndex].assigned_employee =
        updatedPickup.assigned_employee;
      state.pickUpRequestsList[objIndex].pickup_request_status =
        updatedPickup.pickup_request_status;
    },
    setAgingPickupList: (state, action) => {
      const listCategories = action.payload;
      state.agingPickupsFor24Hours = listCategories.for_24_hours;
      state.agingPickupsForMoreThan24Hours =
        listCategories.more_than_for_24_hours;
    },
    updateAging24Request: (state, action) => {
      const updatedPickup = action.payload;
      const objIndex = state.agingPickupsFor24Hours.findIndex(
        (obj) => obj._id === updatedPickup._id
      );
      state.agingPickupsFor24Hours[objIndex].assigned_employee =
        updatedPickup.assigned_employee;
      state.agingPickupsFor24Hours[objIndex].pickup_request_status =
        updatedPickup.pickup_request_status;
    },
    updateAgingMoreThan24Request: (state, action) => {
      const updatedPickup = action.payload;
      const objIndex = state.agingPickupsForMoreThan24Hours.findIndex(
        (obj) => obj._id === updatedPickup._id
      );
      state.agingPickupsForMoreThan24Hours[objIndex].assigned_employee =
        updatedPickup.assigned_employee;
      state.agingPickupsForMoreThan24Hours[objIndex].pickup_request_status =
        updatedPickup.pickup_request_status;
    },
    setScanAtHubUpdatedShipments: (state, action) => {
      state.scanAtHubUpdatedShipments = [
        ...state.scanAtHubUpdatedShipments,
        action.payload,
      ];
    },
    removeScanAtHubUpdatedShipments: (state) => {
      state.scanAtHubUpdatedShipments = [];
    },
    setScanAtHubPendingShipments: (state, action) => {
      state.scanAtHubPendingShipments = [
        ...state.scanAtHubPendingShipments,
        {
          traking_id: action.payload,
        },
      ];
    },
    updateScanAtHubUpdatedShipments: (state, action) => {
      const updatedShipment = action.payload;
      const objIndex = state.scanAtHubUpdatedShipments.findIndex(
        (obj) => obj._id === updatedShipment._id
      );
      state.scanAtHubUpdatedShipments[objIndex].weight = updatedShipment.weight;
    },
  },
});

export const {
  setPickupRequestsList,
  updatePickupRequest,
  setAgingPickupList,
  updateAging24Request,
  updateAgingMoreThan24Request,
  setScanAtHubUpdatedShipments,
  setScanAtHubPendingShipments,
  removeScanAtHubUpdatedShipments,
  updateScanAtHubUpdatedShipments,
} = serviceProviderPickupsSlice.actions;

export default serviceProviderPickupsSlice.reducer;
