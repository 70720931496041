/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BillingSummaryQueryVariables = {|
  shipper_id?: ?string
|};
export type BillingSummaryQueryResponse = {|
  +getShipperBillingSummary: ?{|
    +total_pending_payment: ?number,
    +billing_cycle: ?string,
    +date_range: ?string,
    +next_billing_date: ?string,
    +no_of_shipments: ?number,
  |}
|};
export type BillingSummaryQuery = {|
  variables: BillingSummaryQueryVariables,
  response: BillingSummaryQueryResponse,
|};
*/


/*
query BillingSummaryQuery(
  $shipper_id: String
) {
  getShipperBillingSummary(shipper_id: $shipper_id) {
    total_pending_payment
    billing_cycle
    date_range
    next_billing_date
    no_of_shipments
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shipper_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "shipper_id",
        "variableName": "shipper_id"
      }
    ],
    "concreteType": "BillingSummaryReturnType",
    "kind": "LinkedField",
    "name": "getShipperBillingSummary",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total_pending_payment",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "billing_cycle",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date_range",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "next_billing_date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "no_of_shipments",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BillingSummaryQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BillingSummaryQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dab343c32cb7e76b5033d8a1ae37b965",
    "id": null,
    "metadata": {},
    "name": "BillingSummaryQuery",
    "operationKind": "query",
    "text": "query BillingSummaryQuery(\n  $shipper_id: String\n) {\n  getShipperBillingSummary(shipper_id: $shipper_id) {\n    total_pending_payment\n    billing_cycle\n    date_range\n    next_billing_date\n    no_of_shipments\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c10738a758dcd1e1b95f8b1ccbb9db00';

module.exports = node;
