import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import "./SignUp.css";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  LinearProgress as MuiLinearProgress,
  Typography,
  Grid,
} from "@mui/material";
import { spacing } from "@mui/system";
import graphql from "babel-plugin-relay/macro";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-relay";
import useAuth from "../../hooks/useAuth";
import { resetUser } from "../../redux/slices/user";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);

function PaymentMethods() {
  const navigate = useNavigate();
  const { serviceProviderSignUp } = useAuth();
  const [apiErrors, setApiErrors] = useState("");
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [commit, isInFlight] = useMutation(graphql`
    mutation PaymentMethodsMutation(
      $id: ID!
      $service_provider: ServiceProviderInputType!
    ) {
      updateServiceProvider(id: $id, service_provider: $service_provider) {
        _id
        emp_full_name
        service_provider {
          _id
          service_provider_full_name
          service_provider_company_name
          service_provider_email
        }
        token
        user_id
      }
    }
  `);

  return (
    <>
      <Typography component="h1" variant="h2" align="center" gutterBottom>
        Payment Methods
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Please enter your payment information below.
      </Typography>
      <Formik
        initialValues={{
          nameOnCard: "",
          cardNumber: "",
          expireMonth: "",
          expireYear: "",
          csv: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          nameOnCard: Yup.string().max(255).required("Required"),
          cardNumber: Yup.number()
            .typeError("Please enter a valid card number")
            .test(
              "maxDigits",
              "Please enter a valid card number",
              (number) => String(number).length >= 12
            )
            .positive()
            .required("Required"),
          expireMonth: Yup.number()
            .typeError("Please enter a valid expiration month")
            .test(
              "maxDigits",
              "Please enter a valid expiration month",
              (number) =>
                String(number).length > 0 &&
                String(number).length <= 2 &&
                number > 0 &&
                number <= 12
            )
            .positive()
            .required("Required"),
          expireYear: Yup.number()
            .typeError("Please enter a valid expiration year")
            .test(
              "maxDigits",
              "Please enter a valid expiration year",
              (number) =>
                String(number).length > 0 && String(number).length <= 2
            )
            .positive()
            .required("Required"),
          csv: Yup.number()
            .typeError("Please enter a valid csv")
            .test(
              "maxDigits",
              "Please enter a valid csv",
              (number) => String(number).length === 3
            )
            .positive()
            .required("Required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            commit({
              variables: {
                id: user.service_provider._id,
                service_provider: {
                  payment_card_details: {
                    card_type: "Visa",
                    card_holder_name: values.nameOnCard,
                    card_number: values.cardNumber,
                    card_expiration_month: parseInt(values.expireMonth),
                    card_expiration_year: parseInt(values.expireYear),
                    csv_code: parseInt(values.csv),
                  },
                },
              },
              onCompleted(data) {
                if (data.updateServiceProvider) {
                  const updatedUser = data.updateServiceProvider;
                  serviceProviderSignUp(updatedUser);
                  dispatch(resetUser());
                  navigate(
                    `/${updatedUser.service_provider.service_provider_company_name}/dashboard`
                  );
                  window.location.reload();
                }
              },
            });
          } catch (error) {
            const message = error.message || "Something went wrong";
            setApiErrors(message);
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            {apiErrors !== "" && (
              <Alert mb={4} variant="filled" severity="error">
                {apiErrors}
              </Alert>
            )}
            <TextField
              type="text"
              name="nameOnCard"
              label="Name on card"
              value={values.nameOnCard}
              error={Boolean(touched.nameOnCard && errors.nameOnCard)}
              fullWidth
              helperText={touched.nameOnCard && errors.nameOnCard}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="cardNumber"
              label="Card Number"
              value={values.cardNumber}
              error={Boolean(touched.cardNumber && errors.cardNumber)}
              fullWidth
              helperText={touched.cardNumber && errors.cardNumber}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <Grid container spacing={6}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl>
                <TextField
                  type="text"
                  name="expireMonth"
                  label="Expire Month"
                  value={values.expireMonth}
                  error={Boolean(touched.expireMonth && errors.expireMonth)}
                  fullWidth
                  helperText={touched.expireMonth && errors.expireMonth}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl>
                <TextField
                  type="text"
                  name="expireYear"
                  label="Expire Year"
                  value={values.expireYear}
                  error={Boolean(touched.expireYear && errors.expireYear)}
                  fullWidth
                  helperText={touched.expireYear && errors.expireYear}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl>
                <TextField
                  type="text"
                  name="csv"
                  label="CSV"
                  value={values.csv}
                  error={Boolean(touched.csv && errors.csv)}
                  fullWidth
                  helperText={touched.csv && errors.csv}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isInFlight}
            >
              Start 30 day trial
            </Button>
            <div className="progress-content">
              <LinearProgress
                variant="determinate"
                value="84"
                className="progress"
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default PaymentMethods;
