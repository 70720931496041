import React, { useState } from "react";
import styled from "styled-components/macro";
import GoogleMapReact from "google-map-react";
import { FormHelperText } from "@mui/material";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import Place from "@mui/icons-material/Place";

const GoogleMapReactWrapper = styled.div`
  height: 300px;
  width: 100%;
`;

function Default({ values, setFieldValue, touched, errors }) {
  const [center, setCenter] = useState({
    lat: 7.8731,
    lng: 80.7718,
  });
  const [placeAutoCompletePlaceHolder, setPlaceAutoCompletePlaceHolder] =
    useState("Please select your location on map");

  const options = {
    center: {
      lat: values.addressLocationLat
        ? parseFloat(values.addressLocationLat)
        : 7.8731,
      lng: values.addressLocationLng
        ? parseFloat(values.addressLocationLng)
        : 80.7718,
    },
    zoom: values.addressLocationLat ? 15 : 7,
  };

  const getMapOptions = (Maps) => ({
    fullscreenControl: false,
    mapTypeControl: true,
    mapTypeId: Maps.MapTypeId.ROADMAP,
    scaleControl: true,
    scrollwheel: true,
    streetViewControl: false,
    draggableCursor: "crosshair",
  });

  const handleChange = async (value) => {
    const results = await geocodeByAddress(value.label);
    const ll = await getLatLng(results[0]);
    setCenter({
      lat: ll.lat,
      lng: ll.lng,
    });
    setFieldValue("addressLocationLat", ll.lat);
    setFieldValue("addressLocationLng", ll.lng);
    setFieldValue("addressvalue", value);
  };

  const onClickMapHandler = async ({ lat, lng }) => {
    setFieldValue("addressLocationLat", lat);
    setFieldValue("addressLocationLng", lng);
    setFieldValue("addressvalue", "");
    setPlaceAutoCompletePlaceHolder(`${lat},${lng}`);
  };

  const markerStyle = {
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -100%)",
  };

  return (
    <div style={{ marginBottom: "20px", marginTop: "10px" }}>
      <div style={{ marginBottom: "20px" }}>
        <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY_AUTO_COMPLETE}
          selectProps={{
            value: values.addressvalue ? values.addressvalue : "",
            onChange: handleChange,
            placeholder: placeAutoCompletePlaceHolder,
          }}
        />
        <FormHelperText style={{ color: "red", marginLeft: "10px" }}>
          {touched.addressLocationLat && errors.addressLocationLat
            ? "Location is required."
            : ""}
        </FormHelperText>
      </div>
      <GoogleMapReactWrapper>
        <GoogleMapReact
          options={getMapOptions}
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
            libraries: "places",
          }}
          defaultCenter={center}
          defaultZoom={7}
          center={options.center}
          zoom={options.zoom}
          yesIWantToUseGoogleMapApiInternals
          onClick={onClickMapHandler}
        >
          {values.addressLocationLat && (
            <div
              lat={values.addressLocationLat}
              lng={values.addressLocationLng}
            >
              <Place style={markerStyle} />
            </div>
          )}
        </GoogleMapReact>
      </GoogleMapReactWrapper>
    </div>
  );
}

export default Default;
