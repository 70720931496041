/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RasutomairuLandingPagewQueryVariables = {|
  service_provider_company_name?: ?string
|};
export type RasutomairuLandingPagewQueryResponse = {|
  +getServiceProviderByCompanyName: ?{|
    +_id: ?string,
    +service_provider_id: ?string,
    +service_provider_full_name: ?string,
    +service_provider_company_name: ?string,
    +profile_setting: ?{|
      +logo: ?string,
      +banner: ?string,
    |},
  |}
|};
export type RasutomairuLandingPagewQuery = {|
  variables: RasutomairuLandingPagewQueryVariables,
  response: RasutomairuLandingPagewQueryResponse,
|};
*/


/*
query RasutomairuLandingPagewQuery(
  $service_provider_company_name: String
) {
  getServiceProviderByCompanyName(service_provider_company_name: $service_provider_company_name) {
    _id
    service_provider_id
    service_provider_full_name
    service_provider_company_name
    profile_setting {
      logo
      banner
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "service_provider_company_name"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "service_provider_company_name",
        "variableName": "service_provider_company_name"
      }
    ],
    "concreteType": "ServiceProviderType",
    "kind": "LinkedField",
    "name": "getServiceProviderByCompanyName",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "service_provider_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "service_provider_full_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "service_provider_company_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceProviderProfileSettings",
        "kind": "LinkedField",
        "name": "profile_setting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "banner",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RasutomairuLandingPagewQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RasutomairuLandingPagewQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4d7195ada411deeca2b5fcf35ecdf2ed",
    "id": null,
    "metadata": {},
    "name": "RasutomairuLandingPagewQuery",
    "operationKind": "query",
    "text": "query RasutomairuLandingPagewQuery(\n  $service_provider_company_name: String\n) {\n  getServiceProviderByCompanyName(service_provider_company_name: $service_provider_company_name) {\n    _id\n    service_provider_id\n    service_provider_full_name\n    service_provider_company_name\n    profile_setting {\n      logo\n      banner\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8c981b8c8a4caa70bc3b4664dcc59a63';

module.exports = node;
