/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProvinceSelectorQueryVariables = {||};
export type ProvinceSelectorQueryResponse = {|
  +getProvinces: ?$ReadOnlyArray<?{|
    +_id: ?string,
    +province_name: ?string,
  |}>
|};
export type ProvinceSelectorQuery = {|
  variables: ProvinceSelectorQueryVariables,
  response: ProvinceSelectorQueryResponse,
|};
*/


/*
query ProvinceSelectorQuery {
  getProvinces {
    _id
    province_name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AllProvinceReturn",
    "kind": "LinkedField",
    "name": "getProvinces",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "province_name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProvinceSelectorQuery",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProvinceSelectorQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "724bcd2bfdac0f66eb20dfdaa7d28fcd",
    "id": null,
    "metadata": {},
    "name": "ProvinceSelectorQuery",
    "operationKind": "query",
    "text": "query ProvinceSelectorQuery {\n  getProvinces {\n    _id\n    province_name\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b5e75c0090f2c80ca9f37cc67c780dea';

module.exports = node;
