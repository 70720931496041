import React from "react";
import styled from "styled-components/macro";
import {
  Card as MuiCard,
  CardContent,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  CardHeader,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

function ShipTo({ shipmentDetails }) {
  return (
    <Card mb={6} style={{ marginTop: "10px" }}>
      <CardHeader title="SHIP TO" />
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                {shipmentDetails.recipient_name}
              </TableCell>
              <TableCell align="right">
                {shipmentDetails.recipient_address_province
                  ? shipmentDetails.recipient_address_province.province_name
                  : "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {`${shipmentDetails.recipient_phone1}${
                  shipmentDetails.recipient_phone2 &&
                  `/${shipmentDetails.recipient_phone2}`
                }`}
              </TableCell>
              <TableCell align="right">
                {shipmentDetails.district
                  ? shipmentDetails.district.district_name
                  : "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {shipmentDetails.recipient_address_line1}
              </TableCell>
              <TableCell align="right">
                {shipmentDetails.city ? shipmentDetails.city.city_name : "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {shipmentDetails.recipient_address_line2
                  ? shipmentDetails.recipient_address_line2
                  : "-"}
              </TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default ShipTo;
