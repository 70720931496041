import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordEmail } from "../../redux/slices/user";
import { commonConstants } from "../../utils/commonConstants";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const { SUCCESS, FAILED } = commonConstants;

function ResetPasswordEmail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state) => state.user.isResetPasswordEmailLoading
  );
  const resetPasswordStatus = useSelector(
    (state) => state.user.resetPasswordEmailStatus
  );
  const resetPasswordResponse = useSelector(
    (state) => state.user.resetPasswordEmailResponseMessage
  );

  return (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          dispatch(resetPasswordEmail({ email: values.email }));
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          {resetPasswordStatus === SUCCESS && (
            <Alert mt={2} mb={1} severity="success">
              {`${resetPasswordResponse}. Follow instructions of the email.`}
            </Alert>
          )}
          {resetPasswordStatus === FAILED && (
            <Alert mt={2} mb={1} severity="error">
              {resetPasswordResponse}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          {resetPasswordStatus === SUCCESS ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => navigate("/auth/sign-in")}
            >
              Ok
            </Button>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              Confirm
            </Button>
          )}
        </form>
      )}
    </Formik>
  );
}

export default ResetPasswordEmail;
