import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orders: [],
};

export const ServiceProviderOrdersSlice = createSlice({
  name: "serviceProviderOrders",
  initialState,
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },

    updateOrderList: (state, action) => {
      const updateOrder = action.payload;
      const objIndex = state.orders.findIndex(
        (obj) => obj._id === updateOrder._id
      );
      state.orders[objIndex] = updateOrder;
    },
  },
});

export const { setOrders, updateOrderList } =
  ServiceProviderOrdersSlice.actions;
export default ServiceProviderOrdersSlice.reducer;
