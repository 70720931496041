import React, { useEffect } from "react";
import styled from "styled-components/macro";
import {
  FormControl as MuiFormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { spacing } from "@mui/system";
import graphql from "babel-plugin-relay/macro";

const { usePreloadedQuery, useQueryLoader } = require("react-relay");

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const getAllProvinces = graphql`
  query ProvinceSelectorQuery {
    getProvinces {
      _id
      province_name
    }
  }
`;

function ProvinceSelector({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
}) {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader(getAllProvinces);

  useEffect(() => {
    loadQuery();
  }, []);
  return (
    <FormControl style={{ marginTop: "10px" }}>
      <InputLabel id="demo-simple-select-helper-label">Province</InputLabel>

      {queryReference != null ? (
        <SelectorDisplay
          values={values}
          touched={touched}
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          queryReference={queryReference}
        />
      ) : null}

      <FormHelperText style={{ color: "red" }}>
        {touched.province && errors.province ? "Province is required." : ""}
      </FormHelperText>
    </FormControl>
  );
}

function SelectorDisplay({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  queryReference,
}) {
  const data = usePreloadedQuery(getAllProvinces, queryReference);

  return (
    <Select
      labelId="demo-simple-select-helper-label"
      id="demo-simple-select-helper"
      name="province"
      value={values.province}
      error={Boolean(touched.province && errors.province)}
      helperText={touched.province && errors.province}
      onBlur={handleBlur}
      onChange={handleChange}
    >
      {data.getProvinces.map((province, key) => (
        <MenuItem key={key} value={`${province._id}_${province.province_name}`}>
          {province.province_name}
        </MenuItem>
      ))}
    </Select>
  );
}

export default ProvinceSelector;
