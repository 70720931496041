import {
  BookOpen,
  Briefcase,
  Calendar,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  ShoppingCart,
  PieChart,
  Sliders,
  Users,
  RotateCcw,
  DollarSign,
  Send,
  Home,
  Package,
  Layers,
} from "react-feather";

const getUserDisplayName = () => {
  const user = localStorage.getItem("user");
  const userJson = JSON.parse(user);
  if (userJson) {
    return userJson?.service_provider?.service_provider_company_name;
  }
  return "";
};

const pagesSection = [
  {
    href: `/${getUserDisplayName()}/dashboard`,
    icon: Home,
    title: "Home",
  },
  {
    href: `/${getUserDisplayName()}/pickups`,
    icon: Briefcase,
    title: "Pickups",
    children: [
      {
        href: `/${getUserDisplayName()}/pickups/summary`,
        title: "Summary",
      },
      {
        href: `/${getUserDisplayName()}/pickups/new_pickup_requests`,
        title: "New Pickup Requests",
      },
      {
        href: `/${getUserDisplayName()}/pickups/all_pickup_requests`,
        title: "All Pickup Requests",
      },
      {
        href: `/${getUserDisplayName()}/pickups/aging_requests`,
        title: "Aging Requests",
      },
      {
        href: `/${getUserDisplayName()}/pickups/scan_at_hub`,
        title: "Scan At Hub",
      },
      // {
      //   href: `/${getUserDisplayName()}/pickups/pickup_clearance`,
      //   title: "Pickup Clearance",
      // },
    ],
  },
  {
    href: `/${getUserDisplayName()}/dispatch`,
    icon: Send,
    title: "Dispatch",
    children: [
      {
        href: `/${getUserDisplayName()}/dispatch/summary`,
        title: "Summary",
      },
      {
        href: `/${getUserDisplayName()}/dispatch/dispatch`,
        title: "Dispatch",
      },
      {
        href: `/${getUserDisplayName()}/dispatch/history`,
        title: "Dispatch History",
      },
    ],
  },
  {
    href: `/${getUserDisplayName()}/shipments`,
    icon: Package,
    title: "Shipments",
    children: [
      {
        href: `/${getUserDisplayName()}/shipments/summary`,
        title: "Summary",
      },
      {
        href: `/${getUserDisplayName()}/shipments/all_shipments`,
        title: "All shipments",
      },
      {
        href: `/${getUserDisplayName()}/shipments/add_shipments`,
        title: "Add shipments",
      },
      {
        href: `/${getUserDisplayName()}/shipments/out_for_delivery`,
        title: "Out for delivery",
      },
      // {
      //   href: `/${getUserDisplayName()}/shipments/driver_clearance`,
      //   title: "Driver clearance",
      // },
      {
        href: `/${getUserDisplayName()}/shipments/run_sheets`,
        title: "Run sheets",
      },
      {
        href: `/${getUserDisplayName()}/shipments/reports`,
        title: "Report",
      },
    ],
  },
  {
    href: `/${getUserDisplayName()}/returns`,
    icon: RotateCcw,
    title: "Returns",
    children: [
      {
        href: `/${getUserDisplayName()}/returns/summary`,
        title: "Summary",
      },
      {
        href: `/${getUserDisplayName()}/returns/dispatch_return`,
        title: "Dispatch Return",
      },
      {
        href: `/${getUserDisplayName()}/returns/return_delivery`,
        title: "Return Delivery",
      },
      {
        href: `/${getUserDisplayName()}/returns/return_run_sheets`,
        title: "Return Run Sheets",
      },
      {
        href: `/${getUserDisplayName()}/returns/return_dispatch_history`,
        title: "Return Dispatch History",
      },
      {
        href: `/${getUserDisplayName()}/returns/reports`,
        title: "Report",
      },
    ],
  },
  {
    href: `/${getUserDisplayName()}/payments`,
    icon: CreditCard,
    title: "Payments",
    children: [
      {
        href: `/${getUserDisplayName()}/payments/summary`,
        title: "Summary",
      },
      {
        href: `/${getUserDisplayName()}/payments/shipper_payables`,
        title: "Payables",
      },
      {
        href: `/${getUserDisplayName()}/payments/payment_summaries`,
        title: "Payment Summaries",
      },
      {
        href: `/${getUserDisplayName()}/payments/payment_notes`,
        title: "Payment Notes",
      },
      {
        href: `/${getUserDisplayName()}/payments/all_payment_notes`,
        title: "All Payment Notes",
      },
    ],
  },
  {
    href: `/${getUserDisplayName()}/invoices`,
    icon: DollarSign,
    title: "Invoices",
    children: [
      {
        href: `/${getUserDisplayName()}/invoices/summary`,
        title: "Summary",
      },
      {
        href: `/${getUserDisplayName()}/invoices/receivables`,
        title: "Receivables",
      },
      {
        href: `/${getUserDisplayName()}/invoices/due_invoices`,
        title: "Due Invoices",
      },
      {
        href: `/${getUserDisplayName()}/invoices/all_invoices`,
        title: "All Invoices",
      },
      {
        href: `/${getUserDisplayName()}/invoices/service_fee_summary`,
        title: "Service Fee Summary",
      },
    ],
  },

  {
    href: `/${getUserDisplayName()}/collections`,
    icon: Layers,
    title: "Collections",
    children: [
      {
        href: `/${getUserDisplayName()}/collections/summary`,
        title: "Summary",
      },
      {
        href: `/${getUserDisplayName()}/collections/hub_collections`,
        title: "Hub Collections",
      },
      {
        href: `/${getUserDisplayName()}/collections/pending_collections`,
        title: "Pending Collections",
      },
      {
        href: `/${getUserDisplayName()}/collections/all_collections`,
        title: "All Collections",
      },
    ],
  },
  // {
  //   href: "/dashboard",
  //   icon: Sliders,
  //   title: "Dashboard",
  //   children: [
  //     {
  //       href: "/dashboard/default",
  //       title: "Default",
  //     },
  //     {
  //       href: "/dashboard/analytics",
  //       title: "Analytics",
  //     },
  //     {
  //       href: "/dashboard/saas",
  //       title: "SaaS",
  //     },
  //   ],
  // },
  // {
  //   href: "/pages",
  //   icon: Layout,
  //   title: "Pages",
  //   children: [
  //     {
  //       href: "/pages/profile",
  //       title: "Profile",
  //     },
  //     {
  //       href: "/pages/settings",
  //       title: "Settings",
  //     },
  //     {
  //       href: "/pages/pricing",
  //       title: "Pricing",
  //     },
  //     {
  //       href: "/pages/chat",
  //       title: "Chat",
  //     },
  //     {
  //       href: "/pages/blank",
  //       title: "Blank Page",
  //     },
  //   ],
  // },
  // {
  //   href: "/projects",
  //   icon: Briefcase,
  //   title: "Projects",
  //   badge: "8",
  // },
  // {
  //   href: "/orders",
  //   icon: ShoppingCart,
  //   title: "Orders",
  // },
  // {
  //   href: "/invoices",
  //   icon: CreditCard,
  //   title: "Invoices",
  //   children: [
  //     {
  //       href: "/invoices",
  //       title: "List",
  //     },
  //     {
  //       href: "/invoices/detail",
  //       title: "Detail",
  //     },
  //   ],
  // },
  // {
  //   href: "/tasks",
  //   icon: CheckSquare,
  //   title: "Tasks",
  //   badge: "17",
  // },
  // {
  //   href: "/calendar",
  //   icon: Calendar,
  //   title: "Calendar",
  // },
  // {
  //   href: "/auth",
  //   icon: Users,
  //   title: "Auth",
  //   children: [
  //     {
  //       href: "/auth/sign-in",
  //       title: "Sign In",
  //     },
  //     {
  //       href: "/auth/sign-up",
  //       title: "Sign Up",
  //     },
  //     {
  //       href: "/auth/reset-password",
  //       title: "Reset Password",
  //     },
  //     {
  //       href: "/auth/404",
  //       title: "404 Page",
  //     },
  //     {
  //       href: "/auth/500",
  //       title: "500 Page",
  //     },
  //   ],
  // },
];

const elementsSection = [
  {
    href: "/components",
    icon: Grid,
    title: "Components",
    children: [
      {
        href: "/components/alerts",
        title: "Alerts",
      },
      {
        href: "/components/accordion",
        title: "Accordion",
      },
      {
        href: "/components/avatars",
        title: "Avatars",
      },
      {
        href: "/components/badges",
        title: "Badges",
      },
      {
        href: "/components/buttons",
        title: "Buttons",
      },
      {
        href: "/components/cards",
        title: "Cards",
      },
      {
        href: "/components/chips",
        title: "Chips",
      },
      {
        href: "/components/dialogs",
        title: "Dialogs",
      },
      {
        href: "/components/lists",
        title: "Lists",
      },
      {
        href: "/components/menus",
        title: "Menus",
      },
      {
        href: "/components/pagination",
        title: "Pagination",
      },
      {
        href: "/components/progress",
        title: "Progress",
      },
      {
        href: "/components/snackbars",
        title: "Snackbars",
      },
      {
        href: "/components/tooltips",
        title: "Tooltips",
      },
    ],
  },
  {
    href: "/charts",
    icon: PieChart,
    title: "Charts",
  },
  {
    href: "/forms",
    icon: CheckSquare,
    title: "Forms",
    children: [
      {
        href: "/forms/pickers",
        title: "Pickers",
      },
      {
        href: "/forms/selection-controls",
        title: "Selection Controls",
      },
      {
        href: "/forms/selects",
        title: "Selects",
      },
      {
        href: "/forms/text-fields",
        title: "Text Fields",
      },
      {
        href: "/forms/editors",
        title: "Editors",
      },
      {
        href: "/forms/formik",
        title: "Formik",
      },
    ],
  },
  {
    href: "/tables",
    icon: List,
    title: "Tables",
    children: [
      {
        href: "/tables/simple-table",
        title: "Simple Table",
      },
      {
        href: "/tables/advanced-table",
        title: "Advanced Table",
      },
      {
        href: "/tables/data-grid",
        title: "Data Grid",
      },
    ],
  },
  {
    href: "/icons",
    icon: Heart,
    title: "Icons",
    children: [
      {
        href: "/icons/material-icons",
        title: "Material Icons",
      },
      {
        href: "/icons/feather-icons",
        title: "Feather Icons",
      },
    ],
  },
  {
    href: "/maps",
    icon: Map,
    title: "Maps",
    children: [
      {
        href: "/maps/google-maps",
        title: "Google Maps",
      },
      {
        href: "/maps/vector-maps",
        title: "Vector Maps",
      },
    ],
  },
];

const docsSection = [
  {
    href: "/documentation/welcome",
    icon: BookOpen,
    title: "Documentation",
  },
  {
    href: "/changelog",
    icon: List,
    title: "Changelog",
    badge: "v3.1.0",
  },
];

const navItems = [
  {
    title: "",
    pages: pagesSection,
  },
  // {
  //   title: "Elements",
  //   pages: elementsSection,
  // },
  // {
  //   title: "Material App",
  //   pages: docsSection,
  // },
];

export default navItems;
