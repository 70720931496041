import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  shippers: [],
};

export const shippersSlice = createSlice({
  name: "shippers",
  initialState,
  reducers: {
    setShippers: (state, action) => {
      state.shippers = action.payload;
    },
    updateShippers: (state, action) => {
      const updateShippers = action.payload;
      const objIndex = state.shippers.findIndex(
        (obj) => obj._id === updateShippers._id
      );
      state.shippers[objIndex] = updateShippers;
    },
  },
});

export const { setShippers, updateShippers } = shippersSlice.actions;

export default shippersSlice.reducer;
