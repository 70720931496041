import React from "react";
import { Grid, Box, Typography, Paper } from "@mui/material";
import styled from "styled-components/macro";
import HideImageIcon from "@mui/icons-material/HideImage";

const Wrapper = styled(Paper)`
  @media (max-width: 760px) {
    padding: 0 25% 0 0;
    justify-content: center;
  }
`;

function ImageNotAvailable() {
  return (
    <Wrapper>
      <Grid container spacing={2}>
        <Grid
          container
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
          justifyContent="center"
        >
          <Grid container spacing={2} sx={{ mt: 5 }}>
            <Grid item md={4} />
            <Grid
              item
              md={4}
              xs={12}
              align="center"
              justifyContent="center"
              sx={{ border: "dashed grey" }}
            >
              <HideImageIcon sx={{ fontSize: 60, mt: 20 }} />
              <Typography sx={{ mb: 20 }}>Images are not available</Typography>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
}
export default ImageNotAvailable;
