import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  FormControl as MuiFormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { spacing } from "@mui/system";
import graphql from "babel-plugin-relay/macro";
import { fetchQuery, useRelayEnvironment } from "react-relay";
import { useSelector } from "react-redux";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

function ProvincesContainCitiesSelector({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
}) {
  const environment = useRelayEnvironment();
  const user = useSelector((state) => state.user.user);
  const [provinceList, setProvinceList] = useState([]);

  const fetchProvincesContainCities = async () => {
    const serviceProviderId = user.service_provider._id;

    fetchQuery(
      environment,
      graphql`
        query ProvincesContainCitiesSelectorQuery($service_provider_id: ID) {
          getProvincesBySS(service_provider_id: $service_provider_id) {
            _id
            province_name
          }
        }
      `,
      { service_provider_id: serviceProviderId }
    ).subscribe({
      start: () => {},
      complete: () => {},
      error: () => {},
      next: (data) => {
        setProvinceList(data?.getProvincesBySS);
      },
    });
  };

  useEffect(() => {
    fetchProvincesContainCities();
  }, []);
  return (
    <FormControl style={{ marginTop: "10px" }}>
      <InputLabel id="demo-simple-select-helper-label">Province</InputLabel>

      <SelectorDisplay
        values={values}
        touched={touched}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        provinceList={provinceList}
      />

      <FormHelperText style={{ color: "red" }}>
        {touched.province && errors.province ? "Province is required." : ""}
      </FormHelperText>
    </FormControl>
  );
}

function SelectorDisplay({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  provinceList,
}) {
  return (
    <Select
      labelId="demo-simple-select-helper-label"
      id="demo-simple-select-helper"
      name="province"
      value={values.province}
      error={Boolean(touched.province && errors.province)}
      helperText={touched.province && errors.province}
      onBlur={handleBlur}
      onChange={handleChange}
    >
      {provinceList.map((province, key) => (
        <MenuItem key={key} value={`${province._id}_${province.province_name}`}>
          {province.province_name}
        </MenuItem>
      ))}
    </Select>
  );
}

export default ProvincesContainCitiesSelector;
