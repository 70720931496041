import { Users, Home } from "react-feather";

const pagesSection = [
  {
    href: `/admin/home`,
    icon: Home,
    title: "Home",
  },
  {
    href: `/admin/service_providers`,
    icon: Users,
    title: "Service Providers",
  },
];

const navItems = [
  {
    title: "Admin",
    pages: pagesSection,
  },
];

export default navItems;
