/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ShipmentSummaryQueryVariables = {|
  shipper_id?: ?string
|};
export type ShipmentSummaryQueryResponse = {|
  +getPendingShipmentSummary: ?{|
    +new_shipments: ?number,
    +ready_to_pickup: ?number,
    +picked_up: ?number,
    +at_origin_hub: ?number,
    +ready_to_dispatch: ?number,
    +in_transit: ?number,
    +at_transit_hub: ?number,
    +at_destination_hub: ?number,
    +out_for_delivery: ?number,
    +resheduled: ?number,
    +return_in_transit: ?number,
    +failed_to_deliver: ?number,
    +return_arrived_at_transit_hub: ?number,
    +return_arrived_at_origin_hub: ?number,
    +return_out_for_delivery: ?number,
    +return_delivery_resheduled: ?number,
    +return_delivery_failed: ?number,
  |}
|};
export type ShipmentSummaryQuery = {|
  variables: ShipmentSummaryQueryVariables,
  response: ShipmentSummaryQueryResponse,
|};
*/


/*
query ShipmentSummaryQuery(
  $shipper_id: ID
) {
  getPendingShipmentSummary(shipper_id: $shipper_id) {
    new_shipments
    ready_to_pickup
    picked_up
    at_origin_hub
    ready_to_dispatch
    in_transit
    at_transit_hub
    at_destination_hub
    out_for_delivery
    resheduled
    return_in_transit
    failed_to_deliver
    return_arrived_at_transit_hub
    return_arrived_at_origin_hub
    return_out_for_delivery
    return_delivery_resheduled
    return_delivery_failed
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shipper_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "shipper_id",
        "variableName": "shipper_id"
      }
    ],
    "concreteType": "PendingShipmentSummaryReturnType",
    "kind": "LinkedField",
    "name": "getPendingShipmentSummary",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "new_shipments",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ready_to_pickup",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "picked_up",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "at_origin_hub",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ready_to_dispatch",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "in_transit",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "at_transit_hub",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "at_destination_hub",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "out_for_delivery",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "resheduled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "return_in_transit",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "failed_to_deliver",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "return_arrived_at_transit_hub",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "return_arrived_at_origin_hub",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "return_out_for_delivery",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "return_delivery_resheduled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "return_delivery_failed",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShipmentSummaryQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShipmentSummaryQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "00afe458df8a780197fb7e17db402e03",
    "id": null,
    "metadata": {},
    "name": "ShipmentSummaryQuery",
    "operationKind": "query",
    "text": "query ShipmentSummaryQuery(\n  $shipper_id: ID\n) {\n  getPendingShipmentSummary(shipper_id: $shipper_id) {\n    new_shipments\n    ready_to_pickup\n    picked_up\n    at_origin_hub\n    ready_to_dispatch\n    in_transit\n    at_transit_hub\n    at_destination_hub\n    out_for_delivery\n    resheduled\n    return_in_transit\n    failed_to_deliver\n    return_arrived_at_transit_hub\n    return_arrived_at_origin_hub\n    return_out_for_delivery\n    return_delivery_resheduled\n    return_delivery_failed\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7830f5f17c38727b5a7714249c1a4d56';

module.exports = node;
