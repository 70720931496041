import { createContext, useEffect, useReducer } from "react";

// import axios from '../utils/axios';
import axios from "axios";
import { isValidToken, setSession } from "../utils/jwt";
import { authUserType } from "../utils/defaultStatus";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  userType: "",
};

function JWTReducer(state, action) {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        userType: action.payload.userType,
      };
    case "SIGN_IN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        userType: action.payload.userType,
      };
    case "SIGN_OUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case "SIGN_UP":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
}

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  // useEffect(() => {
  //   const initialize = async () => {
  //     try {
  //       const accessToken = window.localStorage.getItem('accessToken');

  //       if (accessToken && isValidToken(accessToken)) {
  //         setSession(accessToken);

  //         const response = await axios.get('/api/auth/my-account');
  //         const { user } = response.data;

  //         dispatch({
  //           type: INITIALIZE,
  //           payload: {
  //             isAuthenticated: true,
  //             user,
  //           },
  //         });
  //       } else {
  //         dispatch({
  //           type: INITIALIZE,
  //           payload: {
  //             isAuthenticated: false,
  //             user: null,
  //           },
  //         });
  //       }
  //     } catch (err) {
  //       console.error(err);
  //       dispatch({
  //         type: INITIALIZE,
  //         payload: {
  //           isAuthenticated: false,
  //           user: null,
  //         },
  //       });
  //     }
  //   };

  //   initialize();
  // }, []);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = await localStorage.getItem("accessToken");

        const user = await localStorage.getItem("user");
        const userJson = JSON.parse(user);
        if (accessToken) {
          setSession(accessToken);

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user: {},
              userType: userJson.user_type,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (response) => {
    const {
      token,
      user,
      user_type,
      user_id,
      user_activation_status,
      user_setup_status,
      service_provider_setup_status,
      is_config,
    } = response.data;

    setSession(token);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
        userType: user_type,
      },
    });

    let newUser = user;
    newUser = {
      ...newUser,
      user_type,
      user_id,
      user_activation_status,
      user_setup_status,
      service_provider_setup_status,
      is_config,
    };
    window.localStorage.setItem("user", JSON.stringify(newUser));
  };

  const signOut = async () => {
    setSession(null);
    window.localStorage.removeItem("user");
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (user) => {
    // const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", user.token);
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
    let newUser = user;
    newUser = {
      ...newUser,
      user_type: authUserType.SHIPPER,
    };
    window.localStorage.setItem("user", JSON.stringify(newUser));
  };

  const serviceProviderSignUp = async (user) => {
    // const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", user.token);
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
    let newUser = user;
    newUser = {
      ...newUser,
      user_type: authUserType.EMPLOYEE,
    };

    window.localStorage.setItem("user", JSON.stringify(newUser));
  };

  const signUpFirst = async (user) => {
    window.localStorage.setItem("accessToken", user.token);
  };

  const resetPassword = (email) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
        signUpFirst,
        serviceProviderSignUp,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
