import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderUrlImages: [],
  originalImages: [],
  imagesForUpload: [],
};

export const ServiceProviderOrderUrlImagesSlice = createSlice({
  name: "serviceProviderOrderUrlImages",
  initialState,
  reducers: {
    setOrderUrlImage: (state, action) => {
      state.orderUrlImages = [...state.orderUrlImages, action.payload];
    },
    setOriginalImages: (state, action) => {
      state.originalImages = [...state.originalImages, action.payload];
    },
    setImagesForUpload: (state, action) => {
      state.imagesForUpload = [...state.imagesForUpload, action.payload];
    },
    updateOrderUrlImage: (state, action) => {
      const updateImageUrls = action.payload;
      const objIndex = state.orderUrlImages.findIndex(
        (obj) => obj.id === updateImageUrls.id
      );
      state.orderUrlImages[objIndex] = updateImageUrls;
    },
    updateOrderUrlImageForUpload: (state, action) => {
      const updatedImage = action.payload;
      const objIndex = state.imagesForUpload.findIndex(
        (obj) => obj.id === updatedImage.id
      );
      state.imagesForUpload[objIndex] = updatedImage;
    },
    removeImage: (state, action) => {
      state.orderUrlImages = state.orderUrlImages.filter(
        (image) => image.id !== action.payload
      );
      state.originalImages = state.originalImages.filter(
        (image) => image.id !== action.payload
      );
      state.imagesForUpload = state.imagesForUpload.filter(
        (image) => image.id !== action.payload
      );
    },
    removeAllImages: (state, action) => {
      state.orderUrlImages = [];
      state.originalImages = [];
      state.imagesForUpload = [];
    },
  },
});

export const {
  setOrderUrlImage,
  updateOrderUrlImage,
  removeImage,
  setOriginalImages,
  setImagesForUpload,
  updateOrderUrlImageForUpload,
  removeAllImages,
} = ServiceProviderOrderUrlImagesSlice.actions;
export default ServiceProviderOrderUrlImagesSlice.reducer;
