import React from "react";
import styled from "styled-components/macro";
import {
  Card as MuiCard,
  CardContent,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

function DetailsTwo({ shipmentDetails }) {
  return (
    <Card mb={6} style={{ marginTop: "10px" }}>
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                DESCRIPTION
              </TableCell>
              <TableCell align="right">
                {shipmentDetails.shipment_description}
              </TableCell>
            </TableRow>
            {/* This is hide for temporarily
            <TableRow>
              <TableCell component="th" scope="row">
                DV(Declared Value)
              </TableCell>
              <TableCell align="right">
                {shipmentDetails.declared_value}
              </TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell component="th" scope="row">
                COD
              </TableCell>
              <TableCell align="right">
                {shipmentDetails.prepaid === true ? "No" : "Yes"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                AMOUNT
              </TableCell>
              <TableCell align="right">
                {shipmentDetails.cod_value === null ||
                shipmentDetails.cod_value === "" ||
                shipmentDetails.cod_value === 0
                  ? 0
                  : shipmentDetails.cod_value}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default DetailsTwo;
