import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  employees: [],
};

export const serviceProviderEmployeesSlice = createSlice({
  name: "serviceProviderEmployees",
  initialState,
  reducers: {
    setEmployees: (state, action) => {
      state.employees = action.payload;
    },
    updateNewEmployee: (state, action) => {
      const updatedEmployee = action.payload;
      const objIndex = state.employees.findIndex(
        (obj) => obj._id === updatedEmployee._id
      );
      state.employees[objIndex] = updatedEmployee;
    },
  },
});

export const { setEmployees, updateNewEmployee } =
  serviceProviderEmployeesSlice.actions;

export default serviceProviderEmployeesSlice.reducer;
