import React, { useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import "./SignUp.css";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  LinearProgress as MuiLinearProgress,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import graphql from "babel-plugin-relay/macro";
import { useSelector } from "react-redux";
import { useMutation } from "react-relay";
import _ from "lodash";
import ProvinceSelector from "./components/ProvincesContainCitiesSelector";
import DistrictSelector from "./components/DistrictsContainCitiesByProvinceSelector";
import CitySelector from "./components/CitySelectorByDistrictAndServiceProvider";
import DefaultMap from "../../pages/maps/GoogleMaps/Default";
import { authResponse } from "../../utils/response_messages/auth";
import { authConstants } from "../../utils/authConstants";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);

function PickUpInformation({
  setComponent,
  billingInformation,
  pickUp,
  pickupInformation,
  setPickupInformation,
}) {
  const [logingErrors, setLoggingErrors] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [commit, isInFlight] = useMutation(graphql`
    mutation PickUpInformationMutation(
      $id: ID!
      $match_pickup_location: Boolean!
      $shipper: ShipperUpdateInput!
      $service_provider_id: ID
    ) {
      updateShipper(
        id: $id
        match_pickup_location: $match_pickup_location
        shipper: $shipper
        service_provider_id: $service_provider_id
      ) {
        _id
        shipper_full_name
      }
    }
  `);
  return (
    <>
      <Typography component="h1" variant="h2" align="center" gutterBottom>
        Pickup Information
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Please enter your pickup information below.
      </Typography>
      <Formik
        initialValues={{
          addressLine1:
            (pickUp && billingInformation?.addressOne) ||
            pickupInformation?.addressOne ||
            "",
          addressLine2:
            (pickUp && billingInformation?.addressTwo) ||
            pickupInformation?.addressTwo ||
            "",
          province:
            (pickUp && billingInformation?.province) ||
            pickupInformation?.province ||
            "",
          district:
            (pickUp && billingInformation?.district) ||
            pickupInformation?.district ||
            "",
          city:
            (pickUp && billingInformation?.city) ||
            pickupInformation?.city ||
            "",
          addressLocationLat:
            (pickUp && billingInformation?.addressLocationLat) ||
            pickupInformation?.addressLocationLat ||
            "",
          addressLocationLng:
            (pickUp && billingInformation?.addressLocationLng) ||
            pickupInformation?.addressLocationLng ||
            "",
          addressvalue:
            (pickUp && billingInformation?.addressvalue) ||
            pickupInformation?.addressvalue ||
            "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          addressLine1: Yup.string()
            .max(255)
            .required("Address line 1 is required"),
          addressLine2: Yup.string().max(255),
          province: Yup.string().max(255).required("Province is required"),
          district: Yup.string().max(255).required("District is required"),
          city: Yup.string().max(255).required("City is required"),
          addressLocationLat: Yup.string().required("Required"),
          addressLocationLng: Yup.string().required("Required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if (pickUp) {
              setComponent(authConstants.BANK);
            } else {
              const {
                addressLine1,
                addressLine2,
                province,
                district,
                city,
                addressLocationLat,
                addressLocationLng,
                addressvalue,
              } = values;

              setPickupInformation({
                addressOne: addressLine1,
                addressTwo: addressLine2,
                province,
                district,
                city,
                addressLocationLat,
                addressLocationLng,
                addressvalue,
              });

              const provinceArr = province.split("_");
              const districtArr = district.split("_");
              const cityArr = city.split("_");
              commit({
                variables: {
                  id: user._id,
                  match_pickup_location: pickUp,
                  shipper: {
                    pickup_location: {
                      pickup_address_line1: addressLine1,
                      pickup_address_line2: addressLine2,
                      pickup_location_province: {
                        _id: provinceArr[0],
                        province_name: provinceArr[1],
                      },
                      pickup_location_city: {
                        _id: cityArr[0],
                        city_name: cityArr[1],
                      },
                      pickup_location_district: {
                        _id: districtArr[0],
                        district_name: districtArr[1],
                      },
                      pickup_location: {
                        type: "type1",
                        coordinates: [
                          addressLocationLat.toString(),
                          addressLocationLng.toString(),
                        ],
                      },
                    },
                  },
                  service_provider_id: user.service_provider._id,
                },
                onCompleted(data, error) {
                  setLoggingErrors(
                    error && error.length > 0
                      ? [{ error: authResponse.FAILURE }]
                      : []
                  );
                  if (data.updateShipper) {
                    setComponent(authConstants.BANK);
                  }
                },
              });
            }
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
          resetForm,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            {logingErrors.length > 0 && (
              <Alert mb={4} variant="filled" severity="error">
                {authResponse.FAILURE}
              </Alert>
            )}
            <TextField
              type="text"
              name="addressLine1"
              label="Address Line 1"
              value={values.addressLine1}
              error={Boolean(touched.addressLine1 && errors.addressLine1)}
              fullWidth
              helperText={touched.addressLine1 && errors.addressLine1}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
              style={{ marginBottom: 0 }}
            />
            <TextField
              type="text"
              name="addressLine2"
              label="Address Line 2[optional]"
              value={values.addressLine2}
              error={Boolean(touched.addressLine2 && errors.addressLine2)}
              fullWidth
              helperText={touched.addressLine2 && errors.addressLine2}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
              style={{ marginBottom: 0, marginTop: "10px" }}
            />
            <ProvinceSelector
              values={values}
              touched={touched}
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />

            <DistrictSelector
              values={values}
              touched={touched}
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CitySelector
              values={values}
              touched={touched}
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <DefaultMap
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
            />
            {!pickUp && _.isEmpty(pickupInformation) && (
              <Button
                mr={2}
                fullWidth
                onClick={resetForm}
                style={{ marginBottom: "10px" }}
              >
                Clear Form
              </Button>
            )}
            <Button
              mr={2}
              fullWidth
              onClick={() => setComponent(authConstants.BILLING)}
              style={{ marginBottom: "10px" }}
            >
              Back
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isInFlight}
            >
              Next
            </Button>
            <div className="progress-content">
              <LinearProgress
                variant="determinate"
                value="75"
                className="progress"
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default PickUpInformation;
