/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MobileOtpQueryVariables = {|
  user_name?: ?string,
  otp_value?: ?number,
  otp_type?: ?string,
  user_type?: ?string,
  mobile_number?: ?string,
|};
export type MobileOtpQueryResponse = {|
  +verifyUserAccount: ?{|
    +otp_value: ?number,
    +otp_type: ?string,
  |}
|};
export type MobileOtpQuery = {|
  variables: MobileOtpQueryVariables,
  response: MobileOtpQueryResponse,
|};
*/


/*
query MobileOtpQuery(
  $user_name: String
  $otp_value: Int
  $otp_type: String
  $user_type: String
  $mobile_number: String
) {
  verifyUserAccount(user_name: $user_name, otp_value: $otp_value, otp_type: $otp_type, user_type: $user_type, mobile_number: $mobile_number) {
    otp_value
    otp_type
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mobile_number"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "otp_type"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "otp_value"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "user_name"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "user_type"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "mobile_number",
        "variableName": "mobile_number"
      },
      {
        "kind": "Variable",
        "name": "otp_type",
        "variableName": "otp_type"
      },
      {
        "kind": "Variable",
        "name": "otp_value",
        "variableName": "otp_value"
      },
      {
        "kind": "Variable",
        "name": "user_name",
        "variableName": "user_name"
      },
      {
        "kind": "Variable",
        "name": "user_type",
        "variableName": "user_type"
      }
    ],
    "concreteType": "UserOTP",
    "kind": "LinkedField",
    "name": "verifyUserAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "otp_value",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "otp_type",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MobileOtpQuery",
    "selections": (v5/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MobileOtpQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "0cc9303ee7d8a7043aad9a2022de7b15",
    "id": null,
    "metadata": {},
    "name": "MobileOtpQuery",
    "operationKind": "query",
    "text": "query MobileOtpQuery(\n  $user_name: String\n  $otp_value: Int\n  $otp_type: String\n  $user_type: String\n  $mobile_number: String\n) {\n  verifyUserAccount(user_name: $user_name, otp_value: $otp_value, otp_type: $otp_type, user_type: $user_type, mobile_number: $mobile_number) {\n    otp_value\n    otp_type\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9601abd60df9d28599dc23a39bdc4311';

module.exports = node;
