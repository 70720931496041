import React from "react";
import styled from "styled-components/macro";
import { CircularProgress } from "@mui/material";

const Root = styled.div`
  align-items: center;
  display: flex;
`;

function Loader({ size = 30 }) {
  return (
    <Root>
      <CircularProgress color="secondary" size={size} />
    </Root>
  );
}

export default Loader;
