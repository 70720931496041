/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type HubsSelectorQueryVariables = {|
  service_provider_id?: ?string
|};
export type HubsSelectorQueryResponse = {|
  +getAllhubs: ?$ReadOnlyArray<?{|
    +_id: ?string,
    +hub_name: ?string,
    +hub_code: ?string,
  |}>
|};
export type HubsSelectorQuery = {|
  variables: HubsSelectorQueryVariables,
  response: HubsSelectorQueryResponse,
|};
*/


/*
query HubsSelectorQuery(
  $service_provider_id: ID
) {
  getAllhubs(service_provider_id: $service_provider_id) {
    _id
    hub_name
    hub_code
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "service_provider_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "service_provider_id",
        "variableName": "service_provider_id"
      }
    ],
    "concreteType": "AllHubReturn",
    "kind": "LinkedField",
    "name": "getAllhubs",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hub_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hub_code",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HubsSelectorQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HubsSelectorQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ec20f87e7ba5764ac5e3dd6f589ce1c3",
    "id": null,
    "metadata": {},
    "name": "HubsSelectorQuery",
    "operationKind": "query",
    "text": "query HubsSelectorQuery(\n  $service_provider_id: ID\n) {\n  getAllhubs(service_provider_id: $service_provider_id) {\n    _id\n    hub_name\n    hub_code\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6063e796fd6406450033a61b374088e3';

module.exports = node;
