import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import MobileOtpComponent from "../../components/auth/MobileOtp";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function MobileOtp() {
  return (
    <>
      {/* <Brand /> */}
      <Wrapper>
        <Helmet title="Sign Up" />

        <Typography component="h1" variant="h2" align="center" gutterBottom>
          Verify your mobile phone number
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          We have sent a SMS to your phone number with an OTP. Please enter the
          OTP provided in the SMS below to continue.
        </Typography>

        <MobileOtpComponent />
      </Wrapper>
    </>
  );
}

export default MobileOtp;
