import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  billingCycles: [],
};

export const serviceProviderBillingCyclesSlice = createSlice({
  name: "serviceProviderBillingCycles",
  initialState,
  reducers: {
    setBillingCycles: (state, action) => {
      state.billingCycles = action.payload;
    },
    updateNewBillingCycles: (state, action) => {
      const updatedBillingCycles = action.payload;
      const objIndex = state.billingCycles.findIndex(
        (obj) => obj._id === updatedBillingCycles._id
      );
      state.billingCycles[objIndex] = updatedBillingCycles;
    },
  },
});

export const { setBillingCycles, updateNewBillingCycles } =
  serviceProviderBillingCyclesSlice.actions;

export default serviceProviderBillingCyclesSlice.reducer;
