/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type IndexShipperHomeQueryVariables = {|
  shipper_id?: ?string
|};
export type IndexShipperHomeQueryResponse = {|
  +getShipperPerformanceOverview: ?{|
    +pending_payments: ?number,
    +pending_shipments: ?number,
    +pending_bills: ?number,
  |}
|};
export type IndexShipperHomeQuery = {|
  variables: IndexShipperHomeQueryVariables,
  response: IndexShipperHomeQueryResponse,
|};
*/


/*
query IndexShipperHomeQuery(
  $shipper_id: ID
) {
  getShipperPerformanceOverview(shipper_id: $shipper_id) {
    pending_payments
    pending_shipments
    pending_bills
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shipper_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "shipper_id",
        "variableName": "shipper_id"
      }
    ],
    "concreteType": "ShipperPerformanceOverviewReturnType",
    "kind": "LinkedField",
    "name": "getShipperPerformanceOverview",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pending_payments",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pending_shipments",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pending_bills",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "IndexShipperHomeQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "IndexShipperHomeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "23600faa2eab2791ff21c843585d63a8",
    "id": null,
    "metadata": {},
    "name": "IndexShipperHomeQuery",
    "operationKind": "query",
    "text": "query IndexShipperHomeQuery(\n  $shipper_id: ID\n) {\n  getShipperPerformanceOverview(shipper_id: $shipper_id) {\n    pending_payments\n    pending_shipments\n    pending_bills\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8a15c6cee14178be84d33cd0f04764f7';

module.exports = node;
