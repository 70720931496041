/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SignInMutationVariables = {|
  user_name?: ?string,
  otp_type?: ?string,
|};
export type SignInMutationResponse = {|
  +resendOtp: ?{|
    +otp_value: ?number,
    +otp_type: ?string,
    +user: ?{|
      +user_name: ?string
    |},
  |}
|};
export type SignInMutation = {|
  variables: SignInMutationVariables,
  response: SignInMutationResponse,
|};
*/


/*
mutation SignInMutation(
  $user_name: String
  $otp_type: String
) {
  resendOtp(user_name: $user_name, otp_type: $otp_type) {
    otp_value
    otp_type
    user {
      user_name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "otp_type"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "user_name"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "otp_type",
        "variableName": "otp_type"
      },
      {
        "kind": "Variable",
        "name": "user_name",
        "variableName": "user_name"
      }
    ],
    "concreteType": "UserOTP",
    "kind": "LinkedField",
    "name": "resendOtp",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "otp_value",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "otp_type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OtpUserReturn",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "user_name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SignInMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SignInMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e0639a8d4442b2031f239b3a94961392",
    "id": null,
    "metadata": {},
    "name": "SignInMutation",
    "operationKind": "mutation",
    "text": "mutation SignInMutation(\n  $user_name: String\n  $otp_type: String\n) {\n  resendOtp(user_name: $user_name, otp_type: $otp_type) {\n    otp_value\n    otp_type\n    user {\n      user_name\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dde6f0305ca20afd444e1e60559b5162';

module.exports = node;
