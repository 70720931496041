import React, { useState } from "react";
import SignIn from "./SignIn";
import SignUp from "./ServiceProviderSignUp";
import { authConstants } from "../../utils/authConstants";

function ServiceProviderAuth({ component }) {
  const [authComponent, setAuthComponent] = useState(
    component || authConstants.SIGN_IN
  );
  return (
    <>
      {authComponent === authConstants.SIGN_IN && (
        <SignIn setAuthComponent={setAuthComponent} />
      )}
      {authComponent === authConstants.SIGN_UP && (
        <SignUp setAuthComponent={setAuthComponent} />
      )}
    </>
  );
}

export default ServiceProviderAuth;
