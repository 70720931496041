import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  trackBulkShipments: [],
  confirmedShipmentsToRequest: [],
  shipmentReport: [],
};

export const shipperShipmentsSlice = createSlice({
  name: "shipperShipments",
  initialState,
  reducers: {
    setTrackBulkShipemnts: (state, action) => {
      state.trackBulkShipments = action.payload;
    },
    clearTrackBulkShipemnts: (state) => {
      state.trackBulkShipments = [];
    },
    setConfirmedShipmentsToRequest: (state, action) => {
      state.confirmedShipmentsToRequest = action.payload;
    },
    setShipmentReport: (state, action) => {
      state.shipmentReport = action.payload;
    },
    clearShipmentReport: (state) => {
      state.shipmentReport = [];
    },
  },
});

export const {
  setTrackBulkShipemnts,
  clearTrackBulkShipemnts,
  setConfirmedShipmentsToRequest,
  setShipmentReport,
  clearShipmentReport,
} = shipperShipmentsSlice.actions;

export default shipperShipmentsSlice.reducer;
