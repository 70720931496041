import {
  Grid,
  Users,
  Home,
  Truck,
  File,
  Cpu,
  Sidebar,
  Layout,
} from "react-feather";

const getUserDisplayName = () => {
  const user = localStorage.getItem("user");
  const userJson = JSON.parse(user);
  if (userJson) {
    return userJson?.service_provider?.service_provider_company_name;
  }
  return "";
};

const pagesSection = [
  {
    href: `/${getUserDisplayName()}/dashboard`,
    icon: Home,
    title: "Home",
  },
  {
    href: `/${getUserDisplayName()}/settings/operations`,
    icon: Cpu,
    title: "Operations",
  },
  {
    href: `/${getUserDisplayName()}/settings/shippers`,
    icon: Truck,
    title: "Shippers",
  },
  {
    href: `/${getUserDisplayName()}/settings/hubs`,
    icon: Grid,
    title: "Hubs",
  },
  {
    href: `/${getUserDisplayName()}/settings/employees`,
    icon: Users,
    title: "Employees",
  },
  {
    href: `/${getUserDisplayName()}/settings/price_cards`,
    icon: Sidebar,
    title: "Price Cards",
  },
  {
    href: `/${getUserDisplayName()}/settings/landing_page_settings`,
    icon: Layout,
    title: "Landing Page",
  },
  // {
  //   href: `/${getUserDisplayName()}/settings/index_credit`,
  //   icon: File,
  //   title: "Index Credit",
  // },
];

const navItems = [
  {
    title: "Settings",
    pages: pagesSection,
  },
];

export default navItems;
