import React from "react";
import styled from "styled-components/macro";
import {
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from "@mui/material";
import momentTimezone from "moment-timezone";
import { shipmentHandlingType } from "../../utils/defaultStatus";

const TableRow = styled(MuiTableRow)`
  height: 20px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const ShipmentLabelSummary = ({ shipment, qty }) => {
  const currentTimeZone = momentTimezone();
  const currentTime = currentTimeZone.tz("Asia/Colombo").format("DD MMM YYYY");
  return (
    <div style={{ margin: "20px 40px 10px 40px" }}>
      <Table sx={{ border: 0 }}>
        <TableBody>
          <TableRow sx={{ borderBottom: 0 }}>
            <TableCell
              sx={{ borderBottom: 0, fontSize: "15px", textAlign: "left" }}
              component="th"
              scope="row"
              width="500"
            >
              PRINT DATE
            </TableCell>
            <TableCell
              sx={{ borderBottom: 0, fontSize: "15px", textAlign: "left" }}
              align="right"
            >
              :&nbsp;{currentTime}
            </TableCell>
            <TableCell sx={{ borderBottom: 0 }}> </TableCell>

            <TableCell
              sx={{ borderBottom: 0, fontSize: "15px", textAlign: "left" }}
              component="th"
              scope="row"
            >
              QTY
            </TableCell>
            <TableCell
              sx={{ borderBottom: 0, fontSize: "15px", textAlign: "left" }}
              align="right"
            >
              :&nbsp;{qty} of {shipment.qty}
            </TableCell>
          </TableRow>
          <TableRow sx={{ borderBottom: 0 }}>
            <TableCell
              sx={{ borderBottom: 0, fontSize: "15px", textAlign: "left" }}
              component="th"
              scope="row"
              width="100"
            >
              TRK #
            </TableCell>
            <TableCell
              sx={{ borderBottom: 0, fontSize: "15px", textAlign: "left" }}
              align="left"
              width="700"
            >
              :&nbsp;{shipment.traking_id}
            </TableCell>

            <TableCell sx={{ borderBottom: 0 }}> </TableCell>

            <TableCell
              sx={{ borderBottom: 0, fontSize: "15px", textAlign: "left" }}
              component="th"
              scope="row"
            >
              HANDLING
            </TableCell>
            <TableCell
              sx={{ borderBottom: 0, fontSize: "15px", textAlign: "left" }}
              align="right"
            >
              :&nbsp;
              {shipment.handling_type === shipmentHandlingType.FRAGILE
                ? "Fragile"
                : "Standard"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{ borderBottom: 0, fontSize: "15px", textAlign: "left" }}
              component="th"
              scope="row"
            >
              BILL TO
            </TableCell>
            <TableCell
              sx={{ borderBottom: 0, fontSize: "15px", textAlign: "left" }}
              align="right"
            >
              :&nbsp;
              {shipment.shipper && shipment.shipper.shipper_company_name}
            </TableCell>
          </TableRow>

          <TableRow />

          {/* This is hide for temporarily
          <TableRow>
            <TableCell
              sx={{ borderBottom: 0, fontSize: "15px", textAlign: "left" }}
              component="th"
              scope="row"
            >
              DV
            </TableCell>
            <TableCell
              sx={{ borderBottom: 0, fontSize: "15px", textAlign: "left" }}
              align="right"
            >
              :&nbsp;{shipment.declared_value}
            </TableCell>
          </TableRow> */}

          <TableRow>
            <TableCell
              sx={{ borderBottom: 0, fontSize: "15px", textAlign: "left" }}
              component="th"
              scope="row"
            >
              COD
            </TableCell>
            <TableCell
              sx={{ borderBottom: 0, fontSize: "15px", textAlign: "left" }}
              align="right"
            >
              :&nbsp;{shipment.prepaid ? "No" : "Yes"}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell
              sx={{ borderBottom: 0, fontSize: "15px", textAlign: "left" }}
              component="th"
              scope="row"
            >
              AMOUNT
            </TableCell>
            <TableCell
              sx={{ borderBottom: 0, fontSize: "15px", textAlign: "left" }}
              align="right"
            >
              :&nbsp;{shipment.cod_value ? shipment.cod_value : 0}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default ShipmentLabelSummary;
