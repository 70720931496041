import React from "react";
import styled from "styled-components/macro";
import * as Icon from "react-feather";
import {
  Card as MuiCard,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import moment from "moment";
import { snakeCaseToTitleText } from "../../../utils/commonFunctions";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

function Progress({ shipmentDetails }) {
  return (
    <Card mb={6} style={{ marginTop: "10px" }}>
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="left">Status</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Location</TableCell>
              <TableCell align="right">Activity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shipmentDetails &&
              shipmentDetails.shipment_change_log &&
              shipmentDetails.shipment_change_log
                .slice(0)
                .reverse()
                .map((log, index) => {
                  if (index === 0) {
                    return (
                      <TableRow>
                        <TableCell align="left">
                          <Icon.ArrowUpCircle />
                        </TableCell>
                        <TableCell component="th" scope="row" align="left">
                          {snakeCaseToTitleText(log.change_field)}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Typography
                            variant="h5"
                            gutterBottom
                            display="inline"
                          >
                            {moment
                              .unix(log.change_date / 1000)
                              .subtract(5.5, "hours")
                              .format("DD MMM YYYY hh:mm a")}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            variant="h5"
                            gutterBottom
                            display="inline"
                          >
                            {log.location}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="h5"
                            gutterBottom
                            display="inline"
                          >
                            {log.event}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return (
                    <TableRow>
                      <TableCell align="left">
                        <Icon.CheckCircle style={{ color: "green" }} />
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        {snakeCaseToTitleText(log.change_field)}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {moment
                          .unix(log.change_date / 1000)
                          .subtract(5.5, "hours")
                          .format("DD MMM YYYY hh:mm a")}
                      </TableCell>
                      <TableCell align="center">{log.location}</TableCell>
                      <TableCell align="right">{log.event}</TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default Progress;
