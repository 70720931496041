import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  serviceProviders: {},
};

export const adminServiceProvidersSlice = createSlice({
  name: "adminServiceProviders",
  initialState,
  reducers: {
    setServiceProviders: (state, action) => {
      state.serviceProviders = action.payload;
    },
    updateNewServiceProvider: (state, action) => {
      const updatedServiceProvider = action.payload;
      const objIndex = state.serviceProviders.service_providers.findIndex(
        (obj) => obj._id === updatedServiceProvider._id
      );
      state.serviceProviders.service_providers[objIndex] =
        updatedServiceProvider;
    },
    updateNewServiceProviderActiveStatus: (state, action) => {
      const { serviceProviderId, userActiveStatus } = action.payload;
      const objIndex = state.serviceProviders.service_providers.findIndex(
        (obj) => obj._id === serviceProviderId
      );
      let serviceProvider = state.serviceProviders.service_providers[objIndex];
      serviceProvider = {
        ...serviceProvider,
        user_activation_status: userActiveStatus,
      };
      state.serviceProviders.service_providers[objIndex] = serviceProvider;
    },
  },
});

export const {
  setServiceProviders,
  updateNewServiceProvider,
  updateNewServiceProviderActiveStatus,
} = adminServiceProvidersSlice.actions;

export default adminServiceProvidersSlice.reducer;
