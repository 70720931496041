/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RasutomairuLandingIntroductionQueryVariables = {|
  traking_id: string,
  service_provider_id?: ?string,
  shipper_id?: ?string,
|};
export type RasutomairuLandingIntroductionQueryResponse = {|
  +getShipmentByTRK: ?{|
    +_id: ?string,
    +traking_id: ?string,
    +shipment_created_time: ?string,
    +shipper: ?{|
      +_id: ?string,
      +shipper_id: ?string,
      +shipper_full_name: ?string,
      +shipper_company_name: ?string,
      +shipper_email: ?string,
      +shipper_address_line1: ?string,
      +shipper_phone1: ?string,
      +pickup_locations: ?{|
        +hub: ?{|
          +_id: ?string,
          +hub_name: ?string,
          +hub_code: ?string,
        |},
        +city: ?{|
          +_id: ?string,
          +city_name: ?string,
        |},
      |},
    |},
    +weight: ?number,
    +qty: ?number,
    +shipment_description: ?string,
    +declared_value: ?number,
    +prepaid: ?boolean,
    +cod_value: ?number,
    +handling_type: ?string,
    +recipient_name: ?string,
    +recipient_phone1: ?string,
    +recipient_phone2: ?string,
    +recipient_address_line1: ?string,
    +recipient_address_line2: ?string,
    +recipient_postal_code: ?string,
    +recipient_address_province: ?{|
      +_id: ?string,
      +province_name: ?string,
    |},
    +district: ?{|
      +_id: ?string,
      +district_name: ?string,
    |},
    +city: ?{|
      +_id: ?string,
      +city_name: ?string,
    |},
    +shipment_change_log: ?$ReadOnlyArray<?{|
      +change_date: ?string,
      +change_field: ?string,
      +history_field: ?string,
      +event: ?string,
      +location: ?string,
    |}>,
    +ownership: ?$ReadOnlyArray<?{|
      +_id: ?string,
      +emp_full_name: ?string,
      +emp_mobile1: ?string,
      +emp_hub: ?{|
        +hub_name: ?string
      |},
    |}>,
    +service_provider: ?{|
      +_id: ?string,
      +service_provider_full_name: ?string,
      +service_provider_company_name: ?string,
    |},
  |}
|};
export type RasutomairuLandingIntroductionQuery = {|
  variables: RasutomairuLandingIntroductionQueryVariables,
  response: RasutomairuLandingIntroductionQueryResponse,
|};
*/


/*
query RasutomairuLandingIntroductionQuery(
  $traking_id: String!
  $service_provider_id: ID
  $shipper_id: ID
) {
  getShipmentByTRK(traking_id: $traking_id, service_provider_id: $service_provider_id, shipper_id: $shipper_id) {
    _id
    traking_id
    shipment_created_time
    shipper {
      _id
      shipper_id
      shipper_full_name
      shipper_company_name
      shipper_email
      shipper_address_line1
      shipper_phone1
      pickup_locations {
        hub {
          _id
          hub_name
          hub_code
        }
        city {
          _id
          city_name
        }
      }
    }
    weight
    qty
    shipment_description
    declared_value
    prepaid
    cod_value
    handling_type
    recipient_name
    recipient_phone1
    recipient_phone2
    recipient_address_line1
    recipient_address_line2
    recipient_postal_code
    recipient_address_province {
      _id
      province_name
    }
    district {
      _id
      district_name
    }
    city {
      _id
      city_name
    }
    shipment_change_log {
      change_date
      change_field
      history_field
      event
      location
    }
    ownership {
      _id
      emp_full_name
      emp_mobile1
      emp_hub {
        hub_name
      }
    }
    service_provider {
      _id
      service_provider_full_name
      service_provider_company_name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "service_provider_id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shipper_id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "traking_id"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hub_name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ShipmentCityReturn",
  "kind": "LinkedField",
  "name": "city",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city_name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "service_provider_id",
        "variableName": "service_provider_id"
      },
      {
        "kind": "Variable",
        "name": "shipper_id",
        "variableName": "shipper_id"
      },
      {
        "kind": "Variable",
        "name": "traking_id",
        "variableName": "traking_id"
      }
    ],
    "concreteType": "Shipment",
    "kind": "LinkedField",
    "name": "getShipmentByTRK",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "traking_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shipment_created_time",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ShipmentShipperReturn",
        "kind": "LinkedField",
        "name": "shipper",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shipper_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shipper_full_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shipper_company_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shipper_email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shipper_address_line1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shipper_phone1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ShipmentPickupLocationsReturn",
            "kind": "LinkedField",
            "name": "pickup_locations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ShipmentShipperHub",
                "kind": "LinkedField",
                "name": "hub",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hub_code",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "weight",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "qty",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shipment_description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "declared_value",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "prepaid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cod_value",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "handling_type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "recipient_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "recipient_phone1",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "recipient_phone2",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "recipient_address_line1",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "recipient_address_line2",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "recipient_postal_code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ShipmentProvinceReturn",
        "kind": "LinkedField",
        "name": "recipient_address_province",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "province_name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ShipmentDistrictReturn",
        "kind": "LinkedField",
        "name": "district",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "district_name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ChangeLogReturn",
        "kind": "LinkedField",
        "name": "shipment_change_log",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "change_date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "change_field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "history_field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "event",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "location",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ShipmentEmployeeReturn",
        "kind": "LinkedField",
        "name": "ownership",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emp_full_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emp_mobile1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ShipmentHub",
            "kind": "LinkedField",
            "name": "emp_hub",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "service_provider",
        "kind": "LinkedField",
        "name": "service_provider",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "service_provider_full_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "service_provider_company_name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RasutomairuLandingIntroductionQuery",
    "selections": (v6/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "RasutomairuLandingIntroductionQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "bc3f17715e8597f20bb3630dd40cfb5d",
    "id": null,
    "metadata": {},
    "name": "RasutomairuLandingIntroductionQuery",
    "operationKind": "query",
    "text": "query RasutomairuLandingIntroductionQuery(\n  $traking_id: String!\n  $service_provider_id: ID\n  $shipper_id: ID\n) {\n  getShipmentByTRK(traking_id: $traking_id, service_provider_id: $service_provider_id, shipper_id: $shipper_id) {\n    _id\n    traking_id\n    shipment_created_time\n    shipper {\n      _id\n      shipper_id\n      shipper_full_name\n      shipper_company_name\n      shipper_email\n      shipper_address_line1\n      shipper_phone1\n      pickup_locations {\n        hub {\n          _id\n          hub_name\n          hub_code\n        }\n        city {\n          _id\n          city_name\n        }\n      }\n    }\n    weight\n    qty\n    shipment_description\n    declared_value\n    prepaid\n    cod_value\n    handling_type\n    recipient_name\n    recipient_phone1\n    recipient_phone2\n    recipient_address_line1\n    recipient_address_line2\n    recipient_postal_code\n    recipient_address_province {\n      _id\n      province_name\n    }\n    district {\n      _id\n      district_name\n    }\n    city {\n      _id\n      city_name\n    }\n    shipment_change_log {\n      change_date\n      change_field\n      history_field\n      event\n      location\n    }\n    ownership {\n      _id\n      emp_full_name\n      emp_mobile1\n      emp_hub {\n        hub_name\n      }\n    }\n    service_provider {\n      _id\n      service_provider_full_name\n      service_provider_company_name\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1a766fc3c16cf04012977548692566d9';

module.exports = node;
