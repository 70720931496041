import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";
import Chart from "react-chartjs-2";
import { MoreVertical } from "react-feather";

import { orange, green, red } from "@mui/material/colors";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import graphql from "babel-plugin-relay/macro";

const Card = styled(MuiCard)(spacing);

const { fetchQuery, useRelayEnvironment } = require("react-relay");

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const GreenText = styled.span`
  color: ${() => green[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const RedText = styled.span`
  color: ${() => red[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

function DoughnutChart({ theme }) {
  const environment = useRelayEnvironment();
  const [ShipperPaymentSummary, setShipperPaymentSummary] = useState([]);

  const ShipperPaymentsSummary = async () => {
    const user = await localStorage.getItem("user");
    const userJson = JSON.parse(user);

    fetchQuery(
      environment,
      graphql`
        query PaymentSummaryQuery($shipper_id: ID) {
          getShipperPaymentSummary(shipper_id: $shipper_id) {
            date_range
            no_of_shipments
            payment_cycle
            next_payment_date
            total_pending_payment
          }
        }
      `,
      { shipper_id: userJson._id }
    ).subscribe({
      start: () => {},
      complete: (e) => {},
      error: (errors) => {},
      next: (data) => {
        setShipperPaymentSummary(data.getShipperPaymentSummary);
      },
    });
  };
  useEffect(() => {
    ShipperPaymentsSummary();
  }, []);

  const data = {
    labels: ["Social", "Search Engines", "Direct", "Other"],
    datasets: [
      {
        data: [260, 125, 54, 146],
        backgroundColor: [
          theme.palette.secondary.main,
          red[500],
          orange[500],
          theme.palette.grey[200],
        ],
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "80%",
  };

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="Payment Summary"
      />

      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Total pending payments
              </TableCell>
              <TableCell align="right">
                {`${ShipperPaymentSummary.total_pending_payment} LKR`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Date range
              </TableCell>
              <TableCell align="right">
                {ShipperPaymentSummary.date_range}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Number of shipments
              </TableCell>
              <TableCell align="right">
                {ShipperPaymentSummary.no_of_shipments}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Payment cycle
              </TableCell>
              <TableCell align="right">
                {ShipperPaymentSummary.payment_cycle}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Next payment date
              </TableCell>
              <TableCell align="right">
                {ShipperPaymentSummary.next_payment_date}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default withTheme(DoughnutChart);
