import React, { useState, useEffect } from "react";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import { requestForToken, onMessageListener } from "./firebase";

const Notification = () => {
  const [notification, setNotification] = useState({
    title: "",
    body: "",
  });

  const notify = () =>
    toast((t) => (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <p>
            <b>{notification?.title}</b>
          </p>
          <p>{notification?.body}</p>
        </div>
        <div
          style={{
            paddingLeft: "50px",
          }}
        >
          <span>
            <button onClick={() => toast.dismiss(t.id)}>Close</button>
          </span>
        </div>
      </div>
    ));

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
  }, [notification]);

  requestForToken();

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <Toaster
      position="bottom-left"
      toastOptions={{
        className: "",
        duration: 10000,
      }}
    />
  );
};

export default Notification;
