/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankSelectorQueryVariables = {||};
export type BankSelectorQueryResponse = {|
  +getBanks: ?$ReadOnlyArray<?{|
    +_id: ?string,
    +bank_name: ?string,
    +bank_code: ?string,
    +bank_branches: ?$ReadOnlyArray<?{|
      +branch_name: ?string,
      +branch_code: ?string,
    |}>,
  |}>
|};
export type BankSelectorQuery = {|
  variables: BankSelectorQueryVariables,
  response: BankSelectorQueryResponse,
|};
*/


/*
query BankSelectorQuery {
  getBanks {
    _id
    bank_name
    bank_code
    bank_branches {
      branch_name
      branch_code
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AllBankReturn",
    "kind": "LinkedField",
    "name": "getBanks",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bank_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bank_code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BankBranchReturn",
        "kind": "LinkedField",
        "name": "bank_branches",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "branch_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "branch_code",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BankSelectorQuery",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BankSelectorQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8595caa12454d6bacc4d4f54cc6a8fd9",
    "id": null,
    "metadata": {},
    "name": "BankSelectorQuery",
    "operationKind": "query",
    "text": "query BankSelectorQuery {\n  getBanks {\n    _id\n    bank_name\n    bank_code\n    bank_branches {\n      branch_name\n      branch_code\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a56e03642b7e41644d78ab132da74a20';

module.exports = node;
