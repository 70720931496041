import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  priceCardZones: [],
};

export const serviceProviderPriceCardsSlice = createSlice({
  name: "serviceProviderPriceCards",
  initialState,
  reducers: {
    setPriceCardZones: (state, action) => {
      const zones = action.payload;
      const zone = [];
      zones.forEach(({ _id, zone_name, zone_code }) => {
        const object = {
          zone: {
            _id,
            zone_name,
            zone_code,
          },
          first_kg_rate: "",
          additional_kg_rate: "",
        };
        zone.push(object);
      });
      state.priceCardZones = zone;
    },
  },
});

export const { setPriceCardZones } = serviceProviderPriceCardsSlice.actions;

export default serviceProviderPriceCardsSlice.reducer;
