import React from "react";
import styled from "styled-components/macro";

import {
  Button,
  // Container,
  Grid,
  Typography as MuiTypography,
  List,
  ListItemIcon,
  ListSubheader,
  Avatar as MuiAvatar,
  ListItem,
  ListItemText as MuiListItemText,
} from "@mui/material";
import { spacing } from "@mui/system";

const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);

const Wrapper = styled.div`
  ${spacing};
  text-align: left;
  position: relative;
  background: #181d2d;
  color: ${(props) => props.theme.palette.common.white};
`;

const Container = styled.div`
  ${spacing};
  width: auto;
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  opacity: 0.75;
`;

const ListItemText = styled(MuiListItemText)(spacing);

function ProductList() {
  return (
    <Wrapper>
      <List
        subheader={
          <ListSubheader
            style={{
              backgroundColor: "#181d2d",
              color: "GrayText",
            }}
          >
            Products
          </ListSubheader>
        }
      >
        <ListItem>
          <ListItemText primary="Overview" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Features" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Solutions" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Tutorials" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Pricing" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Releases" />
        </ListItem>
      </List>
    </Wrapper>
  );
}

function CompanyList() {
  return (
    <Wrapper>
      <List
        subheader={
          <ListSubheader
            style={{
              backgroundColor: "#181d2d",
              color: "GrayText",
            }}
          >
            Company
          </ListSubheader>
        }
      >
        <ListItem>
          <ListItemText primary="About us" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Careers" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Press" />
        </ListItem>
        <ListItem>
          <ListItemText primary="News" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Media kit" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Contact" />
        </ListItem>
      </List>
    </Wrapper>
  );
}

function ResourcesList() {
  return (
    <Wrapper>
      <List
        subheader={
          <ListSubheader
            style={{
              backgroundColor: "#181d2d",
              color: "GrayText",
            }}
          >
            Resources
          </ListSubheader>
        }
      >
        <ListItem>
          <ListItemText primary="Blog" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Newsletter" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Events" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Help centre" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Tutorials" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Support" />
        </ListItem>
      </List>
    </Wrapper>
  );
}

function SocialList() {
  return (
    <Wrapper>
      <List
        subheader={
          <ListSubheader
            style={{
              backgroundColor: "#181d2d",
              color: "GrayText",
            }}
          >
            Social
          </ListSubheader>
        }
      >
        <ListItem>
          <ListItemText primary="Twitter" />
        </ListItem>
        <ListItem>
          <ListItemText primary="LinkedIn" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Facebook" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Instagram" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Google" />
        </ListItem>
      </List>
    </Wrapper>
  );
}

function LegalList() {
  return (
    <Wrapper>
      <List
        subheader={
          <ListSubheader
            style={{
              backgroundColor: "#181d2d",
              color: "GrayText",
            }}
          >
            Legal
          </ListSubheader>
        }
      >
        <ListItem>
          <ListItemText primary="Terms" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Privacy" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Cookies" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Licenses" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Settings" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Contact" />
        </ListItem>
      </List>
    </Wrapper>
  );
}

function BottomDescription() {
  return (
    <Wrapper pt={16} pb={16}>
      <Container>
        <Grid container alignItems="left" justifyContent="left">
          <Grid item xs={5} pl={25}>
            <Typography variant="h2" gutterBottom>
              IndexCloud
            </Typography>

            <Subtitle variant="h5" gutterBottom>
              Provide amazing delivery experiences that make happier customers
            </Subtitle>
            <Spacer mb={4} />
          </Grid>

          <Grid item xs={2} pl={35}>
            <ProductList />
          </Grid>
          {/* <Spacer mb={4} /> */}

          <Grid item xs={1}>
            <CompanyList />
          </Grid>
          <Grid item>
            <ResourcesList />
          </Grid>
          <Grid item xs={1} pl={15}>
            <SocialList />
          </Grid>
          <Grid item xs={1} pl={15}>
            <LegalList />
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default BottomDescription;
