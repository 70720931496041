import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/counter";
import userReducer from "./slices/user";
import ShipperShipmentsReducer from "./slices/ShipperShipments";
import ShipperReturnsReducer from "./slices/ShipperReturns";
import ShipperPickupsReducer from "./slices/ShipperPickups";
import serviceProviderPickupsReducer from "./slices/serviceProviderPickups";
import ServiceProviderShipmentsReducer from "./slices/ServiceProviderShipments";
import ServiceProviderReturnsReducer from "./slices/ServiceProviderReturns";
import ServiceProviderEmployeesReducer from "./slices/ServiceProviderEmployees";
import ServiceProviderZonesReducer from "./slices/ServiceProviderZones";
import ServiceProviderPaymentCyclesReducer from "./slices/ServiceProviderPaymentCycles";
import ServiceProviderBillingCyclesReducer from "./slices/ServiceProviderBillingCycles";
import ServiceProviderCollectionCyclesReducer from "./slices/ServiceProviderCollectionCycles";
import ServiceProviderHubsReducer from "./slices/ServiceProviderHubs";
import ServiceProviderCitiesReducer from "./slices/ServiceProviderCities";
import ServiceProviderPickupSlotsReducer from "./slices/ServiceProviderPickupSlots";
import ServiceProviderDispatchReducer from "./slices/ServiceProviderDispatch";
import ServiceProviderPaymentNotesReducer from "./slices/ServiceProviderPaymentNotes";
import AdminServiceProvidersReducer from "./slices/AdminServiceProviders";
import ServiceProviderOrdersReducer from "./slices/ServiceProviderOrders";
import ServiceProviderOrderUrlsReducer from "./slices/ServiceProviderOrderUrls";
import ServiceProviderOrderUrlImagesReducer from "./slices/ServiceProviderOrderUrlImages";
import ServiceProviderInvoicesReducer from "./slices/ServiceProviderInvoices";
import ShipperPayableReducer from "./slices/ShipperPayables";
import ServiceProviderSummaryDashboardTableReducer from "./slices/ServiceProviderPaymentSummaryDashboardTable";
import ServiceProviderPaymentSummaryReducer from "./slices/ServiceProviderPaymentSummary";
import ServiceProviderPriceCardsReducer from "./slices/ServiceProviderPriceCards";
import ServiceProviderHubCollections from "./slices/ServiceProviderHubCollections";
import ShippersReducer from "./slices/Shippers";
import ServiceProviderNotificationsReducer from "./slices/ServiceProviderNotifications";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    shipperShipments: ShipperShipmentsReducer,
    shipperReturns: ShipperReturnsReducer,
    shipperPickups: ShipperPickupsReducer,
    serviceProviderPickups: serviceProviderPickupsReducer,
    serviceProviderShipments: ServiceProviderShipmentsReducer,
    serviceProviderReturns: ServiceProviderReturnsReducer,
    serviceProviderEmployees: ServiceProviderEmployeesReducer,
    ServiceProviderZones: ServiceProviderZonesReducer,
    ServiceProviderPaymentCycles: ServiceProviderPaymentCyclesReducer,
    ServiceProviderBillingCycles: ServiceProviderBillingCyclesReducer,
    ServiceProviderCollectionCycles: ServiceProviderCollectionCyclesReducer,
    ServiceProviderHubs: ServiceProviderHubsReducer,
    ServiceProviderCities: ServiceProviderCitiesReducer,
    ServiceProviderPickupSlots: ServiceProviderPickupSlotsReducer,
    ServiceProviderDispatch: ServiceProviderDispatchReducer,
    AdminServiceProviders: AdminServiceProvidersReducer,
    ServiceProviderOrders: ServiceProviderOrdersReducer,
    ServiceProviderOrderUrls: ServiceProviderOrderUrlsReducer,
    ServiceProviderOrderUrlImages: ServiceProviderOrderUrlImagesReducer,
    ServiceProviderPaymentNotes: ServiceProviderPaymentNotesReducer,
    ServiceProviderInvoices: ServiceProviderInvoicesReducer,
    ShipperPayables: ShipperPayableReducer,
    ServiceProviderSummaryDashboardTable:
      ServiceProviderSummaryDashboardTableReducer,
    ServiceProviderPaymentSummary: ServiceProviderPaymentSummaryReducer,
    ServiceProviderPriceCards: ServiceProviderPriceCardsReducer,
    serviceProviderHubCollections: ServiceProviderHubCollections,
    Shippers: ShippersReducer,
    ServiceProviderNotifications: ServiceProviderNotificationsReducer,
  },
});
