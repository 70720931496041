import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  FormControl as MuiFormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { spacing } from "@mui/system";
import graphql from "babel-plugin-relay/macro";
import { fetchQuery, useRelayEnvironment } from "react-relay";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

function CitySelector({ values, touched, errors, handleBlur, handleChange }) {
  return (
    <FormControl style={{ marginTop: "10px" }}>
      <InputLabel id="demo-simple-select-helper-label">City</InputLabel>

      <SelectorDisplay
        values={values}
        touched={touched}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />

      <FormHelperText style={{ color: "red" }}>
        {touched.city && errors.city ? "City is required." : ""}
      </FormHelperText>
    </FormControl>
  );
}

function SelectorDisplay({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
}) {
  const [cityList, setCityList] = useState([]);
  const environment = useRelayEnvironment();
  const fetchCities = async () => {
    const disArr = values.district.split("_");
    fetchQuery(
      environment,
      graphql`
        query CitySelectorByDistrictQuery($district_id: ID) {
          getCities(district_id: $district_id) {
            _id
            city_name
            city_code
          }
        }
      `,
      { district_id: disArr[0] }
    ).subscribe({
      start: () => {},
      complete: () => {},
      error: () => {
        setCityList([]);
      },
      next: (data) => {
        if (data.getCities) {
          setCityList(data.getCities);
        } else {
          setCityList([]);
        }
      },
    });
  };

  useEffect(() => {
    if (values.district) {
      fetchCities();
    }
  }, [values.district]);

  return (
    <Select
      labelId="demo-simple-select-helper-label"
      id="demo-simple-select-helper"
      name="city"
      value={values.city}
      error={Boolean(touched.city && errors.city)}
      helperText={touched.city && errors.city}
      onBlur={handleBlur}
      onChange={handleChange}
    >
      {cityList.map((city, key) => (
        <MenuItem
          key={key}
          value={`${city._id}_${city.city_name}_${city.city_code}`}
        >
          {city.city_name}
        </MenuItem>
      ))}
    </Select>
  );
}

export default CitySelector;
