import React from "react";
import styled from "styled-components/macro";

import { Badge, Grid, Avatar, Typography } from "@mui/material";

import { useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import { authUserType } from "../../utils/defaultStatus";
import { employeeTypeConstants } from "../../utils/employeeTypeConstants";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

function SidebarFooter({ ...rest }) {
  // const { user } = useAuth();
  const user = useSelector((state) => state.user.user);
  return (
    <Footer {...rest}>
      <Grid container spacing={2}>
        {/* <Grid item>
          <FooterBadge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot"
          >
            {!!user && <Avatar alt={user.displayName} src={user.avatar} />}
            {!user && (
              <Avatar
                alt="Lucy Lavender"
                src="/static/img/avatars/avatar-1.jpg"
              />
            )}
          </FooterBadge>
        </Grid> */}
        <Grid item>
          {!!user && (
            <FooterText variant="body2">
              {user?.user_type === authUserType.SHIPPER &&
                user.shipper_full_name}
              {user?.user_type === authUserType.SERVICE_PROVIDER &&
                user.service_provider_full_name}
              {user?.user_type === authUserType.EMPLOYEE && user.emp_full_name}
            </FooterText>
          )}
          {/* Demo data */}
          {!user && <FooterText variant="body2">Guest</FooterText>}
          <FooterSubText variant="caption">
            {user?.user_type === authUserType.SHIPPER && "Shipper Account"}
            {user?.user_type === authUserType.EMPLOYEE &&
              user?.employee_type === employeeTypeConstants.SITE_ADMIN &&
              "Service Provider Account"}
            {user?.user_type === authUserType.EMPLOYEE &&
              user?.employee_type === employeeTypeConstants.HUB_MANAGER &&
              "Hub Manager Account"}
          </FooterSubText>
        </Grid>
      </Grid>
    </Footer>
  );
}

export default SidebarFooter;
