/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PaymentSummaryQueryVariables = {|
  shipper_id?: ?string
|};
export type PaymentSummaryQueryResponse = {|
  +getShipperPaymentSummary: ?{|
    +date_range: ?string,
    +no_of_shipments: ?number,
    +payment_cycle: ?string,
    +next_payment_date: ?string,
    +total_pending_payment: ?number,
  |}
|};
export type PaymentSummaryQuery = {|
  variables: PaymentSummaryQueryVariables,
  response: PaymentSummaryQueryResponse,
|};
*/


/*
query PaymentSummaryQuery(
  $shipper_id: ID
) {
  getShipperPaymentSummary(shipper_id: $shipper_id) {
    date_range
    no_of_shipments
    payment_cycle
    next_payment_date
    total_pending_payment
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shipper_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "shipper_id",
        "variableName": "shipper_id"
      }
    ],
    "concreteType": "PaymentSummaryReturnType",
    "kind": "LinkedField",
    "name": "getShipperPaymentSummary",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date_range",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "no_of_shipments",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "payment_cycle",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "next_payment_date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total_pending_payment",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PaymentSummaryQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PaymentSummaryQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d1479f1f548b2ba43780c719b04c7949",
    "id": null,
    "metadata": {},
    "name": "PaymentSummaryQuery",
    "operationKind": "query",
    "text": "query PaymentSummaryQuery(\n  $shipper_id: ID\n) {\n  getShipperPaymentSummary(shipper_id: $shipper_id) {\n    date_range\n    no_of_shipments\n    payment_cycle\n    next_payment_date\n    total_pending_payment\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '728dee8888396c04dff2718204607b01';

module.exports = node;
