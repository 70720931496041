import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  FormControl as MuiFormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import graphql from "babel-plugin-relay/macro";

const { usePreloadedQuery, useQueryLoader } = require("react-relay");

const TextField = styled(MuiTextField)(spacing);

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const getAllBanks = graphql`
  query BankSelectorQuery {
    getBanks {
      _id
      bank_name
      bank_code
      bank_branches {
        branch_name
        branch_code
      }
    }
  }
`;

function BankSelector({ values, touched, errors, handleBlur, handleChange }) {
  const [queryReference, loadQuery, disposeQuery] = useQueryLoader(getAllBanks);

  useEffect(() => {
    loadQuery();
  }, []);
  return (
    <>
      <FormControl style={{ marginTop: "10px", marginBottom: "10px" }}>
        <InputLabel>Bank</InputLabel>

        {queryReference != null ? (
          <BankSelectorDisplay
            values={values}
            touched={touched}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            queryReference={queryReference}
          />
        ) : null}

        <FormHelperText style={{ color: "red" }}>
          {touched.bank && errors.bank ? "Bank is required." : ""}
        </FormHelperText>
      </FormControl>
      <TextField
        type="text"
        name="bankCode"
        label="Bank Code"
        value={values.bank && values.bank.split("_")[2]}
        error={Boolean(touched.bankCode && errors.bankCode)}
        fullWidth
        helperText={touched.bankCode && errors.bankCode}
        onBlur={handleBlur}
        onChange={handleChange}
        my={3}
        InputProps={{
          readOnly: true,
        }}
      />
      <FormControl style={{ marginTop: "10px", marginBottom: "10px" }}>
        <InputLabel>Branch</InputLabel>
        {queryReference != null ? (
          <BranchSelectorDisplay
            values={values}
            touched={touched}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            queryReference={queryReference}
          />
        ) : null}

        <FormHelperText style={{ color: "red" }}>
          {touched.branch && errors.branch ? "Branch name is required." : ""}
        </FormHelperText>
      </FormControl>
      <TextField
        type="text"
        name="branchCode"
        label="Branch Code"
        value={values.branch && values.branch.split("_")[1]}
        error={Boolean(touched.branchCode && errors.branchCode)}
        fullWidth
        helperText={touched.branchCode && errors.branchCode}
        onBlur={handleBlur}
        onChange={handleChange}
        my={3}
        InputProps={{
          readOnly: true,
        }}
      />
    </>
  );
}

function BankSelectorDisplay({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  queryReference,
}) {
  const data = usePreloadedQuery(getAllBanks, queryReference);

  return (
    <Select
      labelId="demo-simple-select-helper-label"
      id="demo-simple-select-helper"
      name="bank"
      value={values.bank}
      error={Boolean(touched.bank && errors.bank)}
      helperText={touched.bank && errors.bank}
      onBlur={handleBlur}
      onChange={handleChange}
    >
      {data.getBanks.map((bank, key) => (
        <MenuItem
          key={key}
          value={`${bank._id}_${bank.bank_name}_${bank.bank_code}`}
        >
          {bank.bank_name}
        </MenuItem>
      ))}
    </Select>
  );
}

function BranchSelectorDisplay({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  queryReference,
}) {
  const data = usePreloadedQuery(getAllBanks, queryReference);
  const [bankList, setBankList] = useState([]);

  useEffect(() => {
    setBankList(
      data.getBanks.filter((bank) => bank._id === values.bank.split("_")[0])
    );
  }, [values.bank]);
  return (
    <Select
      labelId="demo-simple-select-helper-label"
      id="demo-simple-select-helper"
      name="branch"
      value={values.branch}
      error={Boolean(touched.branch && errors.branch)}
      helperText={touched.branch && errors.branch}
      onBlur={handleBlur}
      onChange={handleChange}
    >
      {bankList.length > 0 &&
        bankList[0].bank_branches.map((branch) => (
          <MenuItem value={`${branch.branch_name}_${branch.branch_code}`}>
            {branch.branch_name}
          </MenuItem>
        ))}
    </Select>
  );
}

export default BankSelector;
