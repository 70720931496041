import React, { useState } from "react";
import styled from "styled-components/macro";
import {
  Card as MuiCard,
  CardContent,
  FormControl as MuiFormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Button as MuiButton,
  Alert as MuiAlert,
  CardHeader,
} from "@mui/material";
import { spacing } from "@mui/system";
import graphql from "babel-plugin-relay/macro";
import momentTimezone from "moment-timezone";
import { useMutation } from "react-relay";
import { useSelector, useDispatch } from "react-redux";
import { commonConstants } from "../../../utils/commonConstants";
import { updateShipmentStatusInAllShipments } from "../../../redux/slices/ServiceProviderShipments";
import HubsSelector from "../../serviceProvider/components/HubsSelector";
import { employeeTypeConstants } from "../../../utils/employeeTypeConstants";
import { ShipmentsConstants } from "../../../utils/shipmentConstants";

const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Alert = styled(MuiAlert)(spacing);

function UpdateShipmentStatus({
  shipmentDetails,
  setShipmentDetails,
  handlePrint,
}) {
  const user = useSelector((state) => state.user.user);
  const [shipmentStatus, setShipmentStatus] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [deliveredHub, setDeliveredHub] = useState("");
  const [deliveredHubSelectError, setDeliveredHubSelectError] = useState(false);
  const dispatch = useDispatch();
  const [commit, isInFlight] = useMutation(graphql`
    mutation UpdateShipmentStatusMutation(
      $id: ID
      $shipment_data: ShipmentInput
      $created_date: String
      $emp_hub: CommenHubInput
    ) {
      updateShipmentById(
        id: $id
        shipment_data: $shipment_data
        created_date: $created_date
        emp_hub: $emp_hub
      ) {
        _id
        traking_id
        shipment_created_time
        shipper {
          _id
          shipper_id
          shipper_full_name
          shipper_company_name
          shipper_email
          shipper_address_line1
          shipper_phone1
          pickup_locations {
            hub {
              _id
              hub_name
              hub_code
            }
            city {
              _id
              city_name
            }
          }
        }
        weight
        qty
        shipment_status
        shipment_description
        shipment_status
        declared_value
        prepaid
        cod_value
        handling_type
        recipient_name
        recipient_phone1
        recipient_phone2
        recipient_address_line1
        recipient_address_line2
        recipient_postal_code
        recipient_address_province {
          _id
          province_name
        }
        district {
          _id
          district_name
        }
        city {
          _id
          city_name
        }
        shipment_change_log {
          change_date
          change_field
          history_field
          event
          location
        }
        ownership {
          _id
          emp_full_name
          emp_mobile1
          emp_hub {
            hub_name
          }
        }
        service_provider {
          _id
          service_provider_full_name
          service_provider_company_name
        }
      }
    }
  `);
  const handleUpdate = () => {
    const { employee_type: employeeType, emp_hub: empHub } = user;
    if (
      employeeType === employeeTypeConstants.SITE_ADMIN &&
      (shipmentStatus === ShipmentsConstants.DELIVERED ||
        shipmentStatus === ShipmentsConstants.RETURN_DELIVERED) &&
      !deliveredHub
    ) {
      setDeliveredHubSelectError(true);
      return;
    }
    const deliveredHubArray = deliveredHub?.split("_");
    const currentTimeZone = momentTimezone();
    const currentTime = currentTimeZone
      .tz("Asia/Colombo")
      .format("YYYY-MM-DD HH:mm:ss");
    commit({
      variables: {
        id: shipmentDetails._id,
        shipment_data: {
          shipment_status: shipmentStatus,
        },
        created_date: currentTime,
        emp_hub: {
          _id:
            employeeType === employeeTypeConstants.SITE_ADMIN
              ? deliveredHubArray[0]
              : empHub._id,
          hub_name:
            employeeType === employeeTypeConstants.SITE_ADMIN
              ? deliveredHubArray[1]
              : empHub.hub_name,
          hub_code:
            employeeType === employeeTypeConstants.SITE_ADMIN
              ? deliveredHubArray[2]
              : empHub.hub_code,
        },
      },
      onCompleted(data, error) {
        if (data.updateShipmentById) {
          setShipmentDetails(data.updateShipmentById);
          dispatch(updateShipmentStatusInAllShipments(data.updateShipmentById));
          setUpdateStatus(commonConstants.SUCCESS);
        }
        if (!data.updateShipmentById) {
          setUpdateStatus(commonConstants.FAILED);
        }
        if (error) {
          setUpdateStatus(commonConstants.FAILED);
        }
      },
    });
  };
  return (
    <Card mb={6} style={{ marginTop: "10px" }}>
      <CardHeader title="Update Shipment Status" />
      <CardContent>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button mr={2} variant="contained" onClick={() => handlePrint()}>
            Shipment label
          </Button>
        </div>

        <FormControl style={{ marginTop: "20px" }}>
          <InputLabel id="demo-simple-select-helper-label">
            Shipment Status
          </InputLabel>

          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            name="shipmentStatus"
            disabled={
              shipmentDetails.shipment_status === ShipmentsConstants.DELIVERED
            }
            onChange={(e) => setShipmentStatus(e.target.value)}
          >
            <MenuItem value="picked_up">Picked up</MenuItem>
            <MenuItem value="rescheduled">Rescheduled</MenuItem>
            <MenuItem value="return_delivery_resheduled">
              Return Rescheduled
            </MenuItem>
            <MenuItem value="failed_to_deliver">Failed to deliver</MenuItem>
            <MenuItem value="return_delivery_failed">
              Return delivery failed
            </MenuItem>
            <MenuItem value="delivered">Delivered</MenuItem>
            <MenuItem value="return_delivered">Return Delivered</MenuItem>
          </Select>

          <FormHelperText style={{ color: "red" }} />
        </FormControl>
        {user.employee_type === employeeTypeConstants.SITE_ADMIN &&
          (shipmentStatus === ShipmentsConstants.DELIVERED ||
            shipmentStatus === ShipmentsConstants.RETURN_DELIVERED) && (
            <HubsSelector
              hub={deliveredHub}
              setHub={setDeliveredHub}
              hubSelectError={deliveredHubSelectError}
              setHubSelectError={setDeliveredHubSelectError}
              placeholder="Select Hub"
              errorMessage="Please select a hub"
            />
          )}
        <Button
          variant="contained"
          style={{ width: "100%", marginTop: "10px" }}
          disabled={
            isInFlight ||
            shipmentStatus === "" ||
            shipmentDetails.shipment_status === ShipmentsConstants.DELIVERED
          }
          onClick={() => handleUpdate()}
        >
          Update shipment status
        </Button>
        {updateStatus === commonConstants.SUCCESS && (
          <Alert
            variant="filled"
            severity="success"
            style={{ marginTop: "10px" }}
            onClose={() => setUpdateStatus("")}
          >
            Shipment status updated successfully.
          </Alert>
        )}
        {updateStatus === commonConstants.FAILED && (
          <Alert
            mb={4}
            variant="filled"
            severity="error"
            style={{ marginTop: "10px" }}
            onClose={() => setUpdateStatus("")}
          >
            Something wrong! Please try again.
          </Alert>
        )}
      </CardContent>
    </Card>
  );
}

export default UpdateShipmentStatus;
