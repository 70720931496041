import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  paymentSummaryCardDetails: {},
  pendingCount: [],
  totalCount: [],
  monthLabels: [],
};

export const ServiceProviderPaymentSummarySlice = createSlice({
  name: "serviceProviderPaymentSummary",
  initialState,
  reducers: {
    setPaymentSummary: (state, action) => {
      state.paymentSummaryCardDetails = action.payload;
    },
    setMonthlyPayments: (state, action) => {
      const payments = action.payload;

      const allmonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

      const result = [];

      allmonths.forEach((month) => {
        const item = payments.find((item) => item._id === month);

        if (item) {
          result.push(item);
        } else {
          result.push({ _id: month, pending_count: 0, total_count: 0 });
        }
      });

      const totCount = [];
      const penCount = [];
      const monthlyToken = [];

      result.forEach((month) => {
        const count = month.total_count;
        const pending = month.pending_count;
        const monthLabel = month._id;

        switch (monthLabel) {
          case 1:
            monthlyToken.push("Jan");
            break;
          case 2:
            monthlyToken.push("Feb");
            break;
          case 3:
            monthlyToken.push("Mar");
            break;
          case 4:
            monthlyToken.push("Apr");
            break;
          case 5:
            monthlyToken.push("May");
            break;
          case 6:
            monthlyToken.push("Jun");
            break;
          case 7:
            monthlyToken.push("Jul");
            break;
          case 8:
            monthlyToken.push("Aug");
            break;
          case 9:
            monthlyToken.push("Sep");
            break;
          case 10:
            monthlyToken.push("Oct");
            break;
          case 11:
            monthlyToken.push("Nov");
            break;
          case 12:
            monthlyToken.push("Dec");
            break;
          default:
        }

        totCount.push(count);
        penCount.push(pending);
      });

      state.totalCount = totCount;
      state.pendingCount = penCount;
      state.monthLabels = monthlyToken;
    },
  },
});

export const { setPaymentSummary, setMonthlyPayments } =
  ServiceProviderPaymentSummarySlice.actions;
export default ServiceProviderPaymentSummarySlice.reducer;
