export const shipmentConstants = {
  NEW: "new",
  READY_TO_PICK_UP: "ready to pick up",
  PICKED_UP: "picked up",
  ARRIVED_AT_ORIGIN_HUB: "arrived at origin hub",
  READY_TO_DISPATCH: "ready to dispatch",
  IN_TRANSIT: "in_transit",
  ARRIVED_AT_TRANSIT_HUB: "arrived at transit hub",
  ARRIVED_AT_DESTINATION_HUB: "arrived at destination hub",
  OUT_FOR_DELIVERY: "out_for_delivery",
  RESCHEDULED: "resheduled",
  FAILED_TO_DELIVER: "failed to deliver",
  RETURN_IN_TRANSIT: "return_in_transit",
  RETURN_ARRIVED_AT_TRANSIT_HUB: "return arrived at transit hub",
  RETURN_ARRIVED_AT_ORIGIN_HUB: "return arrived at origin hub",
  RETURN_OUT_FOR_DELIVERY: "return_out_for_delivery",
  RETURN_DELIVERY_RESCHEDULED: "return delivery resheduled",
  RETURN_DELIVERY_FAILED: "return delivery failed",
  RETURN_DELIVERED: "return delivered",
  DELIVERED: "delivered",
};

export const PriceCardConstants = {
  PRICE_CARD_DELIVERY_SERVICE: "delivery",
  PRICE_CARD_STANDARD_SERVICE: "standard",
  SCOPE_TYPE: "zone",
  COD_FIXED_HANDLE_TYPE: "fixed",
  COD_PECENTAGE_HANDLE_TYPE: "percentage_of_cod_amount",
  COD_ABOVE_CERTAIN_AMOUNT: "above_a_certain_amount",
  THRESHOLD_FIXED: "fixed",
  THRESHOLD_PERCENTAGE: "percentage",
};

export const ShipmentsConstants = {
  RETURN_DELIVERED: "return_delivered",
  DELIVERED: "delivered",
};
